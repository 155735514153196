import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import DashboardSidebar from './DashboardSidebar';
import { useAuth } from '../contexts/AuthContext';
import './ChangePassword.css'; // Import new CSS file
import { API_URL } from '../../config';

const ChangePassword = () => {
  const { isLoggedIn } = useAuth();
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      alert('New password and confirm password must match.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      alert('Authentication token is not available. Please log in again.');
      return;
    }

    const fetchOptions = {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        oldPassword: passwordData.oldPassword,
        newPassword: passwordData.newPassword,
      }),
    };

    try {
      const response = await fetch(`${API_URL}/api/user/changePassword`, fetchOptions);
      if (!response.ok) {
        throw new Error('Request failed');
      }
      const data = await response.json();
      alert(data.message);
      setPasswordData({ oldPassword: '', newPassword: '', confirmPassword: '' });
    } catch (error) {
      //console.error('Error changing password:', error);
      alert(error.message);
    }
  };

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="dashboard-container">
      <DashboardSidebar />
      <section className="dashboard-content">
        <div className="profile-container">
          <h1>Change Password</h1>
          <form onSubmit={handleSubmit} className="change-password-form"> {/* Changed class name */}
            <div className="form-group">
              <label htmlFor="oldPassword">Old Password</label>
              <input
                type="password"
                id="oldPassword"
                name="oldPassword"
                value={passwordData.oldPassword}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={passwordData.newPassword}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm New Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="change-password-submit">Change Password</button> {/* Changed class name */}
          </form>
        </div>
      </section>
    </div>
  );
};

export default ChangePassword;
