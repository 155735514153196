// LoginMessage.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import './LoginForm.css';
import { API_URL } from '../../config';

function LoginMessage() {
  const { isLoggedIn, login } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const charter = location.state?.charter;

  const [formData, setFormData] = useState({
    login: '',
    password: '',
    error: null,
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/send-message', { state: { charter } });
    }
  }, [isLoggedIn, navigate, charter]);

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`${API_URL}/api/auth/login`, {
      method: 'POST',
      body: JSON.stringify({
        login: formData.login,
        password: formData.password,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Login failed');
        }
        return response.json();
      })
      .then((data) => {
        if (data.success && data.token) {
          login({
            token: data.token,
            userId: data.userId, // Assuming your backend sends userId
            user: data.user, // Assuming your backend sends user
          });
          navigate('/send-message', { state: { charter } });
        } else {
          setFormData({ ...formData, error: data.message });
        }
      })
      .catch((error) => {
        setFormData({ ...formData, error: 'Failed to log in. Please try again.' });
      });
  };

  return (
    <div className="login-form">
      <h2>Login</h2>
      {formData.error && <p className="error">{formData.error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="login">Username or Email:</label>
          <input
            type="text"
            id="login"
            name="login"
            value={formData.login}
            onChange={(e) => setFormData({ ...formData, login: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <input type="submit" value="Login" className="submit-button" />
        </div>
      </form>
    </div>
  );
}

export default LoginMessage;
