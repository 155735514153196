import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './ResetPassword.css';
import { API_URL } from '../../config';

function ResetPassword() {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

// Inside ResetPassword component
    const handleSubmit = (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        fetch(`${API_URL}/api/auth/update-password/${token}`, {
            method: 'POST',
            body: JSON.stringify({ password }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                setSuccessMessage(data.message);
            } else {
                throw new Error('Failed to reset password');
            }
        })
        .catch(error => {
            setError('Failed to reset password. Please try again.');
           // console.error('Error resetting password:', error);
        });
    };


    return (
        <div className="reset-password-form">
            <h2>Reset Password</h2>
            {error && <p className="error">{error}</p>}
            {successMessage && <p className="success">{successMessage}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="password">New Password:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm New Password:</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input type="submit" value="Reset Password" className="submit-button" />
                </div>
            </form>
        </div>
    );
}

export default ResetPassword;
