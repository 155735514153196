import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import DashboardSidebar from './DashboardSidebar';
import { useAuth } from '../contexts/AuthContext';
import './PostListings.css';
import { API_URL } from '../../config';

const PostListingHave = () => {
  const { isLoggedIn, token } = useAuth();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(null);
  const [fleet, setFleet] = useState([]);
  const [formData, setFormData] = useState({
    charter_date_start: '',
    charter_date_end: '',
    flexibility: 0,
    from: '',
    to: '',
    passengers: 1,
    aircraft_id: '',
    have_need: 'Have'
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    } else {
      fetch(`${API_URL}/api/user/profile`, {
        headers: { 'Authorization': `Bearer ${token}` }
      })
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch user profile');
        return response.json();
      })
      .then(data => {
        setUserProfile(data);
        if (data.charter_plan === 'free') {
          navigate('/pricing');
        }
      })
      .catch(error => {
        console.error('Error fetching user profile:', error);
      });
    }
  }, [isLoggedIn, token, navigate]);

  useEffect(() => {
    if (userProfile && userProfile.upgrade === 0) {
      navigate('/pricing');
    }
  }, [userProfile, navigate]);

  useEffect(() => {
    if (isLoggedIn) {
      fetch(`${API_URL}/api/addAircraft/myaircraft`, {
        headers: { 'Authorization': `Bearer ${token}` }
      })
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch fleet');
        return response.json();
      })
      .then(data => {
        setFleet(data);
        if (data.length > 0) {
          setFormData(prevFormData => ({
            ...prevFormData,
            aircraft_id: data[0].id
          }));
        }
      })
      .catch(error => {
        console.error('Error fetching fleet:', error);
      });
    }
  }, [isLoggedIn, token]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: name === 'flexibility' || name === 'passengers' ? parseInt(value, 10) : value
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      if (!userProfile) {
        throw new Error('User profile not available');
      }
      const response = await fetch(`${API_URL}/api/charters`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          charter_date_start: formData.charter_date_start,
          charter_date_end: formData.charter_date_end,
          flexibility: formData.flexibility,
          from: formData.from,
          to: formData.to,
          passengers: formData.passengers,
          aircraft_size: formData.aircraft_id, // Assuming aircraft_id corresponds to aircraft_size
          have_need: formData.have_need,
          username: userProfile.username // Use userProfile username directly
        })
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to submit listing');
      }

      setFormData({
        charter_date_start: '',
        charter_date_end: '',
        flexibility: 0,
        from: '',
        to: '',
        passengers: 1,
        aircraft_id: fleet.length > 0 ? fleet[0].id : '',
        have_need: 'Have'
      });

      navigate('/my-posts');
    } catch (error) {
      console.error('Error submitting listing:', error);
    }
  };

  const handleAddAircraft = () => {
    navigate('/add-aircraft');
  };

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="dashboard-container">
      <DashboardSidebar />
      <section className="dashboard-content">
        <div className="listing-container">
          <h1>Post a Have Listing</h1>
          {fleet.length > 0 ? (
            <form onSubmit={handleSubmit} className="listing-form">
              <div className="form-group">
                <label htmlFor="charter_date_start">Start Date:</label>
                <input
                  type="date"
                  name="charter_date_start"
                  value={formData.charter_date_start}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="charter_date_end">End Date:</label>
                <input
                  type="date"
                  name="charter_date_end"
                  value={formData.charter_date_end}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="flexibility">Flexibility (Days):</label>
                <input
                  type="number"
                  name="flexibility"
                  min="0"
                  value={formData.flexibility}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="from">From:</label>
                <input
                  type="text"
                  name="from"
                  value={formData.from}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="to">To: (If Multiple Legs, put all legs here)</label>
                <input
                  type="text"
                  name="to"
                  value={formData.to}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="passengers">Passengers:</label>
                <input
                  type="number"
                  name="passengers"
                  min="1"
                  max="500"
                  value={formData.passengers}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="aircraft_id">Aircraft Type:</label>
                <select
                  name="aircraft_id"
                  value={formData.aircraft_id}
                  onChange={handleChange}
                  required
                >
                  {fleet.map((aircraft) => (
                    <option key={aircraft.id} value={aircraft.id}>
                      {aircraft.aircraft_type}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit" className="listing-submit">Submit Listing</button>
            </form>
          ) : (
            <div className="no-aircraft-message">
              <p>You first need to add aircraft to your account</p>
              <button onClick={handleAddAircraft} className="add-aircraft-btn">Add Aircraft</button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default PostListingHave;
