import React, { useState, useEffect } from 'react';
import DashboardSidebar from './DashboardSidebar';
import { useAuth } from '../contexts/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import './MyFleet.css';
import { FaTimes } from 'react-icons/fa';

const MyFleet = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [fleet, setFleet] = useState([]);

  const fetchAircraft = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_URL}/api/addAircraft/myaircraft`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch fleet');
      }

      const data = await response.json();
      setFleet(data);
    } catch (error) {
      //console.error('Error fetching fleet:', error);
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { replace: true });
      return;
    }

    fetchAircraft();
  }, [isLoggedIn, navigate]);

  const handleDelete = async (aircraftId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this aircraft?");
    if (isConfirmed) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_URL}/api/addAircraft/myaircraft/${aircraftId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete aircraft');
        }

        setFleet(fleet.filter((aircraft) => aircraft.id !== aircraftId));
      } catch (error) {
        //console.error('Error deleting aircraft:', error);
      }
    }
  };

  const handleAddAircraft = () => {
    navigate('/add-aircraft');
  };

  return (
    <div className="dashboard-container">
      <DashboardSidebar />
      <section className="dashboard-content">
        <div className="fleet-container">
          <h1>My Fleet</h1>
          <button className="add-aircraft-btn" onClick={handleAddAircraft}>
            Add Aircraft
          </button>
          {fleet.length === 0 ? (
            <div className="no-aircraft-message">You have no Aircraft</div>
          ) : (
            <div className="table-container">
              <table className="fleet-table">
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Aircraft Type</th>
                    <th>Passengers</th>
                    <th>Year</th>
                    <th>Home Base</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {fleet.map((aircraft) => (
                    <tr key={aircraft.id}>
                      <td>{aircraft.operator}</td>
                      <td>{aircraft.aircraft_type}</td>
                      <td>{aircraft.passengers}</td>
                      <td>{aircraft.year}</td>
                      <td>{aircraft.home_base}</td>
                      <td>
                        <FaTimes onClick={() => handleDelete(aircraft.id)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default MyFleet;
