import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { API_URL } from '../../config';
import { Helmet } from 'react-helmet';
import goldCheck from './gold_check.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPlane, faHandshake, faPlaneDeparture, faTimesCircle, faBullhorn, faListAlt, faEye, faUsers, faBars, faGlobe, faShieldAlt, faChartLine, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import './PricingPage.css';

const testimonials = [
    {
        quote: "At first I thought it was expensive, but I've already booked 4 charters in 1 week directly with the passengers. Well worth the small $500/month fee.",
        name: "Bill N, Director of Operations",
    },
    {
        quote: "Charter God allows me to post and go directly to the operator. No broker markup.",
        name: "Sarah W, Executive Personal Assistant",
    },
    {
        quote: "My principal is constantly making me go find cheaper charters. I am too busy. Post once on Charter God and I go direct to operators!",
        name: "Matt N, Executive Personal Assistant",
    },
    {
        quote: "Worked out a few kickbacks with the operator directly.",
        name: "Emily J, Director of Travel",
    },
];

const PricingPage = () => {
    const { isLoggedIn } = useAuth();
    const [userPlan, setUserPlan] = useState('');
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        if (isLoggedIn) {
            const token = localStorage.getItem('token');
            fetch(`${API_URL}/api/user/profile`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.user) {
                        setUserPlan(data.user.charter_plan);
                        setUserEmail(data.user.email);
                    }
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        }
    }, [isLoggedIn]);

    const formatTime = (time) => {
        return time < 10 ? `0${time}` : time;
    };

    const CountdownTimer = () => {
        const [timeLeft, setTimeLeft] = useState({
            hours: 3,
            minutes: 29,
            seconds: 15,
        });

        useEffect(() => {
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    let { hours, minutes, seconds } = prevTime;

                    if (seconds > 0) {
                        seconds -= 1;
                    } else if (minutes > 0) {
                        minutes -= 1;
                        seconds = 59;
                    } else if (hours > 0) {
                        hours -= 1;
                        minutes = 59;
                        seconds = 59;
                    }

                    return { hours, minutes, seconds };
                });
            }, 1000);

            return () => clearInterval(timer);
        }, []);

        return (
            <div className="countdown-timer">
                <span>{formatTime(timeLeft.hours)}:</span>
                <span>{formatTime(timeLeft.minutes)}:</span>
                <span>{formatTime(timeLeft.seconds)}</span>
            </div>
        );
    };

    return (
        <div className="pricing-page-container">
            <Helmet>
                <title>Pricing Plans - Charter God</title>
                <meta name="description" content="Explore various pricing plans of Charter God to find the perfect match for your needs. Whether you're a broker or an operator, we have a plan that suits your charter management requirements." />
                <meta name="keywords" content="charter god pricing, charter plans, monthly subscription, yearly subscription, charter services" />
                <link rel="canonical" href="https://www.chartergod.com/pricing" />
            </Helmet>
            <header className="hero-section">
                <div className="hero-content text-center">
                    <h1><span className="highlight" style={{ color: '#dcb967' }}></span> Pricing</h1>
                    <div className="row hero-text-blocks">
                        <div className="col-md-4 benefit-box">
                            <div className="benefit-content">
                                <FontAwesomeIcon icon={faBullhorn} size="2x" />
                                <h3>Sell More Charters</h3>
                                <p>Connect with Passengers, Small Brokers & Executive Assistants Directly</p>
                            </div>
                        </div>
                        <div className="col-md-4 benefit-box">
                            <div className="benefit-content">
                                <FontAwesomeIcon icon={faListAlt} size="2x" />
                                <h3>More Listings</h3>
                                <p>We don't charge a fee to post listings, thereby getting more opportunities than other platforms</p>
                            </div>
                        </div>
                        <div className="col-md-4 benefit-box">
                            <div className="benefit-content">
                                <FontAwesomeIcon icon={faEye} size="2x" />
                                <h3>Visibility</h3>
                                <p>Post Have's and increase your visibility</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row counter-container">
                      <div className="col-md-4 counter-box">
                        <FontAwesomeIcon icon={faPlane} size="2x" />
                        <h2 className="counter" data-target="2011">2011</h2>
                        <p># of Operators</p>
                      </div>
                      <div className="col-md-4 counter-box">
                        <FontAwesomeIcon icon={faHandshake} size="2x" />
                        <h2 className="counter" data-target="159">159</h2>
                        <p># of Brokers</p>
                      </div>
                      <div className="col-md-4 counter-box">
                        <FontAwesomeIcon icon={faPlaneDeparture} size="2x" />
                        <h2 className="counter" data-target="231">231</h2>
                        <p># of Direct Passengers</p>
                      </div>
                      <p><br></br></p>
                        <p><br></br></p>

                    </div>
                </div>
            </header>
            <section className="pricing-section">
                <div className="container text-center">
                    <h2>Pricing</h2>
                    <p>Limited time offer. 70% off Yearly. 50% off Monthly Memberships.</p>
                    <CountdownTimer />
                    <div className="pricing-options row justify-content-center">
                        <div className="pricing-card col-md-4">
                            <h2>14 Day FREE Trial</h2>
                            <p className="price">
                                <span className="original-price">$999.99 / month</span>
                                FREE for 14 days, then $299.99 / month
                            </p>
                            <ul className="features">
                                <li>✅ Post Charter Need Requests</li>
                                <li>✅ Message to Brokers</li>
                                <li>✅ Message to Clients</li>
                                <li>✅ Message to Executive Assistants</li>
                                <li>✅ View Charter Listings</li>
                                <li>✅ Post Charter Have Requests</li>
                            </ul>
                            <a href={`https://buy.stripe.com/28o5kA0vHgrBbbWaEM?prefilled_email=${userEmail || ''}`}>
                                <button className={`button upgrade ${userPlan === 'monthly' ? 'current' : ''}`}>{userPlan === 'monthly' ? 'Current Plan' : 'Upgrade'}</button>
                            </a>
                        </div>
                        <div className="pricing-card col-md-4 featured">
                            <h2>Yearly</h2>
                            <p className="price">
                                <span className="original-price">$9,999.99 / year</span>
                                $1,799.99 / year
                            </p>
                            <ul className="features">
                                <li>✅ Post Charter Need Requests</li>
                                <li>✅ Message to Brokers</li>
                                <li>✅ Message to Clients</li>
                                <li>✅ Message to Executive Assistants</li>
                                <li>✅ View Charter Listings</li>
                                <li>✅ Post Charter Have Requests</li>
                            </ul>
                            <a href={`https://buy.stripe.com/00g7sI7Y94ITa7S9AJ?prefilled_email=${userEmail || ''}`}>
                                <button className={`button upgrade ${userPlan === 'yearly' ? 'current' : ''}`}>{userPlan === 'yearly' ? 'Current Plan' : 'Upgrade'}</button>
                            </a>
                        </div>
                        {userPlan !== 'yearly' && userPlan !== 'monthly' && (
                            <div className="pricing-card col-md-4">
                                <h2>FREE</h2>
                                <ul className="features">
                                    <li><br></br><br></br></li>
                                    <li>✅ Post Charter Need Requests</li>
                                    <li>❌ Message to Brokers</li>
                                    <li>❌ Message to Clients</li>
                                    <li>❌ Message to Executive Assistants</li>
                                    <li>❌ View Charter Listings</li>
                                    <li>❌ Post Charter Have Requests</li>
                                </ul>
                                {isLoggedIn ? (
                                    <button className={`button ${userPlan === 'free' ? 'current' : ''}`}>{userPlan === 'free' ? 'Current Plan' : 'Register'}</button>
                                ) : (
                                    <a href="/register">
                                        <button className="button register">Register</button>
                                    </a>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="pricing-comparison-table plan-comparison-section">
                        <h2>Plan Comparison</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Feature</th>
                                    <th>Operator Monthly</th>
                                    <th>Operator Yearly</th>
                                    <th>Free Account</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Post Charter Need Requests </td>
                                    <td>✅</td>
                                    <td>✅</td>
                                    <td>✅</td>
                                </tr>
                                <tr>
                                    <td>Message Brokers, Clients, & Executive Assistants</td>
                                    <td>✅</td>
                                    <td>✅</td>
                                    <td>❌</td>
                                </tr>
                                <tr>
                                    <td>View the details of Charter Request Listings</td>
                                    <td>✅</td>
                                    <td>✅</td>
                                    <td>❌</td>
                                </tr>
                                <tr>
                                    <td>Post Charter Have Requests</td>
                                    <td>✅</td>
                                    <td>✅</td>
                                    <td>❌</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="pricing-comparison-to-competitors">
                        <h2>Competitor Comparison</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Service</th>
                                    <th>Charter God</th>
                                    <th>Avinode</th>
                                    <th>Charter Pad</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Monthly Fee</td>
                                    <td>$299.99</td>
                                    <td>Not Available</td>
                                    <td>$700</td>
                                </tr>
                                <tr>
                                    <td>Yearly Fee</td>
                                    <td>$1,799.99</td>
                                    <td>$23,988</td>
                                    <td>$8,400</td>
                                </tr>
                                <tr>
                                    <td>FREE Broker Access</td>
                                    <td>✅</td>
                                    <td>❌</td>
                                    <td>❌</td>
                                </tr>
                                <tr>
                                    <td>FREE Executive Assistant Access </td>
                                    <td>✅</td>
                                    <td>❌</td>
                                    <td>❌</td>
                                </tr>
                                <tr>
                                    <td>FREE Passenger Access </td>
                                    <td>✅</td>
                                    <td>❌</td>
                                    <td>❌</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </section>
            <section className="features-section">
                <div className="container text-center">
                    <h2>Benefits</h2>
                    <div className="row justify-content-center" style={{ marginTop: '30px' }}>
                        <div className="col-md-3 benefit-box">
                            <div className="benefit-content">
                                <FontAwesomeIcon icon={faUsers} size="2x" />
                                <h3>Direct Access to Clients</h3>
                                <p>Connect directly with Executive Assistants and passengers, cutting out the middleman and saving on fees.</p>
                            </div>
                        </div>
                        <div className="col-md-3 benefit-box">
                            <div className="benefit-content">
                                <FontAwesomeIcon icon={faBars} size="2x" />
                                <h3>Reach Smaller Brokers</h3>
                                <p>Access smaller brokers who can't afford expensive memberships with other platforms like Charter Pad or Avinode.</p>
                            </div>
                        </div>
                        <div className="w-100"></div>
                        <div className="col-md-3 benefit-box">
                            <div className="benefit-content">
                                <FontAwesomeIcon icon={faGlobe} size="2x" />
                                <h3>Expand Internationally</h3>
                                <p>Reach international brokers and operators who are outside the US and may not have NBAA memberships.</p>
                            </div>
                        </div>
                        <div className="col-md-3 benefit-box">
                            <div className="benefit-content">
                                <FontAwesomeIcon icon={faShieldAlt} size="2x" />
                                <h3>Lower Barrier to Entry</h3>
                                <p>Our platform offers a lower barrier to entry for posting, leading to more listings and a wider client base.</p>
                            </div>
                        </div>
                        <div className="col-md-3 benefit-box">
                            <div className="benefit-content">
                                <FontAwesomeIcon icon={faChartLine} size="2x" />
                                <h3>Increase Visibility</h3>
                                <p>Advertise your empty legs and maximize your revenue with greater visibility.</p>
                            </div>
                        </div>
                        <div className="col-md-3 benefit-box">
                            <div className="benefit-content">
                                <FontAwesomeIcon icon={faDollarSign} size="2x" />
                                <h3>Free Posting for Brokers</h3>
                                <p>More listings lead to better choices for clients and operators alike.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials-section">
                <div className="container text-center">
                    <h2>Testimonials</h2>
                    {testimonials.map((testimonial, index) => (
                        <blockquote key={index}>
                            <p>"{testimonial.quote}"</p>
                            <footer>{testimonial.name}</footer>
                        </blockquote>
                    ))}
                </div>
            </section>
            <FAQ />
        </div>
    );
};

const FAQ = () => {
    const [expanded, setExpanded] = useState({});

    // Function to toggle answer visibility
    const toggleAnswer = (index) => {
        setExpanded((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    return (
        <div className="FAQ">
            <Helmet>
                <title>FAQs | Charter God - Your Guide to Air Charter Services</title>
                <meta name="description" content="Find answers to frequently asked questions about Charter God, the marketplace connecting brokers, operators, and clients for air charter services worldwide." />
                <meta name="keywords" content="Charter God FAQ, air charter services, private jet bookings, charter flight FAQs, charter service questions" />
                <link rel="canonical" href="https://www.chartergod.com/faq" />
            </Helmet>
            <h1>FAQ's</h1>
            <div className="questions">
                {faqData.map((item, index) => (
                    <div key={index} className="question">
                        <h3 onClick={() => toggleAnswer(index)}>
                            {item.question} 
                            {expanded[index] ? (
                                <span>&#9650;</span>
                            ) : (
                                <span>&#9660;</span>
                            )}
                        </h3>
                        {expanded[index] && (
                            <div
                                className="answer"
                                dangerouslySetInnerHTML={{ __html: item.answer.replace(/contact form/g, '<a href="/contact">contact form</a>') }}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

const faqData = [
    {
        question: 'How Does Charter God Work?',
        answer: `Charter God facilitates the connection between aviation professionals and clients seeking charter services. Users can submit and interact with charter "Needs" and "Haves" tailored to their specific requirements and preferences.`,
    },
    {
        question: 'Is Charter God Free to Use?',
        answer: `Charter God provides complimentary access to browse and view both charter "Haves" and "Needs." Messaging regarding "Haves" is also provided at no cost. Posting charter "Needs" is free of charge. However, to engage in messaging regarding charter "Needs," users are required to upgrade to a membership tier. Similarly, to post charter "Haves," users must also upgrade to a membership tier.<br/><br/>For more details on membership tiers and associated benefits, please visit our <a href="/pricing">Pricing page</a>.`,
    },
    {
        question: 'What is a Need?',
        answer: `A "Need" refers to a specific request for air charter services, typically initiated by a broker or a direct client. These requests are tailored to individual travel requirements and preferences.`,
    },
    {
        question: 'What is a Have?',
        answer: `A "Have" typically refers to available charter flights, often termed as "Empty Legs" or transient aircraft. These flights offer flexibility in departure and arrival times and are often available at discounted rates.`,
    },
    {
        question: 'What is an Empty Leg?',
        answer: `An "Empty Leg" signifies a one-way private jet charter flight where the aircraft must return to its base or another location without passengers. These flights present an opportunity for travelers to access private jet travel at reduced rates due to the aircraft's positioning needs.`,
    },
    {
        question: 'How do I cancel my membership?',
        answer: `Users can cancel their membership by submitting a request via the <a href="/contact">contact form</a> provided on our website.`,
    },
    {
        question: 'What is the refund policy?',
        answer: `Users can request a refund within 24 hours of a payment or recurring billing charge by submitting a request via the <a href="/contact">contact form</a> on our website.`,
    }
];

export default PricingPage;
