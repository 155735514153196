import React, { useEffect } from 'react';
import { AuthProvider } from './components/contexts/AuthContext';
import './App.css';
import Home from './components/home/Home';
import NavBar from './components/NavBar/NavBar';
import Register from './components/registration/register';
import LoginForm from './components/login/LoginForm';
import EmailVerification from './components/registration/EmailVerification';
import ContactForm from './components/contact/contact';
import PricingPage from './components/pricing/PricingPage';
import MyDashboard from './components/dashboard/myDashboard';
import MyProfile from './components/dashboard/MyProfile';
import EmailNotifications from './components/dashboard/EmailNotifications';
import Footer from './components/footer/Footer';
import SiteMap from './components/footer/sitemap';
import ChangePassword from './components/dashboard/changePassword';
import MyListings from './components/dashboard/myListings';
import MyMessage from './components/dashboard/MyMessage';
import SendMessage from './components/SendMessage/SendMessage';
import MyPosts from './components/dashboard/MyPosts';
import PostListingHave from './components/dashboard/PostListingHave';
import PostPaymentPage from './components/PostPaymentPage/PostPaymentPage';
import GroundTransport from './components/directory/groundTransport';
import Catering from './components/directory/catering';
import FBO from './components/directory/fbo';
import Blog from './components/blog/blog';
import Article from './components/blog/article';
import ForgotPassword from './components/login/ForgotPassword';
import ResetPassword from './components/login/ResetPassword';
import LoginMessage from './components/login/LoginMessage';
import FAQ from './components/faq/faq';
import MyFleet from './components/dashboard/MyFleet'; // Import the MyFleet component
import EmailSubscription from './components/unsubscribe/EmailSubscription'; // Import the EmailSubscription component
import { useAnalytics } from 'react-ga4';
import LandingPage from './components/landingpages/LandingPage';


import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import AddAircraft from './components/dashboard/AddAircraft';
import TermsOfService from './components/footer/termsofservice';
import PrivacyPolicy from './components/footer/privacypolicy';
import PostListing from './components/dashboard/PostListing';
import About from './components/about/About';
import CookieConsent from 'react-cookie-consent';

// Article imports
import JetPrivateJet from './components/articles/jet-private-jet';
import PrivateAirCharter from './components/articles/private-air-charter';
import PrivateAircraftCharter from './components/articles/private-aircraft-charter';
import PrivateAircraftRental from './components/articles/private-aircraft-rental';
import PrivateAirplanes from './components/articles/private-airplanes';
import PrivateFlightCharter from './components/articles/private-flight-charter';
import PrivateJetAirCharter from './components/articles/private-jet-air-charter';
import PrivateJetCharterFlights from './components/articles/private-jet-charter-flights';
import PrivateJetCost from './components/articles/private-jet-cost';
import PrivateJetRental from './components/articles/private-jet-rental';
import PrivateJet from './components/articles/private-jet';
import PrivatePlaneJet from './components/articles/private-plane-jet';
import PrivatePlanesForCharter from './components/articles/private-planes-for-charter';
import PrivateJetCharter from './components/articles/private-jet-charter';
import PrivatePrivateJet from './components/articles/private-private-jet';
import AirCharter from './components/articles/air-charter';
import PrivateJetFlights from './components/articles/private-jet-flights';
import PrivateJetPrice from './components/articles/private-jet-price';
import CharterFlights from './components/articles/charter-flights';
import CheapPrivateJetFlights from './components/articles/cheap-private-jet-flights';
import PrivateJetRentalCost from './components/articles/private-jet-rental-cost';
import PrivateJetMembership from './components/articles/private-jet-membership';
import PrivateJetCompanies from './components/articles/private-jet-companies';
import AirCharterService from './components/articles/air-charter-service';
import PrivateAirlines from './components/articles/private-airlines';
import PrivateJetServices from './components/articles/private-jet-services';
import PrivatePlaneFlights from './components/articles/private-plane-flights';
import EmptyLegPrivateJet from './components/articles/empty-leg-private-jet';
import PrivateJetCostPerHour from './components/articles/private-jet-cost-per-hour';
import AeroPrivateJet from './components/articles/aero-private-jet';
import PrivateJetCharterCost from './components/articles/private-jet-charter-cost';
import PrivateJetRentalCostPerHour from './components/articles/private-jet-rental-cost-per-hour';
import PrivateJetFlightCost from './components/articles/private-jet-flight-cost';
import CharterAirlines from './components/articles/charter-airlines';
import FlyPrivateJet from './components/articles/fly-private-jet';
import CheapestPrivateJet from './components/articles/cheapest-private-jet';
import PrivateJetEmptyLegFlights from './components/articles/private-jet-empty-leg-flights';
import CheapPrivateFlights from './components/articles/cheap-private-flights';
import SemiPrivateAirlines from './components/articles/semi-private-airlines';
import AffordablePrivateJet from './components/articles/affordable-private-jet';
import LuxuryPrivateJets from './components/articles/luxury-private-jets';
import PrivateJetSharedFlights from './components/articles/private-jet-shared-flights';
import PrivateAirplaneFlights from './components/articles/private-airplane-flights';
import FlyPrivateJetCheap from './components/articles/fly-private-jet-cheap';
import AircraftCharter from './components/articles/aircraft-charter';
import PrivateJetCharterPrices from './components/articles/private-jet-charter-prices';
import CharteredFlightCost from './components/articles/chartered-flight-cost';
import PrivateJetAirlines from './components/articles/private-jet-airlines';
import PrivateAirlineCompanies from './components/articles/private-airline-companies';
import CharterAirlineCompanies from './components/articles/charter-airline-companies';
import CharterFlightCompanies from './components/articles/charter-flight-companies';
import PrivateCharterFlightsCost from './components/articles/private-charter-flights-cost';
import CharterPlaneCompanies from './components/articles/charter-plane-companies';
import PrivateAirlineFlights from './components/articles/private-airline-flights';
import PrivateJetBookings from './components/articles/private-jet-bookings';
import PrivateJetFlightPrice from './components/articles/private-jet-flight-price';
import SharedFlightsPrivateJets from './components/articles/shared-flights-private-jets';
import PrivateAirFlights from './components/articles/private-air-flights';
import FlyingPrivateJetCost from './components/articles/flying-private-jet-cost';
import RentAJetPlane from './components/articles/rent-a-jet-plane';
import LowCostPrivateJetFlights from './components/articles/low-cost-private-jet-flights';
import PrivateCharterAirlines from './components/articles/private-charter-airlines';
import JetPrivateFlights from './components/articles/jet-private-flights';
import PrivateJetPricePerHour from './components/articles/private-jet-price-per-hour';
import CostToFlyPrivateJetPerHour from './components/articles/cost-to-fly-private-jet-per-hour';
import FlyPrivateJetPrice from './components/articles/fly-private-jet-price';
import CharterAircraftCost from './components/articles/charter-aircraft-cost';
import CheapPrivatePlaneFlights from './components/articles/cheap-private-plane-flights';
import PrivateJetAirfare from './components/articles/private-jet-airfare';
import LowCostPrivateJet from './components/articles/low-cost-private-jet';
import PrivateAirCharterPrices from './components/articles/private-air-charter-prices';
import PrivateFlightsPrices from './components/articles/private-flights-prices';
import PrivateJetAffordable from './components/articles/private-jet-affordable';
import FlyingOnAPrivateJetCost from './components/articles/flying-on-a-private-jet-cost';
import CostOfPrivatePlaneRental from './components/articles/cost-of-private-plane-rental';
import PrivateJetCostToFly from './components/articles/private-jet-cost-to-fly';
import FlyPrivateJetEmptyLeg from './components/articles/fly-private-jet-empty-leg';
import FlyPrivateCharter from './components/articles/fly-private-charter';
import PrivateAircraftFlights from './components/articles/private-aircraft-flights';
import AirPlaneRental from './components/articles/air-plane-rental';
import Bombardier8000Price from './components/articles/bombardier-8000-price';
import PrivateJetAircraft from './components/articles/private-jet-aircraft';
import CorporatePrivateJet from './components/articles/corporate-private-jet';
import PrivateCharterFlightsPrices from './components/articles/private-charter-flights-prices';
import CharterAirlinesCost from './components/articles/charter-airlines-cost';
import AirportCharter from './components/articles/airport-charter';
import PrivateJetPlaneCost from './components/articles/private-jet-plane-cost';
import FlyingPrivateCheap from './components/articles/flying-private-cheap';
import PrivateJetAirplane from './components/articles/private-jet-airplane';
import AirCharterCost from './components/articles/air-charter-cost';
import PricePrivateJetCharter from './components/articles/price-private-jet-charter';
import CompanyPrivateJet from './components/articles/company-private-jet';
import PlanePrivateJet from './components/articles/plane-private-jet';
import PrivateAirCharterFlights from './components/articles/private-air-charter-flights';
import AirCharterJet from './components/articles/air-charter-jet';
import CostPrivateJetRental from './components/articles/cost-private-jet-rental';
import DeltaPrivateJetsInc from './components/articles/delta-private-jets-inc';
import PrivateAircraftCharterPrices from './components/articles/private-aircraft-charter-prices';
import PrivateJetBest from './components/articles/private-jet-best';
import SwiftAirAirlines from './components/articles/swift-air-airlines';
import PrivateJetHire from './components/articles/private-jet-hire';
import PrivateJetQuote from './components/articles/private-jet-quote';
import CharterFlightsNearMe from './components/articles/charter-flights-near-me';
import WheelsUpPrivateJet from './components/articles/wheels-up-private-jet';
import PrivateJetHireCost from './components/articles/private-jet-hire-cost';
import PrivateJetRentalNearMe from './components/articles/private-jet-rental-near-me';
import PrivateAirTravel from './components/articles/private-air-travel';
import PrivateJetCharterCompanies from './components/articles/private-jet-charter-companies';
import PrivateCharterFlightsNearMe from './components/articles/private-charter-flights-near-me';
import BestPrivateJetCompanies from './components/articles/best-private-jet-companies';
import CharterPlaneFlights from './components/articles/charter-plane-flights';
import PrivateJetTravel from './components/articles/private-jet-travel';
import PrivateAirlinesNearMe from './components/articles/private-airlines-near-me';
import PrivateJetShare from './components/articles/private-jet-share';
import CheapPrivateJetRental from './components/articles/cheap-private-jet-rental';
import CheapCharterFlights from './components/articles/cheap-charter-flights';
import CheapestPrivateJetCharter from './components/articles/cheapest-private-jet-charter';
import PrivateJetNearMe from './components/articles/private-jet-near-me';
import BestPrivateJetCharterCompanies from './components/articles/best-private-jet-charter-companies';
import PrivateJetServicesNearMe from './components/articles/private-jet-services-near-me';
import SemiPrivateFlightsNearMe from './components/articles/semi-private-flights-near-me';
import RentAJetPrice from './components/articles/rent-a-jet-price';
import EmptyLegPrivateJetFlights from './components/articles/empty-leg-private-jet-flights';
import RentPrivateJetPrice from './components/articles/rent-private-jet-price';
import PrivateJetBroker from './components/articles/private-jet-broker';
import PrivateJetInstantQuote from './components/articles/private-jet-instant-quote';
import SharedCharterFlights from './components/articles/shared-charter-flights';
import AffordableCharterFlights from './components/articles/affordable-charter-flights';
import PrivateJetCompaniesNearMe from './components/articles/private-jet-companies-near-me';
import Top10PrivateJetCharterCompanies from './components/articles/top-10-private-jet-charter-companies';
import AirCharterBroker from './components/articles/air-charter-broker';
import InternationalCharterFlights from './components/articles/international-charter-flights';
import SemiPrivateJetFlights from './components/articles/semi-private-jet-flights';
import JetsuitexFlights from './components/articles/jetsuitex-flights';
import PrivateJetTravelCost from './components/articles/private-jet-travel-cost';
import PrivateJetRentalCompanies from './components/articles/private-jet-rental-companies';
import PrivateAir from './components/articles/private-air';
import PrivateJetCharterQuote from './components/articles/private-jet-charter-quote';
import CubazulAirCharter from './components/articles/cubazul-air-charter';
import SharedPrivateFlights from './components/articles/shared-private-flights';
import BestPrivateJetService from './components/articles/best-private-jet-service';
import TopPrivateJetCompanies from './components/articles/top-private-jet-companies';
import InternationalPrivateJet from './components/articles/international-private-jet';
import SmallPlaneCharter from './components/articles/small-plane-charter';
import PrivateInternationalFlight from './components/articles/private-international-flight';
import PrivateAirCharterCompanies from './components/articles/private-air-charter-companies';
import CharterJetFlights from './components/articles/charter-jet-flights';
import PrivateJetCharterNearMe from './components/articles/private-jet-charter-near-me';
import BestJetCharterCompanies from './components/articles/best-jet-charter-companies';
import KingAirCharter from './components/articles/king-air-charter';
import JetCharterBroker from './components/articles/jet-charter-broker';
import PrivateJetTripCost from './components/articles/private-jet-trip-cost';
import BestPrivateJetForInternationalTravel from './components/articles/best-private-jet-for-international-travel';
import JetCharterService from './components/articles/jet-charter-service';
import CheapestPrivateJetToRent from './components/articles/cheapest-private-jet-to-rent';
import PrivateJetServiceCost from './components/articles/private-jet-service-cost';
import CharterFlightQuote from './components/articles/charter-flight-quote';
import SemiPrivateJetCompanies from './components/articles/semi-private-jet-companies';
import PrivateJetCostPerFlight from './components/articles/private-jet-cost-per-flight';
import AircraftCharterCompanies from './components/articles/aircraft-charter-companies';
import PrivateJetLeasing from './components/articles/private-jet-leasing';
import BestCharterFlightCompanies from './components/articles/best-charter-flight-companies';
import CharterFlightServices from './components/articles/charter-flight-services';
import JetCharterNearMe from './components/articles/jet-charter-near-me';
import BestPrivateJetCharter from './components/articles/best-private-jet-charter';
import PrivateJetTrip from './components/articles/private-jet-trip';
import PrivateAircraftHire from './components/articles/private-aircraft-hire';
import SwiftAirCharter from './components/articles/swift-air-charter';
import AirCharterCompanies from './components/articles/air-charter-companies';
import PrivateAircraftCharterServices from './components/articles/private-aircraft-charter-services';
import CharterFlightPrices from './components/articles/charter-flight-prices';
import PrivateJetPlane from './components/articles/private-jet-plane';
import AviationCharterCompanies from './components/articles/aviation-charter-companies';
import AircraftCharterNearMe from './components/articles/aircraft-charter-near-me';
import CharterAircraftNearMe from './components/articles/charter-aircraft-near-me';
import AirChartersNearMe from './components/articles/air-charters-near-me';
import PrivateJetsMiami from './components/articles/private-jets-miami';
import CommercialPrivateJet from './components/articles/commercial-private-jet';
import CargoCharter from './components/articles/cargo-charter';
import AverageCostToCharterAPrivateJet from './components/articles/average-cost-to-charter-a-private-jet';
import AveragePrivateJetCost from './components/articles/average-private-jet-cost';
import FlyCharter from './components/articles/fly-charter';
import CharterPlaneService from './components/articles/charter-plane-service';
import AircraftCharterBroker from './components/articles/aircraft-charter-broker';
import PrivateJetCharterServices from './components/articles/private-jet-charter-services';
import CostToRentAJet from './components/articles/cost-to-rent-a-jet';
import CharterAirService from './components/articles/charter-air-service';
import CargoCharterFlight from './components/articles/cargo-charter-flight';
import LuxuryPrivateJetCharter from './components/articles/luxury-private-jet-charter';
import AviationCharter from './components/articles/aviation-charter';
import PrivateJetBusiness from './components/articles/private-jet-business';
import CheapJetCharter from './components/articles/cheap-jet-charter';
import PremierPrivateJetCharter from './components/articles/premier-private-jet-charter';
import PrivateJetForInternationalTravel from './components/articles/private-jet-for-international-travel';
import SwiftAirCharterFlights from './components/articles/swift-air-charter-flights';
import AverageCostToRentAPrivateJet from './components/articles/average-cost-to-rent-a-private-jet';
import ExecutiveAirCharter from './components/articles/executive-air-charter';
import PrivateJetsNewYork from './components/articles/private-jets-new-york';
import AirCharterQuote from './components/articles/air-charter-quote';
import LargestPrivateJetCompanies from './components/articles/largest-private-jet-companies';
import PrivateFlightHire from './components/articles/private-flight-hire';
import AirCharterServiceInc from './components/articles/air-charter-service-inc';
import UnitedCharterFlights from './components/articles/united-charter-flights';
import SmallPlaneCharterFlights from './components/articles/small-plane-charter-flights';
import CitationPrivateJet from './components/articles/citation-private-jet';
import PrivateAirlineService from './components/articles/private-airline-service';
import CharterJetAirlines from './components/articles/charter-jet-airlines';
import CharterAirlineFlights from './components/articles/charter-airline-flights';
import AllegiantCharterFlights from './components/articles/allegiant-charter-flights';
import LeasingPrivateJetCost from './components/articles/leasing-private-jet-cost';
import CorporateAir from './components/articles/corporate-air';
import TopRatedPrivateJetCompanies from './components/articles/top-rated-private-jet-companies';
import BiggestPrivateJetCompanies from './components/articles/biggest-private-jet-companies';
import CharterAircraftServices from './components/articles/charter-aircraft-services';
import LowCostCharterFlights from './components/articles/low-cost-charter-flights';
import AverageCostOfPrivateJetFlight from './components/articles/average-cost-of-private-jet-flight';
import PrivateJetCenter from './components/articles/private-jet-center';
import BestAirCharterCompanies from './components/articles/best-air-charter-companies';
import InternationalAirCharters from './components/articles/international-air-charters';
import PrivateJetAirportNearMe from './components/articles/private-jet-airport-near-me';
import AirXCharter from './components/articles/air-x-charter';
import GroupAirCharter from './components/articles/group-air-charter';
import FlyCharterAirlines from './components/articles/fly-charter-airlines';
import RentACharterFlight from './components/articles/rent-a-charter-flight';
import AeroCharters from './components/articles/aero-charters';
import PrivateJetLa from './components/articles/private-jet-la';
import UnitedAirlinesPrivateJet from './components/articles/united-airlines-private-jet';
import SmallAircraftCharterFlights from './components/articles/small-aircraft-charter-flights';
import LuxuryPrivateJetHire from './components/articles/luxury-private-jet-hire';
import CheapCharterPlanes from './components/articles/cheap-charter-planes';
import AveragePriceForAPrivateJet from './components/articles/average-price-for-a-private-jet';
import CharterFlightBroker from './components/articles/charter-flight-broker';
import WestAirCharter from './components/articles/west-air-charter';
import AverageCostOfCharteringAPrivateJet from './components/articles/average-cost-of-chartering-a-private-jet';
import FlyCharterCheap from './components/articles/fly-charter-cheap';
import GroupPlaneCharter from './components/articles/group-plane-charter';
import PrivateCharterFlightCompanies from './components/articles/private-charter-flight-companies';
import PrivateBusinessJets from './components/articles/private-business-jets';
import BestCharterAirlines from './components/articles/best-charter-airlines';
import PrivateJetOneWay from './components/articles/private-jet-one-way';
import BestCharterFlights from './components/articles/best-charter-flights';
import AircharterGuide from './components/articles/aircharter-guide';
import CostToRentAJetPlane from './components/articles/cost-to-rent-a-jet-plane';
import AirCharterWorldwide from './components/articles/air-charter-worldwide';
import GroupAircraftCharter from './components/articles/group-aircraft-charter';
import PrivateAircraftServices from './components/articles/private-aircraft-services';
import SilverAirPrivateJet from './components/articles/silver-air-private-jet';
import EmptyLegPrivateJetFlight from './components/articles/empty-leg-private-jet-flight';
import PrivatePlaneCharterServices from './components/articles/private-plane-charter-services';
import UltimateJetCharter from './components/articles/ultimate-jet-charter';
import AircraftRentalService from './components/articles/aircraft-rental-service';
import RentACharterJet from './components/articles/rent-a-charter-jet';
import AircraftRentalCost from './components/articles/aircraft-rental-cost';
import BrokerPrivateJet from './components/articles/broker-private-jet';
import PrivateJetInc from './components/articles/private-jet-inc';
import AirportPrivateJet from './components/articles/airport-private-jet';
import KingAirCharterCost from './components/articles/king-air-charter-cost';
import CorporatePrivateJetCharter from './components/articles/corporate-private-jet-charter';
import TopPrivateJet from './components/articles/top-private-jet';
import BelAirCharter from './components/articles/bel-air-charter';
import InternationalAirCharter from './components/articles/international-air-charter';
import CheapAirCharter from './components/articles/cheap-air-charter';
import GlobeAirEmptyLeg from './components/articles/globe-air-empty-leg';
import UnitedAirlinesCharters from './components/articles/united-airlines-charters';
import AirCharterPrices from './components/articles/air-charter-prices';
import AircraftCharterGuide from './components/articles/aircraft-charter-guide';
import AirplaneCharterServices from './components/articles/airplane-charter-services';
import AlaskaAirCharter from './components/articles/alaska-air-charter';
import AllegiantAirCharter from './components/articles/allegiant-air-charter';
import AllegiantAirCharterFlights from './components/articles/allegiant-air-charter-flights';
import AmericanAirlinesCharter from './components/articles/american-airlines-charter';
import AmericanAirlinesCharterFlights from './components/articles/american-airlines-charter-flights';
import AtlasAirCharter from './components/articles/atlas-air-charter';
import AverageCostOfFlyingPrivateJet from './components/articles/average-cost-of-flying-private-jet';
import BusinessAirCharter from './components/articles/business-air-charter';
import BusinessAircraftCharter from './components/articles/business-aircraft-charter';
import CargoCharterAirlines from './components/articles/cargo-charter-airlines';
import CharterAirways from './components/articles/charter-airways';
import CharterFlightAirline from './components/articles/charter-flight-airline';
import CorporateAirCenter from './components/articles/corporate-air-center';
import EmptyLegAirCharter from './components/articles/empty-leg-air-charter';
import EuropeAirCharter from './components/articles/europe-air-charter';
import EuropeanAviationAirCharter from './components/articles/european-aviation-air-charter';
import EuropeanCharterAirline from './components/articles/european-charter-airline';
import ExecutiveAircraftCharter from './components/articles/executive-aircraft-charter';
import IslandAirCharter from './components/articles/island-air-charter';
import PhoenixAirCharter from './components/articles/phoenix-air-charter';
import PremierAirCharters from './components/articles/premier-air-charters';
import PrivateJetServicePrices from './components/articles/private-jet-service-prices';
import SafestPrivateJets from './components/articles/safest-private-jets';
import SouthernairCharter from './components/articles/southernair-charter';
import SummitAirCharters from './components/articles/summit-air-charters';
import SuperiorAirCharterLlc from './components/articles/superior-air-charter-llc';
import WindroseAirline from './components/articles/windrose-airline';
import PrivateJetMembershipCompanies from './components/articles/private-jet-membership-companies';
import PrivateJetHirePrice from './components/articles/private-jet-hire-price';
import CheapPrivateJetFlightsNearMe from './components/articles/cheap-private-jet-flights-near-me';
import CharterAPlaneNearMe from './components/articles/charter-a-plane-near-me';
import PrivateJetFlightsNearMe from './components/articles/private-jet-flights-near-me';
import XoPrivateJetCost from './components/articles/xo-private-jet-cost';
import PrivateJetRentalsNearMe from './components/articles/private-jet-rentals-near-me';
import PrivateJetCharterInstantQuote from './components/articles/private-jet-charter-instant-quote';
import PrivateJetHireCheap from './components/articles/private-jet-hire-cheap';
import AffordablePrivateJetFlights from './components/articles/affordable-private-jet-flights';
import InternationalPrivateJetCost from './components/articles/international-private-jet-cost';
import AirAmbulanceCharter from './components/articles/air-ambulance-charter';
import JsxCharterFlights from './components/articles/jsx-charter-flights';
import LuxuryPrivateJetRental from './components/articles/luxury-private-jet-rental';
import CheapCharterFlightsNearMe from './components/articles/cheap-charter-flights-near-me';
import PrivateAirTravelCompanies from './components/articles/private-air-travel-companies';
import PrivateJetReservations from './components/articles/private-jet-reservations';
import PrivateJetChartersNearMe from './components/articles/private-jet-charters-near-me';
import PrivateFlightCompanies from './components/articles/private-flight-companies';
import CharterAirlinesNearMe from './components/articles/charter-airlines-near-me';
import PrivateJetOptions from './components/articles/private-jet-options';
import NetjetsPrivateJet from './components/articles/netjets-private-jet';
import PrivateJetOperators from './components/articles/private-jet-operators';
import DeltaPrivateJetsCost from './components/articles/delta-private-jets-cost';
import RentAPrivateJetNearMe from './components/articles/rent-a-private-jet-near-me';
import PrivatePlaneFlightsNearMe from './components/articles/private-plane-flights-near-me';
import PrivateFlightQuote from './components/articles/private-flight-quote';
import LastMinuteCharterFlights from './components/articles/last-minute-charter-flights';
import UpPrivateJet from './components/articles/up-private-jet';
import PrivateJetLeasingCompanies from './components/articles/private-jet-leasing-companies';
import PrivateJetShareCompanies from './components/articles/private-jet-share-companies';
import MonarchAirCharter from './components/articles/monarch-air-charter';
import PrivateAirTravelCost from './components/articles/private-air-travel-cost';
import PrivateJetShareCost from './components/articles/private-jet-share-cost';
import PrivateJetDeals from './components/articles/private-jet-deals';
import BladeCharterFlights from './components/articles/blade-charter-flights';
import VisionJetCharter from './components/articles/vision-jet-charter';
import LastMinutePrivateJetEmptyLegs from './components/articles/last-minute-private-jet-empty-legs';
import PrivateJetCharterInternationalTravel from './components/articles/private-jet-charter-international-travel';
import PrivateJetGroupTravel from './components/articles/private-jet-group-travel';
import AirCargoCharter from './components/articles/air-cargo-charter';
import OneWayPrivateJetFlights from './components/articles/one-way-private-jet-flights';
import PrivateJetTransportation from './components/articles/private-jet-transportation';
import OnDemandPrivateJet from './components/articles/on-demand-private-jet';
import AeroPrivateFlights from './components/articles/aero-private-flights';
import CharterCargoPlane from './components/articles/charter-cargo-plane';
import PrivateFlightRental from './components/articles/private-flight-rental';
import PrivatePlaneFlightCost from './components/articles/private-plane-flight-cost';
import BestPrivateFlightCompanies from './components/articles/best-private-flight-companies';
import PrivateJetRoundTripCost from './components/articles/private-jet-round-trip-cost';
import AuricAirCharters from './components/articles/auric-air-charters';
import CheapSemiPrivateFlights from './components/articles/cheap-semi-private-flights';
import PrivateJetServiceCompanies from './components/articles/private-jet-service-companies';
import OnDemandCharterFlights from './components/articles/on-demand-charter-flights';
import CharterPrivateJetNearMe from './components/articles/charter-private-jet-near-me';
import Pc12Charter from './components/articles/pc12-charter';
import PrivateAirChartersNearMe from './components/articles/private-air-charters-near-me';
import PrivateJetCharterBroker from './components/articles/private-jet-charter-broker';
import TuiCharterFlights from './components/articles/tui-charter-flights';
import SilverAirCharter from './components/articles/silver-air-charter';
import BestPrivateCharterJetCompanies from './components/articles/best-private-charter-jet-companies';
import RvrAirCharter from './components/articles/rvr-air-charter';
import MostAffordablePrivateJetService from './components/articles/most-affordable-private-jet-service';
import AirCharterServiceNearMe from './components/articles/air-charter-service-near-me';
import BestPrivateAirlines from './components/articles/best-private-airlines';
import PrivateCommercialFlights from './components/articles/private-commercial-flights';
import PrivateJetLegFlights from './components/articles/private-jet-leg-flights';
import JetShareFlights from './components/articles/jet-share-flights';
import AeroCharterFlights from './components/articles/aero-charter-flights';
import RentAJetNearMe from './components/articles/rent-a-jet-near-me';
import SemiPrivateFlightsJsx from './components/articles/semi-private-flights-jsx';
import PrivateJetCharterEmptyLegs from './components/articles/private-jet-charter-empty-legs';
import PrivateFlightServices from './components/articles/private-flight-services';
import PrivateFlightChartersNearMe from './components/articles/private-flight-charters-near-me';
import CharterAirFlights from './components/articles/charter-air-flights';
import AtlanticPrivateJet from './components/articles/atlantic-private-jet';
import FlightChartersNearMe from './components/articles/flight-charters-near-me';
import Top5PrivateJetCompanies from './components/articles/top-5-private-jet-companies';
import XoCharterFlights from './components/articles/xo-charter-flights';
import InternationalPrivateJetCharter from './components/articles/international-private-jet-charter';
import PrivateJetFares from './components/articles/private-jet-fares';
import PropPlaneCharter from './components/articles/prop-plane-charter';
import PrivateJetInternationalFlights from './components/articles/private-jet-international-flights';
import CharterServiceCompany from './components/articles/charter-service-company';
import UpCharterFlights from './components/articles/up-charter-flights';
import BestPrivateJetRental from './components/articles/best-private-jet-rental';
import PrivateFlightFinder from './components/articles/private-flight-finder';
import CheapPrivateCharterFlights from './components/articles/cheap-private-charter-flights';
import CanadianPrivateJetCompanies from './components/articles/canadian-private-jet-companies';
import Top10PrivateJetCompanies from './components/articles/top-10-private-jet-companies';
import AffordablePrivateJetRental from './components/articles/affordable-private-jet-rental';
import EmptyPrivateJetFlights from './components/articles/empty-private-jet-flights';
import CheapestPrivateJetService from './components/articles/cheapest-private-jet-service';
import InternationalPrivateJetCharterCost from './components/articles/international-private-jet-charter-cost';
import AffordablePrivateJetCharter from './components/articles/affordable-private-jet-charter';
import AffordablePrivateJetRentals from './components/articles/affordable-private-jet-rentals';
import RentPrivateJetNearMe from './components/articles/rent-private-jet-near-me';
import LightJetCharter from './components/articles/light-jet-charter';
import LargePrivateJetCharter from './components/articles/large-private-jet-charter';
import SemiPrivateJetService from './components/articles/semi-private-jet-service';
import PrivateJetPricesToFly from './components/articles/private-jet-prices-to-fly';
import ToRentAPrivateJet from './components/articles/to-rent-a-private-jet';
import CargoPlaneRental from './components/articles/cargo-plane-rental';
import FindCharterFlights from './components/articles/find-charter-flights';
import EmptyLegTripPrivateJet from './components/articles/empty-leg-trip-private-jet';
import InstantCharterFlightQuote from './components/articles/instant-charter-flight-quote';






// implement some lady loading
//const AddAircraft = React.lazy(() => import('./components/dashboard/AddAircraft'));
//const TermsOfService = React.lazy(() => import('./components/footer/termsofservice'));
//const PrivacyPolicy = React.lazy(() => import('./components/footer/privacypolicy'));
//const PostListing = React.lazy(() => import('./components/dashboard/PostListing'));
//const About = React.lazy(() => import('./components/about/About'));

function usePageTracking() {
  const location = useLocation(); // Gets the current browser location object
  const analytics = useAnalytics(); // Gets the GA4 analytics instance

  useEffect(() => {
      // Initialize GA4 with your measurement ID if it's not already initialized elsewhere
      analytics.initialize("G-87ZH4W7MER");

      // Send a pageview to GA4 whenever the location changes
      analytics.send("pageview");
  }, [location]); // This effect runs whenever the location changes
}

function App() {
  const location = useLocation();
  const hideNavBarAndFooter = location.pathname === '/landing-page';
 // console.log("App component rendered");
  return (
      <AuthProvider>
        <div className="App">
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/email-verification" element={<EmailVerification />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/dashboard" element={<MyDashboard />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/email-notifications" element={<EmailNotifications />} />
            <Route path="/post-listing" element={<PostListing />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/my-listing" element={<MyListings />} />
            <Route path="/messages" element={<MyMessage />} />
            <Route path="/send-message" element={<SendMessage />} />
            <Route path="/my-posts" element={<MyPosts />} />
            <Route path="/post-listing-have" element={<PostListingHave />} />
            <Route path="/post-payment" element={<PostPaymentPage />} />
            <Route path="/ground-transport" element={<GroundTransport />} />
            <Route path="/catering" element={<GroundTransport />} />
            <Route path="/FBO" element={<FBO />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/article/:id" element={<Article />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/login_message" element={<LoginMessage />} />
            <Route path="/about" element={<About />} />
            <Route path="/termsofservice" element={<TermsOfService />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/sitemap" element={<SiteMap />} />
            <Route path="/unsubscribe" element={<EmailSubscription />} /> // Add this line for email subscription route
            <Route path="/faq" element={<FAQ />} />
            <Route path="/landing-page" element={<LandingPage />} />

            
            {/* Add routes for MyFleet and AddAircraft components */}
            <Route path="/my-fleet" element={<MyFleet />} />
            <Route path="/add-aircraft" element={<AddAircraft />} />

            {/* Add routes for aircraft artciles created with open ai and python*/}
            <Route path="/private-planes-for-charter" element={<PrivatePlanesForCharter />} />
            <Route path="/private-air-charter" element={<PrivateAirCharter />} />
            <Route path="/private-aircraft-charter" element={<PrivateAircraftCharter />} />
            <Route path="/private-aircraft-rental" element={<PrivateAircraftRental />} />
            <Route path="/private-airplanes" element={<PrivateAirplanes />} />
            <Route path="/private-flight-charter" element={<PrivateFlightCharter />} />
            <Route path="/private-jet-charter-flights" element={<PrivateJetCharterFlights />} />
            <Route path="/private-jet-cost" element={<PrivateJetCost />} />
            <Route path="/private-jet-rental" element={<PrivateJetRental />} />
            <Route path="/private-jet" element={<PrivateJet />} />
            <Route path="/private-plane-jet" element={<PrivatePlaneJet />} />
            <Route path="/articles/jet-private-jet" element={<JetPrivateJet />} />
            <Route path="/articles/private-jet-air-charter" element={<PrivateJetAirCharter />} />
            <Route path="/articles/private-jet-charter" element={<PrivateJetCharter />} />
            <Route path="/articles/private-private-jet" element={<PrivatePrivateJet />} />
            <Route path="/articles/air-charter" element={<AirCharter />} />
            <Route path='/articles/private-jet-flights' element={<PrivateJetFlights />} />
            <Route path='/articles/private-jet-price' element={<PrivateJetPrice />} />
            <Route path='/articles/charter-flights' element={<CharterFlights />} />
            <Route path='/articles/cheap-private-jet-flights' element={<CheapPrivateJetFlights />} />
            <Route path='/articles/private-jet-rental-cost' element={<PrivateJetRentalCost />} />
            <Route path='/articles/private-jet-membership' element={<PrivateJetMembership />} />
            <Route path='/articles/private-jet-companies' element={<PrivateJetCompanies />} />
            <Route path='/articles/air-charter-service' element={<AirCharterService />} />
            <Route path='/articles/private-airlines' element={<PrivateAirlines />} />
            <Route path='/articles/private-jet-services' element={<PrivateJetServices />} />
            <Route path='/articles/private-plane-flights' element={<PrivatePlaneFlights />} />
            <Route path='/articles/empty-leg-private-jet' element={<EmptyLegPrivateJet />} />
            <Route path='/articles/private-jet-cost-per-hour' element={<PrivateJetCostPerHour />} />
            <Route path='/articles/aero-private-jet' element={<AeroPrivateJet />} />
            <Route path='/articles/private-jet-charter-cost' element={<PrivateJetCharterCost />} />
            <Route path='/articles/private-jet-rental-cost-per-hour' element={<PrivateJetRentalCostPerHour />} />
            <Route path='/articles/private-jet-flight-cost' element={<PrivateJetFlightCost />} />
            <Route path='/articles/charter-airlines' element={<CharterAirlines />} />
            <Route path='/articles/fly-private-jet' element={<FlyPrivateJet />} />
            <Route path='/articles/cheapest-private-jet' element={<CheapestPrivateJet />} />
            <Route path='/articles/private-jet-empty-leg-flights' element={<PrivateJetEmptyLegFlights />} />
            <Route path='/articles/cheap-private-flights' element={<CheapPrivateFlights />} />
            <Route path='/articles/semi-private-airlines' element={<SemiPrivateAirlines />} />
            <Route path='/articles/affordable-private-jet' element={<AffordablePrivateJet />} />
            <Route path='/articles/luxury-private-jets' element={<LuxuryPrivateJets />} />
            <Route path='/articles/private-jet-shared-flights' element={<PrivateJetSharedFlights />} />
            <Route path='/articles/private-airplane-flights' element={<PrivateAirplaneFlights />} />
            <Route path='/articles/fly-private-jet-cheap' element={<FlyPrivateJetCheap />} />
            <Route path='/articles/aircraft-charter' element={<AircraftCharter />} />
            <Route path='/articles/private-jet-charter-prices' element={<PrivateJetCharterPrices />} />
            <Route path='/articles/chartered-flight-cost' element={<CharteredFlightCost />} />
            <Route path='/articles/private-jet-airlines' element={<PrivateJetAirlines />} />
            <Route path='/articles/private-airline-companies' element={<PrivateAirlineCompanies />} />
            <Route path='/articles/charter-airline-companies' element={<CharterAirlineCompanies />} />
            <Route path='/articles/charter-flight-companies' element={<CharterFlightCompanies />} />
            <Route path='/articles/private-charter-flights-cost' element={<PrivateCharterFlightsCost />} />
            <Route path='/articles/charter-plane-companies' element={<CharterPlaneCompanies />} />
            <Route path='/articles/private-airline-flights' element={<PrivateAirlineFlights />} />
            <Route path='/articles/private-jet-bookings' element={<PrivateJetBookings />} />
            <Route path='/articles/private-jet-flight-price' element={<PrivateJetFlightPrice />} />
            <Route path='/articles/shared-flights-private-jets' element={<SharedFlightsPrivateJets />} />
            <Route path='/articles/private-air-flights' element={<PrivateAirFlights />} />
            <Route path='/articles/flying-private-jet-cost' element={<FlyingPrivateJetCost />} />
            <Route path='/articles/rent-a-jet-plane' element={<RentAJetPlane />} />
            <Route path='/articles/low-cost-private-jet-flights' element={<LowCostPrivateJetFlights />} />
            <Route path='/articles/private-charter-airlines' element={<PrivateCharterAirlines />} />
            <Route path='/articles/jet-private-flights' element={<JetPrivateFlights />} />
            <Route path='/articles/private-jet-price-per-hour' element={<PrivateJetPricePerHour />} />
            <Route path='/articles/cost-to-fly-private-jet-per-hour' element={<CostToFlyPrivateJetPerHour />} />
            <Route path='/articles/fly-private-jet-price' element={<FlyPrivateJetPrice />} />
            <Route path='/articles/charter-aircraft-cost' element={<CharterAircraftCost />} />
            <Route path='/articles/cheap-private-plane-flights' element={<CheapPrivatePlaneFlights />} />
            <Route path='/articles/private-jet-airfare' element={<PrivateJetAirfare />} />
            <Route path='/articles/low-cost-private-jet' element={<LowCostPrivateJet />} />
            <Route path='/articles/private-air-charter-prices' element={<PrivateAirCharterPrices />} />
            <Route path='/articles/private-flights-prices' element={<PrivateFlightsPrices />} />
            <Route path='/articles/private-jet-affordable' element={<PrivateJetAffordable />} />
            <Route path='/articles/flying-on-a-private-jet-cost' element={<FlyingOnAPrivateJetCost />} />
            <Route path='/articles/cost-of-private-plane-rental' element={<CostOfPrivatePlaneRental />} />
            <Route path='/articles/private-jet-cost-to-fly' element={<PrivateJetCostToFly />} />
            <Route path='/articles/fly-private-jet-empty-leg' element={<FlyPrivateJetEmptyLeg />} />
            <Route path='/articles/fly-private-charter' element={<FlyPrivateCharter />} />
            <Route path='/articles/private-aircraft-flights' element={<PrivateAircraftFlights />} />
            <Route path='/articles/air-plane-rental' element={<AirPlaneRental />} />
            <Route path='/articles/bombardier-8000-price' element={<Bombardier8000Price />} />
            <Route path='/articles/private-jet-aircraft' element={<PrivateJetAircraft />} />
            <Route path='/articles/corporate-private-jet' element={<CorporatePrivateJet />} />
            <Route path='/articles/private-charter-flights-prices' element={<PrivateCharterFlightsPrices />} />
            <Route path='/articles/charter-airlines-cost' element={<CharterAirlinesCost />} />
            <Route path='/articles/airport-charter' element={<AirportCharter />} />
            <Route path='/articles/private-jet-plane-cost' element={<PrivateJetPlaneCost />} />
            <Route path='/articles/flying-private-cheap' element={<FlyingPrivateCheap />} />
            <Route path='/articles/private-jet-airplane' element={<PrivateJetAirplane />} />
            <Route path='/articles/air-charter-cost' element={<AirCharterCost />} />
            <Route path='/articles/price-private-jet-charter' element={<PricePrivateJetCharter />} />
            <Route path='/articles/company-private-jet' element={<CompanyPrivateJet />} />
            <Route path='/articles/plane-private-jet' element={<PlanePrivateJet />} />
            <Route path='/articles/private-air-charter-flights' element={<PrivateAirCharterFlights />} />
            <Route path='/articles/air-charter-jet' element={<AirCharterJet />} />
            <Route path='/articles/cost-private-jet-rental' element={<CostPrivateJetRental />} />
            <Route path='/articles/delta-private-jets-inc' element={<DeltaPrivateJetsInc />} />
            <Route path='/articles/private-aircraft-charter-prices' element={<PrivateAircraftCharterPrices />} />
            <Route path='/articles/private-jet-best' element={<PrivateJetBest />} />
            <Route path='/articles/swift-air-airlines' element={<SwiftAirAirlines />} />
            <Route path='/articles/private-jet-hire' element={<PrivateJetHire />} />
            <Route path='/articles/private-jet-quote' element={<PrivateJetQuote />} />
            <Route path='/articles/charter-flights-near-me' element={<CharterFlightsNearMe />} />
            <Route path='/articles/wheels-up-private-jet' element={<WheelsUpPrivateJet />} />
            <Route path='/articles/private-jet-hire-cost' element={<PrivateJetHireCost />} />
            <Route path='/articles/private-jet-rental-near-me' element={<PrivateJetRentalNearMe />} />
            <Route path='/articles/private-air-travel' element={<PrivateAirTravel />} />
            <Route path='/articles/private-jet-charter-companies' element={<PrivateJetCharterCompanies />} />
            <Route path='/articles/private-charter-flights-near-me' element={<PrivateCharterFlightsNearMe />} />
            <Route path='/articles/best-private-jet-companies' element={<BestPrivateJetCompanies />} />
            <Route path='/articles/charter-plane-flights' element={<CharterPlaneFlights />} />
            <Route path='/articles/private-jet-travel' element={<PrivateJetTravel />} />
            <Route path='/articles/private-airlines-near-me' element={<PrivateAirlinesNearMe />} />
            <Route path='/articles/private-jet-share' element={<PrivateJetShare />} />
            <Route path='/articles/cheap-private-jet-rental' element={<CheapPrivateJetRental />} />
            <Route path='/articles/cheap-charter-flights' element={<CheapCharterFlights />} />
            <Route path='/articles/cheapest-private-jet-charter' element={<CheapestPrivateJetCharter />} />
            <Route path='/articles/private-jet-near-me' element={<PrivateJetNearMe />} />
            <Route path='/articles/best-private-jet-charter-companies' element={<BestPrivateJetCharterCompanies />} />
            <Route path='/articles/private-jet-services-near-me' element={<PrivateJetServicesNearMe />} />
            <Route path='/articles/semi-private-flights-near-me' element={<SemiPrivateFlightsNearMe />} />
            <Route path='/articles/rent-a-jet-price' element={<RentAJetPrice />} />
            <Route path='/articles/empty-leg-private-jet-flights' element={<EmptyLegPrivateJetFlights />} />
            <Route path='/articles/rent-private-jet-price' element={<RentPrivateJetPrice />} />
            <Route path='/articles/private-jet-broker' element={<PrivateJetBroker />} />
            <Route path='/articles/private-jet-instant-quote' element={<PrivateJetInstantQuote />} />
            <Route path='/articles/shared-charter-flights' element={<SharedCharterFlights />} />
            <Route path='/articles/affordable-charter-flights' element={<AffordableCharterFlights />} />
            <Route path='/articles/private-jet-companies-near-me' element={<PrivateJetCompaniesNearMe />} />
            <Route path='/articles/top-10-private-jet-charter-companies' element={<Top10PrivateJetCharterCompanies />} />
            <Route path='/articles/air-charter-broker' element={<AirCharterBroker />} />
            <Route path='/articles/international-charter-flights' element={<InternationalCharterFlights />} />
            <Route path='/articles/semi-private-jet-flights' element={<SemiPrivateJetFlights />} />
            <Route path='/articles/jetsuitex-flights' element={<JetsuitexFlights />} />
            <Route path='/articles/private-jet-travel-cost' element={<PrivateJetTravelCost />} />
            <Route path='/articles/private-jet-rental-companies' element={<PrivateJetRentalCompanies />} />
            <Route path='/articles/private-air' element={<PrivateAir />} />
            <Route path='/articles/private-jet-charter-quote' element={<PrivateJetCharterQuote />} />
            <Route path='/articles/cubazul-air-charter' element={<CubazulAirCharter />} />
            <Route path='/articles/shared-private-flights' element={<SharedPrivateFlights />} />
            <Route path='/articles/best-private-jet-service' element={<BestPrivateJetService />} />
            <Route path='/articles/top-private-jet-companies' element={<TopPrivateJetCompanies />} />
            <Route path='/articles/international-private-jet' element={<InternationalPrivateJet />} />
            <Route path='/articles/small-plane-charter' element={<SmallPlaneCharter />} />
            <Route path='/articles/private-international-flight' element={<PrivateInternationalFlight />} />
            <Route path='/articles/private-air-charter-companies' element={<PrivateAirCharterCompanies />} />
            <Route path='/articles/charter-jet-flights' element={<CharterJetFlights />} />
            <Route path='/articles/private-jet-charter-near-me' element={<PrivateJetCharterNearMe />} />
            <Route path='/articles/best-jet-charter-companies' element={<BestJetCharterCompanies />} />
            <Route path='/articles/king-air-charter' element={<KingAirCharter />} />
            <Route path='/articles/jet-charter-broker' element={<JetCharterBroker />} />
            <Route path='/articles/private-jet-trip-cost' element={<PrivateJetTripCost />} />
            <Route path='/articles/best-private-jet-for-international-travel' element={<BestPrivateJetForInternationalTravel />} />
            <Route path='/articles/jet-charter-service' element={<JetCharterService />} />
            <Route path='/articles/cheapest-private-jet-to-rent' element={<CheapestPrivateJetToRent />} />
            <Route path='/articles/private-jet-service-cost' element={<PrivateJetServiceCost />} />
            <Route path='/articles/charter-flight-quote' element={<CharterFlightQuote />} />
            <Route path='/articles/semi-private-jet-companies' element={<SemiPrivateJetCompanies />} />
            <Route path='/articles/private-jet-cost-per-flight' element={<PrivateJetCostPerFlight />} />
            <Route path='/articles/aircraft-charter-companies' element={<AircraftCharterCompanies />} />
            <Route path='/articles/private-jet-leasing' element={<PrivateJetLeasing />} />
            <Route path='/articles/best-charter-flight-companies' element={<BestCharterFlightCompanies />} />
            <Route path='/articles/charter-flight-services' element={<CharterFlightServices />} />
            <Route path='/articles/jet-charter-near-me' element={<JetCharterNearMe />} />
            <Route path='/articles/best-private-jet-charter' element={<BestPrivateJetCharter />} />
            <Route path='/articles/private-jet-trip' element={<PrivateJetTrip />} />
            <Route path='/articles/private-aircraft-hire' element={<PrivateAircraftHire />} />
            <Route path='/articles/swift-air-charter' element={<SwiftAirCharter />} />
            <Route path='/articles/air-charter-companies' element={<AirCharterCompanies />} />
            <Route path='/articles/private-aircraft-charter-services' element={<PrivateAircraftCharterServices />} />
            <Route path='/articles/charter-flight-prices' element={<CharterFlightPrices />} />
            <Route path='/articles/private-jet-plane' element={<PrivateJetPlane />} />
            <Route path='/articles/aviation-charter-companies' element={<AviationCharterCompanies />} />
            <Route path='/articles/aircraft-charter-near-me' element={<AircraftCharterNearMe />} />
            <Route path='/articles/charter-aircraft-near-me' element={<CharterAircraftNearMe />} />
            <Route path='/articles/air-charters-near-me' element={<AirChartersNearMe />} />
            <Route path='/articles/private-jets-miami' element={<PrivateJetsMiami />} />
            <Route path='/articles/commercial-private-jet' element={<CommercialPrivateJet />} />
            <Route path='/articles/cargo-charter' element={<CargoCharter />} />
            <Route path='/articles/average-cost-to-charter-a-private-jet' element={<AverageCostToCharterAPrivateJet />} />
            <Route path='/articles/average-private-jet-cost' element={<AveragePrivateJetCost />} />
            <Route path='/articles/fly-charter' element={<FlyCharter />} />
            <Route path='/articles/charter-plane-service' element={<CharterPlaneService />} />
            <Route path='/articles/aircraft-charter-broker' element={<AircraftCharterBroker />} />
            <Route path='/articles/private-jet-charter-services' element={<PrivateJetCharterServices />} />
            <Route path='/articles/cost-to-rent-a-jet' element={<CostToRentAJet />} />
            <Route path='/articles/charter-air-service' element={<CharterAirService />} />
            <Route path='/articles/cargo-charter-flight' element={<CargoCharterFlight />} />
            <Route path='/articles/luxury-private-jet-charter' element={<LuxuryPrivateJetCharter />} />
            <Route path='/articles/aviation-charter' element={<AviationCharter />} />
            <Route path='/articles/private-jet-business' element={<PrivateJetBusiness />} />
            <Route path='/articles/cheap-jet-charter' element={<CheapJetCharter />} />
            <Route path='/articles/premier-private-jet-charter' element={<PremierPrivateJetCharter />} />
            <Route path='/articles/private-jet-for-international-travel' element={<PrivateJetForInternationalTravel />} />
            <Route path='/articles/swift-air-charter-flights' element={<SwiftAirCharterFlights />} />
            <Route path='/articles/average-cost-to-rent-a-private-jet' element={<AverageCostToRentAPrivateJet />} />
            <Route path='/articles/executive-air-charter' element={<ExecutiveAirCharter />} />
            <Route path='/articles/private-jets-new-york' element={<PrivateJetsNewYork />} />
            <Route path='/articles/air-charter-quote' element={<AirCharterQuote />} />
            <Route path='/articles/largest-private-jet-companies' element={<LargestPrivateJetCompanies />} />
            <Route path='/articles/private-flight-hire' element={<PrivateFlightHire />} />
            <Route path='/articles/air-charter-service-inc' element={<AirCharterServiceInc />} />
            <Route path='/articles/united-charter-flights' element={<UnitedCharterFlights />} />
            <Route path='/articles/small-plane-charter-flights' element={<SmallPlaneCharterFlights />} />
            <Route path='/articles/citation-private-jet' element={<CitationPrivateJet />} />
            <Route path='/articles/private-airline-service' element={<PrivateAirlineService />} />
            <Route path='/articles/charter-jet-airlines' element={<CharterJetAirlines />} />
            <Route path='/articles/charter-airline-flights' element={<CharterAirlineFlights />} />
            <Route path='/articles/allegiant-charter-flights' element={<AllegiantCharterFlights />} />
            <Route path='/articles/leasing-private-jet-cost' element={<LeasingPrivateJetCost />} />
            <Route path='/articles/corporate-air' element={<CorporateAir />} />
            <Route path='/articles/top-rated-private-jet-companies' element={<TopRatedPrivateJetCompanies />} />
            <Route path='/articles/biggest-private-jet-companies' element={<BiggestPrivateJetCompanies />} />
            <Route path='/articles/charter-aircraft-services' element={<CharterAircraftServices />} />
            <Route path='/articles/low-cost-charter-flights' element={<LowCostCharterFlights />} />
            <Route path='/articles/average-cost-of-private-jet-flight' element={<AverageCostOfPrivateJetFlight />} />
            <Route path='/articles/private-jet-center' element={<PrivateJetCenter />} />
            <Route path='/articles/best-air-charter-companies' element={<BestAirCharterCompanies />} />
            <Route path='/articles/international-air-charters' element={<InternationalAirCharters />} />
            <Route path='/articles/private-jet-airport-near-me' element={<PrivateJetAirportNearMe />} />
            <Route path='/articles/air-x-charter' element={<AirXCharter />} />
            <Route path='/articles/group-air-charter' element={<GroupAirCharter />} />
            <Route path='/articles/fly-charter-airlines' element={<FlyCharterAirlines />} />
            <Route path='/articles/rent-a-charter-flight' element={<RentACharterFlight />} />
            <Route path='/articles/aero-charters' element={<AeroCharters />} />
            <Route path='/articles/private-jet-la' element={<PrivateJetLa />} />
            <Route path='/articles/united-airlines-private-jet' element={<UnitedAirlinesPrivateJet />} />
            <Route path='/articles/small-aircraft-charter-flights' element={<SmallAircraftCharterFlights />} />
            <Route path='/articles/luxury-private-jet-hire' element={<LuxuryPrivateJetHire />} />
            <Route path='/articles/cheap-charter-planes' element={<CheapCharterPlanes />} />
            <Route path='/articles/average-price-for-a-private-jet' element={<AveragePriceForAPrivateJet />} />
            <Route path='/articles/charter-flight-broker' element={<CharterFlightBroker />} />
            <Route path='/articles/west-air-charter' element={<WestAirCharter />} />
            <Route path='/articles/average-cost-of-chartering-a-private-jet' element={<AverageCostOfCharteringAPrivateJet />} />
            <Route path='/articles/fly-charter-cheap' element={<FlyCharterCheap />} />
            <Route path='/articles/group-plane-charter' element={<GroupPlaneCharter />} />
            <Route path='/articles/private-charter-flight-companies' element={<PrivateCharterFlightCompanies />} />
            <Route path='/articles/private-business-jets' element={<PrivateBusinessJets />} />
            <Route path='/articles/best-charter-airlines' element={<BestCharterAirlines />} />
            <Route path='/articles/private-jet-one-way' element={<PrivateJetOneWay />} />
            <Route path='/articles/best-charter-flights' element={<BestCharterFlights />} />
            <Route path='/articles/aircharter-guide' element={<AircharterGuide />} />
            <Route path='/articles/cost-to-rent-a-jet-plane' element={<CostToRentAJetPlane />} />
            <Route path='/articles/air-charter-worldwide' element={<AirCharterWorldwide />} />
            <Route path='/articles/group-aircraft-charter' element={<GroupAircraftCharter />} />
            <Route path='/articles/private-aircraft-services' element={<PrivateAircraftServices />} />
            <Route path='/articles/silver-air-private-jet' element={<SilverAirPrivateJet />} />
            <Route path='/articles/empty-leg-private-jet-flight' element={<EmptyLegPrivateJetFlight />} />
            <Route path='/articles/private-plane-charter-services' element={<PrivatePlaneCharterServices />} />
            <Route path='/articles/ultimate-jet-charter' element={<UltimateJetCharter />} />
            <Route path='/articles/aircraft-rental-service' element={<AircraftRentalService />} />
            <Route path='/articles/rent-a-charter-jet' element={<RentACharterJet />} />
            <Route path='/articles/aircraft-rental-cost' element={<AircraftRentalCost />} />
            <Route path='/articles/broker-private-jet' element={<BrokerPrivateJet />} />
            <Route path='/articles/private-jet-inc' element={<PrivateJetInc />} />
            <Route path='/articles/airport-private-jet' element={<AirportPrivateJet />} />
            <Route path='/articles/king-air-charter-cost' element={<KingAirCharterCost />} />
            <Route path='/articles/corporate-private-jet-charter' element={<CorporatePrivateJetCharter />} />
            <Route path='/articles/top-private-jet' element={<TopPrivateJet />} />
            <Route path='/articles/bel-air-charter' element={<BelAirCharter />} />
            <Route path='/articles/international-air-charter' element={<InternationalAirCharter />} />
            <Route path='/articles/cheap-air-charter' element={<CheapAirCharter />} />
            <Route path='/articles/globe-air-empty-leg' element={<GlobeAirEmptyLeg />} />
            <Route path='/articles/united-airlines-charters' element={<UnitedAirlinesCharters />} />
            <Route path='/articles/air-charter-prices' element={<AirCharterPrices />} />
            <Route path='/articles/aircraft-charter-guide' element={<AircraftCharterGuide />} />
            <Route path='/articles/airplane-charter-services' element={<AirplaneCharterServices />} />
            <Route path='/articles/alaska-air-charter' element={<AlaskaAirCharter />} />
            <Route path='/articles/allegiant-air-charter' element={<AllegiantAirCharter />} />
            <Route path='/articles/allegiant-air-charter-flights' element={<AllegiantAirCharterFlights />} />
            <Route path='/articles/american-airlines-charter' element={<AmericanAirlinesCharter />} />
            <Route path='/articles/american-airlines-charter-flights' element={<AmericanAirlinesCharterFlights />} />
            <Route path='/articles/atlas-air-charter' element={<AtlasAirCharter />} />
            <Route path='/articles/average-cost-of-flying-private-jet' element={<AverageCostOfFlyingPrivateJet />} />
            <Route path='/articles/business-air-charter' element={<BusinessAirCharter />} />
            <Route path='/articles/business-aircraft-charter' element={<BusinessAircraftCharter />} />
            <Route path='/articles/cargo-charter-airlines' element={<CargoCharterAirlines />} />
            <Route path='/articles/charter-airways' element={<CharterAirways />} />
            <Route path='/articles/charter-flight-airline' element={<CharterFlightAirline />} />
            <Route path='/articles/corporate-air-center' element={<CorporateAirCenter />} />
            <Route path='/articles/empty-leg-air-charter' element={<EmptyLegAirCharter />} />
            <Route path='/articles/europe-air-charter' element={<EuropeAirCharter />} />
            <Route path='/articles/european-aviation-air-charter' element={<EuropeanAviationAirCharter />} />
            <Route path='/articles/european-charter-airline' element={<EuropeanCharterAirline />} />
            <Route path='/articles/executive-aircraft-charter' element={<ExecutiveAircraftCharter />} />
            <Route path='/articles/island-air-charter' element={<IslandAirCharter />} />
            <Route path='/articles/phoenix-air-charter' element={<PhoenixAirCharter />} />
            <Route path='/articles/premier-air-charters' element={<PremierAirCharters />} />
            <Route path='/articles/private-jet-service-prices' element={<PrivateJetServicePrices />} />
            <Route path='/articles/safest-private-jets' element={<SafestPrivateJets />} />
            <Route path='/articles/southernair-charter' element={<SouthernairCharter />} />
            <Route path='/articles/summit-air-charters' element={<SummitAirCharters />} />
            <Route path='/articles/superior-air-charter-llc' element={<SuperiorAirCharterLlc />} />
            <Route path='/articles/windrose-airline' element={<WindroseAirline />} />
            <Route path='/articles/private-jet-membership-companies' element={<PrivateJetMembershipCompanies />} />
            <Route path='/articles/private-jet-hire-price' element={<PrivateJetHirePrice />} />
            <Route path='/articles/cheap-private-jet-flights-near-me' element={<CheapPrivateJetFlightsNearMe />} />
            <Route path='/articles/charter-a-plane-near-me' element={<CharterAPlaneNearMe />} />
            <Route path='/articles/private-jet-flights-near-me' element={<PrivateJetFlightsNearMe />} />
            <Route path='/articles/xo-private-jet-cost' element={<XoPrivateJetCost />} />
            <Route path='/articles/private-jet-rentals-near-me' element={<PrivateJetRentalsNearMe />} />
            <Route path='/articles/private-jet-charter-instant-quote' element={<PrivateJetCharterInstantQuote />} />
            <Route path='/articles/private-jet-hire-cheap' element={<PrivateJetHireCheap />} />
            <Route path='/articles/affordable-private-jet-flights' element={<AffordablePrivateJetFlights />} />
            <Route path='/articles/international-private-jet-cost' element={<InternationalPrivateJetCost />} />
            <Route path='/articles/air-ambulance-charter' element={<AirAmbulanceCharter />} />
            <Route path='/articles/jsx-charter-flights' element={<JsxCharterFlights />} />
            <Route path='/articles/luxury-private-jet-rental' element={<LuxuryPrivateJetRental />} />
            <Route path='/articles/cheap-charter-flights-near-me' element={<CheapCharterFlightsNearMe />} />
            <Route path='/articles/private-air-travel-companies' element={<PrivateAirTravelCompanies />} />
            <Route path='/articles/private-jet-reservations' element={<PrivateJetReservations />} />
            <Route path='/articles/private-jet-charters-near-me' element={<PrivateJetChartersNearMe />} />
            <Route path='/articles/private-flight-companies' element={<PrivateFlightCompanies />} />
            <Route path='/articles/charter-airlines-near-me' element={<CharterAirlinesNearMe />} />
            <Route path='/articles/private-jet-options' element={<PrivateJetOptions />} />
            <Route path='/articles/netjets-private-jet' element={<NetjetsPrivateJet />} />
            <Route path='/articles/private-jet-operators' element={<PrivateJetOperators />} />
            <Route path='/articles/delta-private-jets-cost' element={<DeltaPrivateJetsCost />} />
            <Route path='/articles/rent-a-private-jet-near-me' element={<RentAPrivateJetNearMe />} />
            <Route path='/articles/private-plane-flights-near-me' element={<PrivatePlaneFlightsNearMe />} />
            <Route path='/articles/private-flight-quote' element={<PrivateFlightQuote />} />
            <Route path='/articles/last-minute-charter-flights' element={<LastMinuteCharterFlights />} />
            <Route path='/articles/up-private-jet' element={<UpPrivateJet />} />
            <Route path='/articles/private-jet-leasing-companies' element={<PrivateJetLeasingCompanies />} />
            <Route path='/articles/private-jet-share-companies' element={<PrivateJetShareCompanies />} />
            <Route path='/articles/monarch-air-charter' element={<MonarchAirCharter />} />
            <Route path='/articles/private-air-travel-cost' element={<PrivateAirTravelCost />} />
            <Route path='/articles/private-jet-share-cost' element={<PrivateJetShareCost />} />
            <Route path='/articles/private-jet-deals' element={<PrivateJetDeals />} />
            <Route path='/articles/blade-charter-flights' element={<BladeCharterFlights />} />
            <Route path='/articles/vision-jet-charter' element={<VisionJetCharter />} />
            <Route path='/articles/last-minute-private-jet-empty-legs' element={<LastMinutePrivateJetEmptyLegs />} />
            <Route path='/articles/private-jet-charter-international-travel' element={<PrivateJetCharterInternationalTravel />} />
            <Route path='/articles/private-jet-group-travel' element={<PrivateJetGroupTravel />} />
            <Route path='/articles/air-cargo-charter' element={<AirCargoCharter />} />
            <Route path='/articles/one-way-private-jet-flights' element={<OneWayPrivateJetFlights />} />
            <Route path='/articles/private-jet-transportation' element={<PrivateJetTransportation />} />
            <Route path='/articles/on-demand-private-jet' element={<OnDemandPrivateJet />} />
            <Route path='/articles/aero-private-flights' element={<AeroPrivateFlights />} />
            <Route path='/articles/charter-cargo-plane' element={<CharterCargoPlane />} />
            <Route path='/articles/private-flight-rental' element={<PrivateFlightRental />} />
            <Route path='/articles/private-plane-flight-cost' element={<PrivatePlaneFlightCost />} />
            <Route path='/articles/best-private-flight-companies' element={<BestPrivateFlightCompanies />} />
            <Route path='/articles/private-jet-round-trip-cost' element={<PrivateJetRoundTripCost />} />
            <Route path='/articles/auric-air-charters' element={<AuricAirCharters />} />
            <Route path='/articles/cheap-semi-private-flights' element={<CheapSemiPrivateFlights />} />
            <Route path='/articles/private-jet-service-companies' element={<PrivateJetServiceCompanies />} />
            <Route path='/articles/on-demand-charter-flights' element={<OnDemandCharterFlights />} />
            <Route path='/articles/charter-private-jet-near-me' element={<CharterPrivateJetNearMe />} />
            <Route path='/articles/pc12-charter' element={<Pc12Charter />} />
            <Route path='/articles/private-air-charters-near-me' element={<PrivateAirChartersNearMe />} />
            <Route path='/articles/private-jet-charter-broker' element={<PrivateJetCharterBroker />} />
            <Route path='/articles/tui-charter-flights' element={<TuiCharterFlights />} />
            <Route path='/articles/silver-air-charter' element={<SilverAirCharter />} />
            <Route path='/articles/best-private-charter-jet-companies' element={<BestPrivateCharterJetCompanies />} />
            <Route path='/articles/rvr-air-charter' element={<RvrAirCharter />} />
            <Route path='/articles/most-affordable-private-jet-service' element={<MostAffordablePrivateJetService />} />
            <Route path='/articles/air-charter-service-near-me' element={<AirCharterServiceNearMe />} />
            <Route path='/articles/best-private-airlines' element={<BestPrivateAirlines />} />
            <Route path='/articles/private-commercial-flights' element={<PrivateCommercialFlights />} />
            <Route path='/articles/private-jet-leg-flights' element={<PrivateJetLegFlights />} />
            <Route path='/articles/jet-share-flights' element={<JetShareFlights />} />
            <Route path='/articles/aero-charter-flights' element={<AeroCharterFlights />} />
            <Route path='/articles/rent-a-jet-near-me' element={<RentAJetNearMe />} />
            <Route path='/articles/semi-private-flights-jsx' element={<SemiPrivateFlightsJsx />} />
            <Route path='/articles/private-jet-charter-empty-legs' element={<PrivateJetCharterEmptyLegs />} />
            <Route path='/articles/private-flight-services' element={<PrivateFlightServices />} />
            <Route path='/articles/private-flight-charters-near-me' element={<PrivateFlightChartersNearMe />} />
            <Route path='/articles/charter-air-flights' element={<CharterAirFlights />} />
            <Route path='/articles/atlantic-private-jet' element={<AtlanticPrivateJet />} />
            <Route path='/articles/flight-charters-near-me' element={<FlightChartersNearMe />} />
            <Route path='/articles/top-5-private-jet-companies' element={<Top5PrivateJetCompanies />} />
            <Route path='/articles/xo-charter-flights' element={<XoCharterFlights />} />
            <Route path='/articles/international-private-jet-charter' element={<InternationalPrivateJetCharter />} />
            <Route path='/articles/private-jet-fares' element={<PrivateJetFares />} />
            <Route path='/articles/prop-plane-charter' element={<PropPlaneCharter />} />
            <Route path='/articles/private-jet-international-flights' element={<PrivateJetInternationalFlights />} />
            <Route path='/articles/charter-service-company' element={<CharterServiceCompany />} />
            <Route path='/articles/up-charter-flights' element={<UpCharterFlights />} />
            <Route path='/articles/best-private-jet-rental' element={<BestPrivateJetRental />} />
            <Route path='/articles/private-flight-finder' element={<PrivateFlightFinder />} />
            <Route path='/articles/cheap-private-charter-flights' element={<CheapPrivateCharterFlights />} />
            <Route path='/articles/canadian-private-jet-companies' element={<CanadianPrivateJetCompanies />} />
            <Route path='/articles/top-10-private-jet-companies' element={<Top10PrivateJetCompanies />} />
            <Route path='/articles/affordable-private-jet-rental' element={<AffordablePrivateJetRental />} />
            <Route path='/articles/empty-private-jet-flights' element={<EmptyPrivateJetFlights />} />
            <Route path='/articles/cheapest-private-jet-service' element={<CheapestPrivateJetService />} />
            <Route path='/articles/international-private-jet-charter-cost' element={<InternationalPrivateJetCharterCost />} />
            <Route path='/articles/affordable-private-jet-charter' element={<AffordablePrivateJetCharter />} />
            <Route path='/articles/affordable-private-jet-rentals' element={<AffordablePrivateJetRentals />} />
            <Route path='/articles/rent-private-jet-near-me' element={<RentPrivateJetNearMe />} />
            <Route path='/articles/light-jet-charter' element={<LightJetCharter />} />
            <Route path='/articles/large-private-jet-charter' element={<LargePrivateJetCharter />} />
            <Route path='/articles/semi-private-jet-service' element={<SemiPrivateJetService />} />
            <Route path='/articles/private-jet-prices-to-fly' element={<PrivateJetPricesToFly />} />
            <Route path='/articles/to-rent-a-private-jet' element={<ToRentAPrivateJet />} />
            <Route path='/articles/cargo-plane-rental' element={<CargoPlaneRental />} />
            <Route path='/articles/find-charter-flights' element={<FindCharterFlights />} />
            <Route path='/articles/empty-leg-trip-private-jet' element={<EmptyLegTripPrivateJet />} />
            <Route path='/articles/instant-charter-flight-quote' element={<InstantCharterFlightQuote />} />

          </Routes>

          {!hideNavBarAndFooter && <Footer />}
          <CookieConsent
  location="bottom"
  buttonText="Accept"
  declineButtonText="Decline"
  cookieName="yourSiteCookieConsent"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#00000", fontSize: "13px", borderRadius: '5px' }}
  declineButtonStyle={{ color: "#fff", fontSize: "13px", borderRadius: '5px' }}
  contentStyle={{ fontSize: "12px", margin: "15px", textAlign: "left" }} // Combine the styles here
  expires={150}
  onAccept={() => {
    //console.log('Consent given.');
  }}
  onDecline={() => {
    //console.log('Consent declined.');
  }}
>
  We respect your privacy and are committed to protecting your personal data. This website may use cookies and similar technologies to enhance user experience, analyze performance and traffic, and to personalize content and ads. Some cookies may be necessary for the site’s operation while others may be used for analytics, personalized advertising, to integrate with social media, or send promotiomal material. You have a choice regarding certain cookie types. By clicking “Accept”, you consent to the use of all cookies. , please see our <a href="/privacypolicy" style={{ textDecoration: 'underline' }}>Privacy Policy</a> and <a href="/termsofservice" style={{ textDecoration: 'underline' }}>Terms of Service</a>.
</CookieConsent>

        </div>
      </AuthProvider>
  );
}

export default App;
