import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer>
      <ul className="footer-links">
        <li><Link to="/termsofservice">Terms of Service</Link></li>
        <li><Link to="/privacypolicy">Privacy Policy</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <li><a href="/sitemap.xml" target="_blank" rel="noopener noreferrer">Sitemap</a></li>
        <li><a href="https://www.chartergod.com/private-planes-for-charter" target="_blank" rel="noopener noreferrer">Private Planes for Charter</a></li>
        <li><a href="https://www.chartergod.com/private-air-charter" target="_blank" rel="noopener noreferrer">Private Air Charter</a></li>
        <li><a href="https://www.chartergod.com/private-aircraft-charter" target="_blank" rel="noopener noreferrer">Private Aircraft Charter</a></li>
        <li><a href="https://www.chartergod.com/private-aircraft-rental" target="_blank" rel="noopener noreferrer">Private Aircraft Rental</a></li>
        <li><a href="https://www.chartergod.com/private-airplanes" target="_blank" rel="noopener noreferrer">Private Airplanes</a></li>
        <li><a href="https://www.chartergod.com/private-flight-charter" target="_blank" rel="noopener noreferrer">Private Flight Charter</a></li>
        <li><a href="https://www.chartergod.com/private-jet-charter-flights" target="_blank" rel="noopener noreferrer">Private Jet Charter Flights</a></li>
        <li><a href="https://www.chartergod.com/private-jet-cost" target="_blank" rel="noopener noreferrer">Private Jet Cost</a></li>
        <li><a href="https://www.chartergod.com/private-jet-rental" target="_blank" rel="noopener noreferrer">Private Jet Rental</a></li>
        <li><a href="https://www.chartergod.com/private-jet" target="_blank" rel="noopener noreferrer">Private Jet</a></li>
        <li><a href="https://www.chartergod.com/private-plane-jet" target="_blank" rel="noopener noreferrer">Private Plane Jet</a></li>
        <li><a href="https://www.chartergod.com/jet-private-jet" target="_blank" rel="noopener noreferrer">Jet Private Jet</a></li>
        <li><a href="https://www.chartergod.com/private-jet-air-charter" target="_blank" rel="noopener noreferrer">Private Jet Air Charter</a></li>
      </ul>
    </footer>
  );
};

export default Footer;
