import React, { useState, useEffect } from 'react';
import './EmailSubscription.css'; // Ensure the CSS path is correct.
import { API_URL } from '../../config';
import { useLocation } from 'react-router-dom';

const EmailSubscription = () => {
    const [email, setEmail] = useState('');
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [reason, setReason] = useState('');
    const [captchaResult, setCaptchaResult] = useState('');
    const [captchaNumbers, setCaptchaNumbers] = useState({
        num1: Math.floor(Math.random() * 10),
        num2: Math.floor(Math.random() * 10),
    });

    // Function to parse query params
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    useEffect(() => {
        // Parse the email from the URL query parameters
        const queryEmail = query.get('email');
        if (queryEmail) {
            setEmail(queryEmail);
        }
    }, [query]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (captchaResult !== `${captchaNumbers.num1 + captchaNumbers.num2}`) {
            alert('Incorrect captcha. Please try again.');
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/unsubscribe/updateSubscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    subscriptionStatus: Number(subscriptionStatus),
                    reason,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            alert('Your subscription status has been updated. Thank you!');
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div className="contact-container">
            <h1>Email Subscription Status Update</h1>
            <form onSubmit={handleSubmit} className="contact-form">
                <div className="form-group">
                    <label htmlFor="email">Enter Email Address (Required)</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        readOnly={true} // Email field is read-only
                    />
                </div>
          <div className="form-group">
            <label htmlFor="subscriptionStatus">Subscription Status (Required)</label>
            <select
              id="subscriptionStatus"
              name="subscriptionStatus"
              value={subscriptionStatus || ''}
              onChange={(e) => setSubscriptionStatus(e.target.value)}
              required
            >
              <option value="">Please select</option>
              <option value="1">Subscribe to mailing list</option>
              <option value="0">Unsubscribe from mailing list</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="reason">Reason (Optional)</label>
            <textarea
              id="reason"
              name="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <div className="form-group captcha">
            <label htmlFor="captcha">Captcha: {captchaNumbers.num1} + {captchaNumbers.num2} = ?</label>
            <input
              type="text"
              id="captcha"
              name="captcha"
              value={captchaResult}
              onChange={(e) => setCaptchaResult(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="contact-submit">Submit</button>
        </form>
      </div>
    );
  };

  export default EmailSubscription;