import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './EmailVerification.css';
import { API_URL } from '../../config';

const EmailVerification = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const confirmationCode = location.state?.confirmationCode?.confirmationCode || '';
    const [verificationCode, setVerificationCode] = useState(new Array(6).fill(''));
    const [verificationError, setVerificationError] = useState('');
    const [submitEnabled, setSubmitEnabled] = useState(false); // State to track submit button status
    const inputRefs = useRef([]);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, verificationCode.length);
        // Check if all verification codes are filled to enable/disable submit button
        setSubmitEnabled(verificationCode.every(code => code !== ''));
    }, [verificationCode]);

    const handleChange = (index, value) => {
        const newVerificationCode = [...verificationCode];
        newVerificationCode[index] = value;
        setVerificationCode(newVerificationCode);
        if (value !== '' && index < verificationCode.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const codeEntered = verificationCode.join(''); // Code entered by the user
        //console.log("Code entered:", codeEntered); // Log code entered by the user

        if (codeEntered === confirmationCode) {
            try {
                const response = await fetch(`${API_URL}/api/auth/confirm-email`, {
                    method: 'POST',
                    body: JSON.stringify({ confirmationCode: codeEntered }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

               // console.log("Posted to API:", { confirmationCode: codeEntered }); // Log the payload posted to the API
                const data = await response.json();

                if (data.success) {
                    localStorage.setItem('registrationSuccess', 'true');
                    navigate('/login');
                } else {
                    setVerificationError(data.message);
                }
            } catch (error) {
                setVerificationError('An error occurred during verification. Please try again.');
            }
        } else {
            setVerificationError('Verification code does not match. Please try again.');
        }
    };

    return (
        <div className="email-verification">
            <h2>Please check your email and enter the code from your email</h2>
            <form onSubmit={handleSubmit}>
                <table>
                    <tbody>
                        <tr>
                            {verificationCode.map((code, index) => (
                                <td key={index}>
                                    <input
                                        type="text"
                                        name={`digit${index + 1}`}
                                        maxLength="1"
                                        value={code}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        onFocus={(e) => e.target.select()}
                                        ref={(input) => (inputRefs.current[index] = input)}
                                        pattern="\d*"
                                        required
                                        className="verification-code"
                                    />
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
                <div className="form-group">
                    <input type="submit" value="Submit" className={`submit-button ${submitEnabled ? 'active' : ''}`} disabled={!submitEnabled} />
                </div>
            </form>
            {verificationError && (
                <div className="error">
                    <p>{verificationError}</p>
                </div>
            )}
        </div>
    );
};

export default EmailVerification;
