import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import DashboardSidebar from './DashboardSidebar';
import { useAuth } from '../contexts/AuthContext';
import './MyProfile.css';
import { API_URL } from '../../config';
import 'bootstrap/dist/css/bootstrap.min.css';

const MyProfile = () => {
  const { isLoggedIn } = useAuth();
  const [profile, setProfile] = useState({
    username: '',
    email: '',
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
  
    const token = localStorage.getItem('token');
    //console.log('Authentication Token:', token);
    
    if (token) {
      fetchProfileData(token);
    } else {
      //console.error('Token not found in localStorage');
    }
  }, [isLoggedIn]);
  

  const fetchProfileData = (token) => {
    fetch(`${API_URL}/api/user/profile`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Request failed with status: ' + response.status);
      }
      return response.json();
    })
    .then((data) => {
      //console.log('Server Response:', data);
      setProfile({
        username: data.username || '',
        email: data.email || '',
      });
    })
    .catch((error) => {
      //console.error('Error fetching profile:', error);
    });
  };

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="container-fluid dashboard-container">
      <DashboardSidebar />
      <section className="dashboard-content">
        <div className="card profile-container">
          <h1 className="card-header">My Profile</h1>
          <div className="card-body">
            <form className="profile-form">
              <div className="mb-3">
                <label htmlFor="username" className="form-label">Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="username"
                  value={profile.username}
                  onChange={(e) => setProfile({ ...profile, username: e.target.value })}
                  required
                  disabled
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={profile.email}
                  onChange={(e) => setProfile({ ...profile, email: e.target.value })}
                  required
                  disabled
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyProfile;
