import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import DashboardSidebar from './DashboardSidebar';
import { useAuth } from '../contexts/AuthContext';
import './MyPosts.css';
import { API_URL } from '../../config';

const MyPosts = () => {
  const { isLoggedIn, token } = useAuth();
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10); // Number of posts to display per page
  const navigate = useNavigate();
  const [showScrollMessage, setShowScrollMessage] = useState(false); // State to manage showing the scroll message

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    const fetchPosts = async () => {
      try {
        const response = await fetch(`${API_URL}/api/charters/myposts`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setPosts(data.map((post) => ({
          ...post,
          charter_date_start: new Date(post.charter_date_start).toLocaleDateString('en-US'),
          charter_date_end: new Date(post.charter_date_end).toLocaleDateString('en-US'),
          created_at: new Date(post.created_at).toLocaleDateString('en-US'),
        })));
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, [isLoggedIn, navigate, token]);

  const handleDelete = async (postId) => {
    if (window.confirm("Are you sure you want to delete this post?")) {
      try {
        const response = await fetch(`${API_URL}/api/charters/${postId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // If the delete was successful, remove the post from the local state
        setPosts(posts.filter((post) => post.id !== postId));
      } catch (error) {
        console.error('Error deleting post:', error);
      }
    }
  };

  // Function to detect whether the table content overflows horizontally
  const detectOverflow = () => {
    const table = document.querySelector('.posts-table');
    setShowScrollMessage(table.scrollWidth > table.clientWidth);
  };

  useEffect(() => {
    detectOverflow();
    window.addEventListener('resize', detectOverflow); // Listen for window resize events

    return () => {
      window.removeEventListener('resize', detectOverflow); // Clean up event listener
    };
  }, []);

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // Pagination Logic
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="dashboard-container">
      <DashboardSidebar />
      <section className="dashboard-content">
        <div className="posts-container">
        <h1>My Posts <span className="small-text">(Scroll Left to view entire table)</span></h1>
          {showScrollMessage && <p style={{ textAlign: 'center', color: 'red' }}>Scroll horizontally to view the entire table</p>}
          <div className="table-responsive">
            <table className="table table-striped posts-table">
              <thead>
                <tr>
                  <th>Start</th>
                  <th>End</th>
                  <th>Flexibility</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Pax</th>
                  <th>Size</th>
                  <th>Type</th>
                  <th>Post Date</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((post) => (
                  <tr key={post.id}>
                    <td>{post.charter_date_start}</td>
                    <td>{post.charter_date_end}</td>
                    <td>{post.flexibility}</td>
                    <td>{post.from}</td>
                    <td>{post.to}</td>
                    <td>{post.passengers}</td>
                    <td>{post.aircraft_size}</td>
                    <td>{post.have_need}</td>
                    <td>{post.created_at}</td>
                    <td>
                      <button onClick={() => handleDelete(post.id)} className="btn btn-danger">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="pagination">
            {Array.from({ length: Math.ceil(posts.length / postsPerPage) }, (_, i) => (
              <button
                key={i}
                onClick={() => paginate(i + 1)}
                className={currentPage === i + 1 ? 'active' : 'btn btn-primary'}>
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyPosts;
