import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import DashboardSidebar from './DashboardSidebar';
import { useAuth } from '../contexts/AuthContext';
import './MyMessage.css';
import { API_URL } from '../../config';

const MyMessage = () => {
  const { isLoggedIn, token } = useAuth();
  const [messages, setMessages] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [messagesPerPage] = useState(10);
  const navigate = useNavigate();
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };


  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${API_URL}/api/user/profile`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch user profile');
        }
        const data = await response.json();
        setUserProfile(data);
      } catch (error) {
        //console.error('Error fetching user profile:', error);
      }
    };

    const fetchMessages = async () => {
      try {
        const response = await fetch(`${API_URL}/api/private-messages/private-messages`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const messagesData = await response.json();
        setMessages(messagesData);
      } catch (error) {
        //console.error('Error fetching messages:', error);
      }
    };
    

    fetchUserProfile();
    fetchMessages();
  }, [isLoggedIn, token, navigate]);


  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = messages.slice(indexOfFirstMessage, indexOfLastMessage);

  return (
    <div className="dashboard-container">
      <DashboardSidebar />
      <section className="dashboard-content">
        <div className="message-container">
          <h1>My Messages (Inbox)</h1>
          <strong>Sent messages cannot be viewed at this time</strong>
          <div className="message-table">
            {currentMessages.map((message) => (
              <div className="message-entry" key={message.id}>
                <div className="message-header">
                  <div className="message-from">
                    <strong>From:</strong> {message.from_username}
                  </div>
                </div>
                <div className="message-body" dangerouslySetInnerHTML={createMarkup(message.message)} />
                <div className="message-details">
                  <strong>Trip Details</strong>
                  <div>{new Date(message.charter_date_start).toLocaleDateString('en-US')} - {new Date(message.charter_date_end).toLocaleDateString('en-US')}</div>
                  <div>{message.from} - {message.to}</div>
                  <div>{message.passengers} Passengers</div>
                  <div>{message.aircraft_size}</div>
                  <div>{message.have_need}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination">
            {Array.from({ length: Math.ceil(messages.length / messagesPerPage) }, (_, i) => (
              <button key={i} onClick={() => paginate(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyMessage;
