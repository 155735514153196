import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import './SendMessage.css';
import { API_URL } from '../../config';

const SendMessage = () => {
  const { token, isLoggedIn } = useAuth();
  const userId = localStorage.getItem('userId');
  const location = useLocation();
  const navigate = useNavigate();
  const charter = location.state?.charter;

  const [tripDetails, setTripDetails] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [aircrafts, setAircrafts] = useState([]);
  const [selectedAircraft, setSelectedAircraft] = useState('');
  const [userPlan, setUserPlan] = useState(''); // State to store user's plan

  // Add these lines within your SendMessage component's body, near your other useState hooks
const [captcha, setCaptcha] = useState({
  num1: Math.floor(Math.random() * 10),
  num2: Math.floor(Math.random() * 10),
});
const [captchaInput, setCaptchaInput] = useState('');


  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { state: { charter } });
    }
  }, [isLoggedIn, charter, navigate]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    fetch(`${API_URL}/api/addAircraft/myaircraft`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Request failed with status: ' + response.status);
        }
        return response.json();
      })
      .then((data) => {
       // console.log('Aircrafts:', data);
        setAircrafts(data);
        setIsLoading(false); // Mark loading as complete
      })
      .catch(error => {
        //console.error('Error fetching aircrafts:', error);
        setIsLoading(false); // Mark loading as complete even on error
      });

    // Fetch user's plan from local storage
    const userPlanFromStorage = localStorage.getItem('plan');
    setUserPlan(userPlanFromStorage);
  }, [userId, navigate, token]);

  useEffect(() => {
    if (!charter) {
      return;
    }
    //console.log('Charter:', charter);
  
    //console.log('User plan:', userPlan); // Log the user's plan
  
    //console.log('Charter have_need:', charter.have_need);
  
    setTripDetails(
      `${new Date(charter.charter_date_start).toLocaleDateString()} - ` +
      `${new Date(charter.charter_date_end).toLocaleDateString()} - ` +
      `${charter.from} - ${charter.to} - ${charter.passengers} Pax - ` +
      `${charter.aircraft_size}`
    );
  }, [charter, userPlan]);

  const sendEmail = (toEmail) => {
    const subject = 'Trip Message';
    const body = `
      <p>You have received a message about ${charter.charter_date_end} - ${charter.charter_date_start} - ${charter.from} - ${charter.to}</p>
      <p>Please <a href="http://yourwebsite.com/login">login</a> to view your message.</p>
    `;
  
    fetch(`${API_URL}/api/messages/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        from_user_id: userId,
        to_user_id: charter.user_id,
        charter_id: charter.id,
        message: message,
        aircraft_id: selectedAircraft,
        toEmail: toEmail,
        charter: charter // Include the charter object in the request body
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      //console.log('Message sent:', data);
      //console.log('Email Parameters:', data.emailParams); // Log the email parameters to the console
      alert('Your message has been sent.');
      setMessage('');
      navigate('/messages');
    })
    .catch(error => {
     // console.error('Error sending message:', error);
      alert('There was an error sending your message. Please try again.');
    });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if the message is empty
    if (!message.trim()) {
      alert('Please provide a message.');
      return;
    }
  
    if (!userId || !charter) {
     // console.error('User or charter is undefined');
      return;
    }
  
    //console.log('Sending message from user ID:', userId);
  
    //console.log('User plan:', userPlan); // Log the user's plan
  
    // Check if the charter data has scraped = "yes"
    if (charter.scraped === 'poop') {
      alert('Winner winner Chicken dinner'); // Display the popup message
      return; // Exit the function
    }
  
    // Check if the user's charter plan is "free" and the charter's have_need is "Need"
    if (userPlan === 'free' && charter.have_need === 'Need') {
      navigate('/pricing'); // Redirect to the pricing page
      return; // Exit the function
    }
  
    // If no aircraft type is selected, set it to null or some other appropriate value
    // For example, you can set it to "none" if that's acceptable in your backend logic
    if (!selectedAircraft) {
      setSelectedAircraft('none');
    }
  
    // Send email with the appropriate selectedAircraft value
    sendEmail(charter.email);
  };
  
  

  if (isLoading) {
    // Instead of just returning "Loading...", use the FontAwesome icon for a visual spinner
    return (
      <div className="loading-indicator">
        <FontAwesomeIcon icon={faPlane} spin />
        Loading...
      </div>
    );
  }

  return (
    <div className="sendMessage-container">
      <h1>Send Message - 109</h1>
      <form onSubmit={handleSubmit} className="sendMessage-form">
        <div className="form-group">
          <label htmlFor="tripDetails">Trip Details:</label>
          <textarea
            id="tripDetails"
            name="tripDetails"
            value={tripDetails}
            readOnly
          />
        </div>
        {charter && charter.have_need === 'Need' && aircrafts.length > 0 && (
          <div className="form-group">
            <label htmlFor="aircraft_id">Aircraft Type:</label>
            <select
              name="aircraft_id"
              value={selectedAircraft}
              onChange={(e) => setSelectedAircraft(e.target.value)}
            >
              <option value="none">None</option> {/* Add this line */}
              {aircrafts.map((aircraft) => (
                <option key={aircraft.id} value={aircraft.id}>
                  {aircraft.aircraft_type}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="form-group">
        <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{ minHeight: '100px' }}
          />
        </div>
        <div className="form-group">
  <label htmlFor="captcha">Captcha: {captcha.num1} + {captcha.num2} = ?</label>
  <input
    type="text"
    id="captcha"
    name="captcha"
    value={captchaInput}
    onChange={(e) => setCaptchaInput(e.target.value)}
    required
  />
</div>
        <button type="submit" className="sendMessage-submit">Send</button>
      </form>
    </div>
  );
};

export default SendMessage;
