import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import './LoginForm.css';
import { API_URL } from '../../config';
import { Helmet } from 'react-helmet';


function LoginForm() {
    const { login } = useAuth();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        login: '',
        password: '',
        captchaAnswer: '',
        error: null,
    });

    // New state for captcha numbers
    const [captchaNumbers, setCaptchaNumbers] = useState({
        num1: Math.floor(Math.random() * 10),
        num2: Math.floor(Math.random() * 10),
    });

    useEffect(() => {
        const success = localStorage.getItem('registrationSuccess');
        if (success === 'true') {
            localStorage.removeItem('registrationSuccess');
            // Show success message or handle accordingly
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate captcha first
        if (parseInt(formData.captchaAnswer) !== (captchaNumbers.num1 + captchaNumbers.num2)) {
            setFormData({ ...formData, error: 'Incorrect CAPTCHA answer. Please try again.' });
            return; // Stop the form submission if captcha is wrong
        }

        try {
            const response = await fetch(`${API_URL}/api/auth/login`, {
                method: 'POST',
                body: JSON.stringify({
                    login: formData.login,
                    password: formData.password,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            if (response.ok && data.success && data.token) {
                const { token, userId, email, plan, isConfirmed } = data;

                localStorage.setItem('token', token);
                localStorage.setItem('userId', userId);
                localStorage.setItem('email', email);
                localStorage.setItem('plan', plan);

                login({ token, userId, email, plan });

                if (plan === 'free') {
                    navigate('/pricing');
                } else {
                    navigate('/');
                }
                
            } else {
                setFormData({ ...formData, error: data.message });
            }
        } catch (error) {
            setFormData({ ...formData, error: 'Failed to log in. Please try again.' });
        }
    };

    return (
        <div className="login-form">
            <Helmet>
                <title>Login | Charter God</title>
                <meta name="description" content="Login to Charter God to access your dashboard, manage your bookings, and update your settings." />
                <meta name="keywords" content="Charter God login, air charter login, charter account access" />
                <link rel="canonical" href="https://www.chartergod.com/login" />
            </Helmet>
            <h2>Login</h2>
            {formData.error && <p className="error">{formData.error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="login">Username or Email:</label>
                    <input
                        type="text"
                        id="login"
                        name="login"
                        value={formData.login}
                        onChange={(e) => setFormData({ ...formData, login: e.target.value })}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        required
                    />
                </div>
                {/* CAPTCHA */}
                <div className="form-group captcha">
                    <label>
                        Solve the following: {captchaNumbers.num1} + {captchaNumbers.num2} =
                    </label>
                    <input
                        type="text"
                        name="captchaAnswer"
                        value={formData.captchaAnswer}
                        onChange={(e) => setFormData({ ...formData, captchaAnswer: e.target.value })}
                        required
                    />
                </div>
                <div className="form-group">
                    <input type="submit" value="Login" className="submit-button" />
                </div>
                <div className="form-group">
                    <p><a href="/forgot-password">Forgot Password?</a></p>
                    <p>Not a user? <a href="/register">Register</a></p>
                </div>
            </form>
        </div>
    );
}

export default LoginForm;
