import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import DashboardSidebar from './DashboardSidebar';
import { useAuth } from '../contexts/AuthContext';
import './PostListing.css';
import { API_URL } from '../../config';

const PostListings = () => {
  const { isLoggedIn, token, user } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    charter_date_start: '',
    charter_date_end: '',
    flexibility: 0,
    from: '',
    to: '',
    passengers: 1,
    aircraft_size: [],
    have_need: 'Need', // Default to 'Need'
    text_block: '',  // Add this line to handle the text block
    captchaAnswer: '', // CAPTCHA answer input by the user
  });

  // State for CAPTCHA numbers
  const [captchaNumbers, setCaptchaNumbers] = useState({
    num1: Math.floor(Math.random() * 10),
    num2: Math.floor(Math.random() * 10),
  });

  useEffect(() => {
    if (user) {
      console.log('User object:', user); // Log user object to check its properties
      setFormData(prevFormData => ({
        ...prevFormData,
        user_id: user.id,
        account_type: user.account_type, // Set account_type from user
        username: user.username // Set username from user
      }));
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'aircraft_size') {
      const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
      setFormData({ ...formData, [name]: selectedOptions });
    } else {
      let parsedValue = name === 'flexibility' || name === 'passengers' ? parseInt(value, 10) : value;
      setFormData({ ...formData, [name]: parsedValue });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (parseInt(formData.captchaAnswer) !== captchaNumbers.num1 + captchaNumbers.num2) {
      alert('Incorrect CAPTCHA answer. Please try again.');
      return;
    }

    const startDate = new Date(formData.charter_date_start);
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
    if (startDate < twoDaysAgo) {
      alert('Start date cannot be more than 2 days in the past.');
      return;
    }

    const endDate = new Date(formData.charter_date_end);
    if (endDate < startDate) {
      alert('End date cannot be before the start date.');
      return;
    }

    const submissionData = {
      ...formData,
      user_id: user.id,
      username: user.username,
      account_type: user.account_type,
      aircraft_size: JSON.stringify(formData.aircraft_size)
    };

    try {
      const response = await fetch(`${API_URL}/api/charters`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(submissionData),
      });

      const data = await response.json();
      if (response.ok) {
        navigate('/my-posts');
        alert('Your listing has been posted.');
      } else {
        console.error('Error creating charter:', data.message);
      }
    } catch (error) {
      console.error('Error creating charter:', error);
    }
  };

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="dashboard-container">
      <DashboardSidebar />
      <section className="dashboard-content">
        <div className="listing-container">
          <h1>Post a Need Listing</h1>
          <form onSubmit={handleSubmit} className="listing-form">
            <div className="form-group row-3">
              <label htmlFor="charter_date_start">Start Date:</label>
              <input type="date" name="charter_date_start" value={formData.charter_date_start} onChange={handleChange} required />
              
              <label htmlFor="charter_date_end">End Date:</label>
              <input type="date" name="charter_date_end" value={formData.charter_date_end} onChange={handleChange} required />
              
              <label htmlFor="flexibility">Flexibility (Days):</label>
              <input type="number" name="flexibility" min="0" value={formData.flexibility} onChange={handleChange} className="half-width-input" />
            </div>

            <div className="form-group row-2">
              <label htmlFor="from">From:</label>
              <label htmlFor="from">(Starting Location) </label>
              <input type="text" name="from" value={formData.from} onChange={handleChange} required className="half-width-input" />
              
              <label htmlFor="to">To:</label>
              <label htmlFor="to">(If Multiple Locations, you can put TEB-MIA-HPN or Teterboro-Miami-White Plains)</label>
              <input type="text" name="to" value={formData.to} onChange={handleChange} required className="half-width-input" />
            </div>

            <div className="form-group row-2">
            <label htmlFor="aircraft_size">Aircraft Type:</label>
              <select name="aircraft_size" value={formData.aircraft_size} onChange={handleChange} multiple required className="half-width-input">
                <option value="Any">Any</option>
                <option value="Airliner VIP">Airliner VIP</option>
                <option value="Heavy Jet">Heavy Jet</option>
                <option value="Helicopter">Helicopter</option>
                <option value="Jet Airliner">Jet Airliner</option>
                <option value="Light Jet">Light Jet</option>
                <option value="Mid Jet">Mid Jet</option>
                <option value="Piston Multi">Piston Multi</option>
                <option value="Piston Single">Piston Single</option>
                <option value="Turboprop">Turboprop</option>
                <option value="Turboprop Airliner">Turboprop Airliner</option>
                <option value="Very Light Jet">Very Light Jet</option>
              </select>
            </div>
            <div className="form-group row-2">
              <label htmlFor="passengers">Passengers:</label>
              <input type="number" name="passengers" min="1" max="500" value={formData.passengers} onChange={handleChange} required className="half-width-input" />
            </div>
            <div className="form-group">
              <label htmlFor="text_block">Additional Information:</label>
              <textarea
                name="text_block"
                value={formData.text_block}
                onChange={handleChange}
                placeholder="Trip Details"
                rows="4"  // Set the number of rows to define the size of the textarea
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="captcha">CAPTCHA: What is {captchaNumbers.num1} + {captchaNumbers.num2}?</label>
              <input
                type="text"
                id="captcha"
                name="captchaAnswer"
                value={formData.captchaAnswer}
                onChange={handleChange}
                required
              />
            </div>

            <button type="submit" className="listing-submit">Post Listing</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default PostListings;
