import React from 'react';
import './about.css'; // Import the CSS file here
import { Helmet } from 'react-helmet'; // Ensure react-helmet is imported


const About = () => {
  return (
    <div className="about">
          <Helmet>
        <title>About Us - Charter God</title>
        <meta name="description" content="Learn about Charter God, the premier platform connecting aviation professionals and clients for charter services. Discover how we facilitate communication and transactions within the air charter industry." />
        <meta name="keywords" content="Charter God, about Charter God, air charter services, air charter industry, aviation professionals, charter operators, charter brokers" />
        <link rel="canonical" href="https://www.chartergod.com/about" />
      </Helmet>
      <h1>About Charter God</h1>
      <p>
        Charter God connects aviation professionals and clients looking for charter services. Our platform lists available charters ("Haves") and specific charter needs ("Needs"), creating a comprehensive marketplace for the air charter industry.
      </p>
      <p>
        Our users include brokers, operators, and clients who are integral to the air charter business. To ensure privacy and reduce spam, we employ an internal messaging system for communication without revealing personal emails.
      </p>
      <p>
        Posting charter needs is free, making it easy for anyone to list their requirements. Messaging about Needs requires a fee, which helps maintain our high-quality service and user base. However, messaging Haves is completely free.
      </p>
      <p>
        We're dedicated to streamlining the charter process, providing an efficient and reliable space for the air charter community to thrive. Whether you're looking to book a flight or offer your services, Charter God is your premier air charter partner.
      </p>
      {/* Add more content as needed */}
    </div>
  );
};

export default About;
