import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import './groundTransport.css'; // Import groundTransport.css for styling
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';

const GroundTransport = () => {
  const [companies, setCompanies] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [companiesPerPage] = useState(10);
  const [filterName, setFilterName] = useState('');
  const [filterAirport, setFilterAirport] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${API_URL}/api/ground-transport`)
      .then(response => response.json())
      .then(data => setCompanies(data))
      .catch(error => console.error('Error fetching ground transport companies:', error));
  }, []);

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  const sortedCompanies = [...companies].sort((a, b) => {
    if (!sortBy) return 0;
    if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const filteredCompanies = sortedCompanies.filter(company => {
    const nameMatch = company.companyName.toLowerCase().includes(filterName.toLowerCase());
    const airportMatch = company.airport.toLowerCase().includes(filterAirport.toLowerCase());
    return nameMatch && airportMatch;
  });

  const indexOfLastCompany = currentPage * companiesPerPage;
  const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
  const currentCompanies = filteredCompanies.slice(indexOfFirstCompany, indexOfLastCompany);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const resetFilters = () => {
    setFilterName('');
    setFilterAirport('');
  };

  return (
    <div className="ground-transport">
      <header className="gt-header">
        <h1>Ground Transport Directory</h1>
      </header>
      <section className="gt-list">
        <div className="filter-controls">
          <div className="filter-control">
            <label>Company Name:</label>
            <input type="text" value={filterName} onChange={(e) => setFilterName(e.target.value)} />
          </div>
          <div className="filter-control">
            <label>Airport:</label>
            <input type="text" value={filterAirport} onChange={(e) => setFilterAirport(e.target.value)} />
          </div>
          <button onClick={resetFilters} className="reset-button">Reset Filters</button>
        </div>
        <table className="gt-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('companyName')}>
                Company Name {sortBy === 'companyName' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />)}
              </th>
              <th onClick={() => handleSort('phone')}>
                Phone {sortBy === 'phone' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />)}
              </th>
              <th onClick={() => handleSort('website')}>
                Website {sortBy === 'website' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />)}
              </th>
              <th onClick={() => handleSort('description')}>
                Description {sortBy === 'description' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />)}
              </th>
              <th onClick={() => handleSort('airport')}>
                Airport {sortBy === 'airport' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />)}
              </th>
              <th>
                Send Message
              </th>
            </tr>
          </thead>
          <tbody>
            {currentCompanies.map((company, index) => (
              <tr key={index}>
                <td>{company.companyName}</td>
                <td>{company.phone}</td>
                <td><a href={company.website} target="_blank" rel="noopener noreferrer">{company.website}</a></td>
                <td>{company.description}</td>
                <td>{company.airport}</td>
                <td>
                  <button
                    onClick={() => {
                      navigate('/send-message', { state: { company } });
                    }}
                  >
                    Send Message
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          {Array.from({ length: Math.ceil(filteredCompanies.length / companiesPerPage) }, (_, i) => (
            <button
              key={i}
              onClick={() => paginate(i + 1)}
              className={currentPage === i + 1 ? 'active' : ''}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </section>
    </div>
  );
};

export default GroundTransport;
