import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './PostPaymentPage.css'; // Ensure this CSS file contains the styles you want to use

const PostPaymentPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [counter, setCounter] = useState(10); // Set the countdown starting number

  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    if (sessionId) {
      fetch(`/stripe/session/${sessionId}`)
        .then(response => response.json())
        .then(data => {
          // Assuming the backend sends the specific data needed
          //console.log('Customer Email:', data.customerEmail);
         // console.log('Products Purchased:', data.products);
        })
        .catch(error => {
          console.error('Error fetching Stripe session data:', error);
        });
    }

    // Countdown timer
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    // Redirect after countdown
    if (counter === 0) {
      navigate('/'); // Redirect to homepage
    }

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, [searchParams, counter, navigate]);

  // Function to handle image click
  const handleImageClick = () => {
    navigate('/');
  };

  return (
    <div className="post-payment-container">
            <div className="countdown-message">
        You will be redirected to the homepage in {counter} seconds...
      </div>
      <img 
        src="/payment_success.jpeg" 
        alt="Payment Success" 
        className="payment-success-image"
        onClick={handleImageClick} // Add the onClick event here
      />

    </div>
  );
};

export default PostPaymentPage;
