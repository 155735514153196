// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [authState, setAuthState] = useState(() => {
    const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn')) || false;
    const user = JSON.parse(localStorage.getItem('user')) || null;
    const token = localStorage.getItem('token') || '';
    const userId = localStorage.getItem('userId') || null;
    const email = localStorage.getItem('email') || null;

    return { isLoggedIn, user, token, userId, email };
  });

  useEffect(() => {
    localStorage.setItem('isLoggedIn', JSON.stringify(authState.isLoggedIn));
    localStorage.setItem('user', JSON.stringify(authState.user));
    localStorage.setItem('token', authState.token);
    localStorage.setItem('userId', authState.userId);
    localStorage.setItem('email', authState.email);
  }, [authState]);

  const login = (userData) => {
    const { token, userId, email } = userData;
    const user = { userId, email };

    setAuthState({
      isLoggedIn: true,
      user,
      token,
      userId,
      email,
    });

    localStorage.setItem('isLoggedIn', JSON.stringify(true));
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    localStorage.setItem('userId', userId);
    localStorage.setItem('email', email);

    navigate('/');
  };

  const logout = () => {
    setAuthState({
      isLoggedIn: false,
      user: null,
      token: '',
      userId: null,
      email: null,
    });

    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');

    navigate('/');
  };

  return (
    <AuthContext.Provider value={{ ...authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
