import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './blog.css';
import { API_URL } from '../../config';

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetch(`${API_URL}/api/articles?page=${currentPage}`)
      .then(response => response.json())
      .then(data => setArticles(data))
      .catch(error => console.error('Error fetching articles:', error));
  }, [currentPage]);

  return (
    <div className="blog">
      <h1>Blog</h1>
      <div className="articles">
        {articles.map(article => (
          <div key={article.id} className="article">
            <h2>{article.title}</h2>
            <p>{article.body}</p>
            <p>{new Date(article.date).toLocaleDateString()}</p> {/* Format date */}
            <p>Tags: {article.tags}</p>
            <Link to={`/article/${article.id}`}>Read More</Link> {/* Read more link */}
          </div>
        ))}
      </div>
      <div className="pagination">
        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
        <button onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
      </div>
    </div>
  );
};

export default Blog;
