import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import DashboardSidebar from './DashboardSidebar';
import { useAuth } from '../contexts/AuthContext';
import './MyMessage.css'; // Corrected CSS file import
import { API_URL } from '../../config';

const MyListings = () => {
  const { isLoggedIn, token } = useAuth();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!isLoggedIn) {
      setError('User is not authenticated');
      setLoading(false);
      return;
    }

    const fetchListings = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/api/charters/user`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setListings(data.charters); // Assuming your endpoint sends back an object with a charters array
      } catch (error) {
        setError('Failed to load listings');
      } finally {
        setLoading(false);
      }
    };

    fetchListings();
  }, [isLoggedIn, token]);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="dashboard-container">
      <DashboardSidebar />
      <div className="dashboard-content">
        <h1>My Listings</h1>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {!loading && !error && (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>From</th>
                <th>To</th>
                <th>Passengers</th>
                <th>Aircraft Size</th>
                <th>Type</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {listings.map((listing) => (
                <tr key={listing.id}>
                  <td>{listing.id}</td>
                  <td>{listing.charter_date_start}</td>
                  <td>{listing.charter_date_end}</td>
                  <td>{listing.from}</td>
                  <td>{listing.to}</td>
                  <td>{listing.passengers}</td>
                  <td>{listing.aircraft_size}</td>
                  <td>{listing.have_need}</td>
                  <td>{listing.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default MyListings;
