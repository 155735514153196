import React, { useState, useRef } from 'react';
import './Contact.css'; // Make sure this import path is correct
import { API_URL } from '../../config';
import { Helmet } from 'react-helmet'; // Ensure react-helmet is imported


const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Contact = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [topic, setTopic] = useState('');
  const [captchaResult, setCaptchaResult] = useState('');
  const [captchaNumbers, setCaptchaNumbers] = useState({
    num1: Math.floor(Math.random() * 10),
    num2: Math.floor(Math.random() * 10),
  });
  const recaptchaRef = useRef(null); // Create a ref for the reCAPTCHA, if you're using one

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (captchaResult !== `${captchaNumbers.num1 + captchaNumbers.num2}`) {
      alert('Incorrect captcha. Please try again.');
      return;
    }

    if (!emailRegex.test(email)) {
      alert('Invalid email address.');
      return;
    }

    const formData = {
      email,
      message,
      topic,
    };

    //console.log('Form Data:', formData); // Add this console log statement


    try {
      const response = await fetch(`${API_URL}/api/auth/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setEmail('');
        setMessage('');
        setTopic('');
        setCaptchaResult('');
        setCaptchaNumbers({
          num1: Math.floor(Math.random() * 10),
          num2: Math.floor(Math.random() * 10),
        });
        if (recaptchaRef.current) {
          recaptchaRef.current.reset(); // If you're using a reCAPTCHA, reset it here
        }
        alert('Your message has been sent. We will get back to you within 24 hours.');
      } else {
        const errorData = await response.json();
        alert(errorData.message || 'Failed to send your message. Please try again.');
      }
    } catch (error) {
      //console.error('Network error:', error);
      alert('Network error: Could not submit the form.');
    }
  };

  const handleCaptchaInputChange = (event) => {
    setCaptchaResult(event.target.value);
  };

  return (
    <div className="contact-container">
        <Helmet>
        <title>Contact Us - Charter God</title>
        <meta name="description" content="Reach out to Charter God for any inquiries or support needs. Whether you need help with bookings, have questions about our services, or wish to provide feedback, our team is ready to assist you." />
        <meta name="keywords" content="Charter God contact, Charter God support, air charter help, charter booking assistance, aviation support" />
        <link rel="canonical" href="https://www.chartergod.com/contact" />
      </Helmet>
      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="topic">Topic</label>
          <select
            id="topic"
            name="topic"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            required
          >
            <option value="">Select a topic</option>
            <option value="Business Inquiry">Business Inquiry</option>
            <option value="Subscription Cancellation">Subscription Cancellation</option>
            <option value="Report an listing">Report an listing</option>
            <option value="Technical Issue">Technical Issue</option>
            <option value="Remove me from Email marketing">Remove me from Email marketing</option>
            <option value="Booking Assistance">Booking Assistance</option>
            <option value="Flight Customization">Flight Customization</option>
            <option value="Payment and Billing Inquiries">Payment and Billing Inquiries</option>
            <option value="Safety and Security Concerns">Safety and Security Concerns</option>
            <option value="Charter Operator Ratings and Reviews">Charter Operator Ratings and Reviews</option>
            <option value="Legal and Compliance Issues">Legal and Compliance Issues</option>
            <option value="Technical Support">Technical Support</option>
            <option value="Marketing and Advertising Opportunities">Marketing and Advertising Opportunities</option>
            <option value="Partnership and Collaboration Requests">Partnership and Collaboration Requests</option>
            <option value="General Feedback and Suggestions">General Feedback and Suggestions</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <div className="form-group captcha">
          <label htmlFor="captcha">Captcha: {captchaNumbers.num1} + {captchaNumbers.num2} = ?</label>
          <input
            type="text"
            id="captcha"
            name="captcha"
            value={captchaResult}
            onChange={handleCaptchaInputChange}
            required
          />
        </div>
        <button type="submit" className="contact-submit">Send</button>
      </form>
    </div>
  );
};

export default Contact;
