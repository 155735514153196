import React, { useState } from 'react';
import DashboardSidebar from './DashboardSidebar';
import { useAuth } from '../contexts/AuthContext';
import { API_URL } from '../../config';
import { useNavigate, Navigate } from 'react-router-dom';

const AddAircraft = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [operator, setOperator] = useState('');
  const [category, setCategory] = useState('');
  const [type, setType] = useState('');
  const [passengers, setPassengers] = useState('');
  const [year, setYear] = useState('');
  const [homeBase, setHomeBase] = useState('');

  const aircraftData = [
    {"category": "Airliner VIP", "type": "A318 ELITE "},
    {"category": "Airliner VIP", "type": "A318 VIP A318 "},
    {"category": "Airliner VIP", "type": "A319 VIP "},
    {"category": "Airliner VIP", "type": "A319-115 CJ "},
    {"category": "Airliner VIP", "type": "A319-CJ CORPORATE JETLINER "},
    {"category": "Airliner VIP", "type": "A320 VIP "},
    {"category": "Airliner VIP", "type": "A320-200 VIP "},
    {"category": "Airliner VIP", "type": "A340-200 VIP "},
    {"category": "Airliner VIP", "type": "B-737 VIP "},
    {"category": "Airliner VIP", "type": "B-737-200 VIP "},
    {"category": "Airliner VIP", "type": "B-737-300 VIP "},
    {"category": "Airliner VIP", "type": "B-737-400 VIP "},
    {"category": "Airliner VIP", "type": "B-737-500 VIP "},
    {"category": "Airliner VIP", "type": "B-737-700 VIP "},
    {"category": "Airliner VIP", "type": "B-737-BJ BOEING BUSINESS JET "},
    {"category": "Airliner VIP", "type": "B-747 VIP "},
    {"category": "Airliner VIP", "type": "B-757-200 VIP "},
    {"category": "Airliner VIP", "type": "B-767-200ER VIP "},
    {"category": "Airliner VIP", "type": "B-767-300ER VIP "},
    {"category": "Airliner VIP", "type": "B-777-200LR "},
    {"category": "Airliner VIP", "type": "B-787-800 VIP DREAMLINER "},
    {"category": "Airliner VIP", "type": "CRJ-200 VIP REGIONAL JET "},
    {"category": "Airliner VIP", "type": "DO-328J VIP "},
    {"category": "Airliner VIP", "type": "EMB-190 VIP LINEAGE 1000 "},
    {"category": "Airliner VIP", "type": "SBJ-100 "},
    {"category": "Airliner VIP", "type": "YAK-40 VIP "},
    {"category": "Heavy Jet", "type": "BD-5000-1A10 GLOBAL 5000 "},
    {"category": "Heavy Jet", "type": "BD-5000-1A10 VISION GLOBAL 5000 VISION "},
    {"category": "Heavy Jet", "type": "BD-5000-1A10ER GLOBAL 5000 ER "},
    {"category": "Heavy Jet", "type": "BD-5500-1A10 GLOBAL 5500 "},
    {"category": "Heavy Jet", "type": "BD-6000-1A10 GLOBAL 6000 "},
    {"category": "Heavy Jet", "type": "BD-6500-1A10 GLOBAL 6500 "},
    {"category": "Heavy Jet", "type": "BD-700-1A10 GLOBAL EXPRESS "},
    {"category": "Heavy Jet", "type": "BD-700-1A10/6000 GLOBAL 6000 "},
    {"category": "Heavy Jet", "type": "BD-700-1A10/XRS GLOBAL EXPRESS XRS "},
    {"category": "Heavy Jet", "type": "BD-700-1A11 GLOBAL EXPRESS "},
    {"category": "Heavy Jet", "type": "BD-700-2A12 GLOBAL 7500 "},
    {"category": "Heavy Jet", "type": "CL-600 CHALLENGER 600 "},
    {"category": "Heavy Jet", "type": "CL-600-2B16 CHALLENGER 600 "},
    {"category": "Heavy Jet", "type": "CL-600-2B19 CHALLENGER 600 "},
    {"category": "Heavy Jet", "type": "CL-601 CHALLENGER 601 "},
    {"category": "Heavy Jet", "type": "CL-601-3A CHALLENGER 601 "},
    {"category": "Heavy Jet", "type": "CL-601-3R CHALLENGER "},
    {"category": "Heavy Jet", "type": "CL-604 CHALLENGER 604 "},
    {"category": "Heavy Jet", "type": "CL-605 CHALLENGER 605 "},
    {"category": "Heavy Jet", "type": "CL-650 CHALLENGER 650 "},
    {"category": "Heavy Jet", "type": "DA-2000 FALCON 2000 "},
    {"category": "Heavy Jet", "type": "DA-2000EX EASY "},
    {"category": "Heavy Jet", "type": "DA-2000EX FALCON 2000EX "},
    {"category": "Heavy Jet", "type": "DA-2000LX FALCON 2000LX "},
    {"category": "Heavy Jet", "type": "DA-2000LXS FALCON LXS "},
    {"category": "Heavy Jet", "type": "DA-2000S FALCON "},
    {"category": "Heavy Jet", "type": "DA-7X FALCON 7X "},
    {"category": "Heavy Jet", "type": "DA-8X FALCON 8X "},
    {"category": "Heavy Jet", "type": "DA-900 FALCON 900 "},
    {"category": "Heavy Jet", "type": "DA-900B FALCON 900B "},
    {"category": "Heavy Jet", "type": "DA-900C FALCON 900C "},
    {"category": "Heavy Jet", "type": "DA-900DX FALCON 900DX "},
    {"category": "Heavy Jet", "type": "DA-900EX EASY FALCON 900EX EASY "},
    {"category": "Heavy Jet", "type": "DA-900EX FALCON 900EX "},
    {"category": "Heavy Jet", "type": "DA-900LX "},
    {"category": "Heavy Jet", "type": "DA-900LX EASY FALCON 900LX EASY "},
    {"category": "Heavy Jet", "type": "EMB-135BJ Legacy"},
    {"category": "Heavy Jet", "type": "EMB-135BJ LEGACY 600"},
    {"category": "Heavy Jet", "type": "EMB-135BJ LEGACY 650"},
    {"category": "Heavy Jet", "type": "G-1159 GULFSTREAM II "},
    {"category": "Heavy Jet", "type": "G-1159A GULFSTREAM III "},
    {"category": "Heavy Jet", "type": "G-1159B GULFSTREAM IIB "},
    {"category": "Heavy Jet", "type": "G-300 G-300 "},
    {"category": "Heavy Jet", "type": "G-350 G-350 "},
    {"category": "Heavy Jet", "type": "G-400 G-400 "},
    {"category": "Heavy Jet", "type": "G-450 G-450 "},
    {"category": "Heavy Jet", "type": "G-500 G-500 "},
    {"category": "Heavy Jet", "type": "G-550 G-550 "},
    {"category": "Heavy Jet", "type": "G-600 G-600/VII "},
    {"category": "Heavy Jet", "type": "G-650 "},
    {"category": "Heavy Jet", "type": "G-650ER "},
    {"category": "Heavy Jet", "type": "G-IV GULFSTREAM IV "},
    {"category": "Heavy Jet", "type": "G-IV SP GULFSTREAM IV "},
    {"category": "Heavy Jet", "type": "G-IV-X "},
    {"category": "Heavy Jet", "type": "G-V GULFSTREAM V "},
    {"category": "Heavy Jet", "type": "L-1329 JETSTAR "},
    {"category": "Helicopter", "type": "A-109"},
    {"category": "Helicopter", "type": "A-109-K2"},
    {"category": "Helicopter", "type": "A-109A"},
    {"category": "Helicopter", "type": "A-109A-2 MARK II"},
    {"category": "Helicopter", "type": "A-109C"},
    {"category": "Helicopter", "type": "A-109E POWER"},
    {"category": "Helicopter", "type": "A-109S GRAND"},
    {"category": "Helicopter", "type": "A-109SP GRAND"},
    {"category": "Helicopter", "type": "A-119 KOALA"},
    {"category": "Helicopter", "type": "A-139 AGUSTA WESTLAND"},
    {"category": "Helicopter", "type": "A-169"},
    {"category": "Helicopter", "type": "AB-139 AGUSTA BELL"},
    {"category": "Helicopter", "type": "AS-332L SUPER PUMA"},
    {"category": "Helicopter", "type": "AS-332L2 SUPER PUMA MKII"},
    {"category": "Helicopter", "type": "AS-350 ASTAR"},
    {"category": "Helicopter", "type": "AS-350 B2 SINGLE SQUIRREL"},
    {"category": "Helicopter", "type": "AS-350B ECUREUIL"},
    {"category": "Helicopter", "type": "AS-350B-1 ASTAR"},
    {"category": "Helicopter", "type": "AS-350B-2 EUROCOPTER"},
    {"category": "Helicopter", "type": "AS-350B-3 EUROCOPTER"},
    {"category": "Helicopter", "type": "AS-350B-3E EUROCOPTER"},
    {"category": "Helicopter", "type": "AS-350BA EUROCOPTER"},
    {"category": "Helicopter", "type": "AS-350D ASTAR MKII"},
    {"category": "Helicopter", "type": "AS-355 TWIN SQUIRREL"},
    {"category": "Helicopter", "type": "AS-355F TWIN SQUIRREL"},
    {"category": "Helicopter", "type": "AS-355F-1 TWINSTAR"},
    {"category": "Helicopter", "type": "AS-355F-2 TWINSTAR"},
    {"category": "Helicopter", "type": "AS-355N"},
    {"category": "Helicopter", "type": "AS-355NP TWIN SQUIRREL"},
    {"category": "Helicopter", "type": "AS-365 DAUPHIN"},
    {"category": "Helicopter", "type": "AS-365C DAUPHIN 2"},
    {"category": "Helicopter", "type": "AS-365N DAUPHIN 2"},
    {"category": "Helicopter", "type": "AS-365N-1 DAUPHIN 2"},
    {"category": "Helicopter", "type": "AS-365N-2 DAUPHIN"},
    {"category": "Helicopter", "type": "AS-365N-3"},
    {"category": "Helicopter", "type": "AW-109"},
    {"category": "Helicopter", "type": "AW-109SP"},
    {"category": "Helicopter", "type": "AW-139"},
    {"category": "Helicopter", "type": "AW-169"},
    {"category": "Helicopter", "type": "AW-189"},
    {"category": "Helicopter", "type": "BK-117"},
    {"category": "Helicopter", "type": "BK-117B-2"},
    {"category": "Helicopter", "type": "BK-117C-1 BK-117C-1"},
    {"category": "Helicopter", "type": "BK-117C-2"},
    {"category": "Helicopter", "type": "BO-105"},
    {"category": "Helicopter", "type": "BO-105CBS-4"},
    {"category": "Helicopter", "type": "EC-120"},
    {"category": "Helicopter", "type": "EC-120-B COLIBRI"},
    {"category": "Helicopter", "type": "EC-130"},
    {"category": "Helicopter", "type": "EC-130 T2"},
    {"category": "Helicopter", "type": "EC-130-B4"},
    {"category": "Helicopter", "type": "EC-135"},
    {"category": "Helicopter", "type": "EC-135 P2+"},
    {"category": "Helicopter", "type": "EC-135 P3"},
    {"category": "Helicopter", "type": "EC-135T-1 EUROCOPTER"},
    {"category": "Helicopter", "type": "EC-135T-2 EUROCOPTER"},
    {"category": "Helicopter", "type": "EC-135T-3"},
    {"category": "Helicopter", "type": "EC-145 EUROCOPTER"},
    {"category": "Helicopter", "type": "EC-150"},
    {"category": "Helicopter", "type": "EC-155 VIP"},
    {"category": "Helicopter", "type": "EC-155B"},
    {"category": "Helicopter", "type": "EC-155B1"},
    {"category": "Helicopter", "type": "EN-480B"},
    {"category": "Helicopter", "type": "H-120"},
    {"category": "Helicopter", "type": "H125"},
    {"category": "Helicopter", "type": "H130"},
    {"category": "Helicopter", "type": "H130 B4"},
    {"category": "Helicopter", "type": "H130 T2"},
    {"category": "Helicopter", "type": "H135 ACH"},
    {"category": "Helicopter", "type": "H145 H145"},
    {"category": "Helicopter", "type": "H155 DAUPHIN"},
    {"category": "Helicopter", "type": "H175 H175"},
    {"category": "Helicopter", "type": "H225 SUPER PUMA"},
    {"category": "Helicopter", "type": "HB-204B-2"},
    {"category": "Helicopter", "type": "HB-205"},
    {"category": "Helicopter", "type": "HB-205A"},
    {"category": "Helicopter", "type": "HB-205A-1"},
    {"category": "Helicopter", "type": "HB-205A1"},
    {"category": "Helicopter", "type": "HB-206 JETRANGER"},
    {"category": "Helicopter", "type": "HB-206-B JETRANGER"},
    {"category": "Helicopter", "type": "HB-206-L LONGRANGER"},
    {"category": "Helicopter", "type": "HB-206A JETRANGER II"},
    {"category": "Helicopter", "type": "HB-206B JETRANGER III"},
    {"category": "Helicopter", "type": "HB-206B-2 JETRANGER II"},
    {"category": "Helicopter", "type": "HB-206B-3 JETRANGER III"},
    {"category": "Helicopter", "type": "HB-206L LONGRANGER"},
    {"category": "Helicopter", "type": "HB-206L-1 LONGRANGER III"},
    {"category": "Helicopter", "type": "HB-206L-3 LONGRANGER"},
    {"category": "Helicopter", "type": "HB-206L-4 LONGRANGER IV"},
    {"category": "Helicopter", "type": "HB-212 TWIN TWO-TWELVE"},
    {"category": "Helicopter", "type": "HB-212EP TWIN TWO-TWELVE"},
    {"category": "Helicopter", "type": "HB-214B-1 BIGLIFTER"},
    {"category": "Helicopter", "type": "HB-222"},
    {"category": "Helicopter", "type": "HB-222UT"},
    {"category": "Helicopter", "type": "HB-230"},
    {"category": "Helicopter", "type": "HB-407 TEXTRON"},
    {"category": "Helicopter", "type": "HB-407GX"},
    {"category": "Helicopter", "type": "HB-412"},
    {"category": "Helicopter", "type": "HB-412-SP"},
    {"category": "Helicopter", "type": "HB-412EP"},
    {"category": "Helicopter", "type": "HB-427"},
    {"category": "Helicopter", "type": "HB-429"},
    {"category": "Helicopter", "type": "HB-430"},
    {"category": "Helicopter", "type": "HB-47G-3B1 AGUSTA - BELL"},
    {"category": "Helicopter", "type": "HB-47G-5A AGUSTA - BELL"},
    {"category": "Helicopter", "type": "HB-505 JET RANGER X"},
    {"category": "Helicopter", "type": "HU-300C"},
    {"category": "Helicopter", "type": "HU-369"},
    {"category": "Helicopter", "type": "HU-369D"},
    {"category": "Helicopter", "type": "KA-1200 K-MAX KAMAN 1200 K-MAX"},
    {"category": "Helicopter", "type": "KA-32 KAMOV"},
    {"category": "Helicopter", "type": "KA-32S"},
    {"category": "Helicopter", "type": "KA-32T"},
    {"category": "Helicopter", "type": "MD-369D"},
    {"category": "Helicopter", "type": "MD-369E"},
    {"category": "Helicopter", "type": "MD-500E"},
    {"category": "Helicopter", "type": "MD-520N"},
    {"category": "Helicopter", "type": "MD-530F MD-530F"},
    {"category": "Helicopter", "type": "MD-600N"},
    {"category": "Helicopter", "type": "MD-900 EXPLORER"},
    {"category": "Helicopter", "type": "MD-902"},
    {"category": "Helicopter", "type": "MI-17"},
    {"category": "Helicopter", "type": "MI-171"},
    {"category": "Helicopter", "type": "MI-171A2 MI-171A2"},
    {"category": "Helicopter", "type": "MI-26"},
    {"category": "Helicopter", "type": "MI-8"},
    {"category": "Helicopter", "type": "MI-8-MTV"},
    {"category": "Helicopter", "type": "MI-8-MTV1"},
    {"category": "Helicopter", "type": "MI-8-PS9"},
    {"category": "Helicopter", "type": "MI-8T"},
    {"category": "Helicopter", "type": "PZL W-3 SOKOL"},
    {"category": "Helicopter", "type": "R-22 BETA"},
    {"category": "Helicopter", "type": "R-44"},
    {"category": "Helicopter", "type": "R-44 II"},
    {"category": "Helicopter", "type": "R-66"},
    {"category": "Helicopter", "type": "SA-315 LAMA"},
    {"category": "Helicopter", "type": "SA-315B LAMA"},
    {"category": "Helicopter", "type": "SA-316 ALOUETTE"},
    {"category": "Helicopter", "type": "SA-316B ALOUETTE III"},
    {"category": "Helicopter", "type": "SA-341G GAZELLE"},
    {"category": "Helicopter", "type": "SCH-330SP 330"},
    {"category": "Helicopter", "type": "SCH-333"},
    {"category": "Helicopter", "type": "SK-58"},
    {"category": "Helicopter", "type": "SK-61"},
    {"category": "Helicopter", "type": "SK-76 SPIRIT MKII"},
    {"category": "Helicopter", "type": "SK-76A MARK II"},
    {"category": "Helicopter", "type": "SK-76B"},
    {"category": "Helicopter", "type": "SK-76C"},
    {"category": "Helicopter", "type": "SK-76C+"},
    {"category": "Helicopter", "type": "SK-76C++"},
    {"category": "Helicopter", "type": "SK-76D D"},
    {"category": "Helicopter", "type": "SK-92"},
    {"category": "Helicopter", "type": "UH-1H"},
    {"category": "Jet Airliner", "type": "A220"},
    {"category": "Jet Airliner", "type": "A300-600"},
    {"category": "Jet Airliner", "type": "A300-B4"},
    {"category": "Jet Airliner", "type": "A300B4-200"},
    {"category": "Jet Airliner", "type": "A310-300"},
    {"category": "Jet Airliner", "type": "A318"},
    {"category": "Jet Airliner", "type": "A319"},
    {"category": "Jet Airliner", "type": "A320"},
    {"category": "Jet Airliner", "type": "A320 NEO"},
    {"category": "Jet Airliner", "type": "A320-200"},
    {"category": "Jet Airliner", "type": "A320-232"},
    {"category": "Jet Airliner", "type": "A321"},
    {"category": "Jet Airliner", "type": "A321-200"},
    {"category": "Jet Airliner", "type": "A321-231"},
    {"category": "Jet Airliner", "type": "A330"},
    {"category": "Jet Airliner", "type": "A330-200"},
    {"category": "Jet Airliner", "type": "A330-300"},
    {"category": "Jet Airliner", "type": "A330-900"},
    {"category": "Jet Airliner", "type": "A340-300"},
    {"category": "Jet Airliner", "type": "A350-900"},
    {"category": "Jet Airliner", "type": "AN-124-100"},
    {"category": "Jet Airliner", "type": "AN-148-100E"},
    {"category": "Jet Airliner", "type": "B-727"},
    {"category": "Jet Airliner", "type": "B-727-200"},
    {"category": "Jet Airliner", "type": "B-727F"},
    {"category": "Jet Airliner", "type": "B-737"},
    {"category": "Jet Airliner", "type": "B-737 MAX 8"},
    {"category": "Jet Airliner", "type": "B-737-200"},
    {"category": "Jet Airliner", "type": "B-737-200C"},
    {"category": "Jet Airliner", "type": "B-737-300"},
    {"category": "Jet Airliner", "type": "B-737-300F"},
    {"category": "Jet Airliner", "type": "B-737-300QC"},
    {"category": "Jet Airliner", "type": "B-737-400"},
    {"category": "Jet Airliner", "type": "B-737-400F"},
    {"category": "Jet Airliner", "type": "B-737-500"},
    {"category": "Jet Airliner", "type": "B-737-700"},
    {"category": "Jet Airliner", "type": "B-737-800"},
    {"category": "Jet Airliner", "type": "B-737-800W"},
    {"category": "Jet Airliner", "type": "B-737-900"},
    {"category": "Jet Airliner", "type": "B-747"},
    {"category": "Jet Airliner", "type": "B-747-200"},
    {"category": "Jet Airliner", "type": "B-747-400"},
    {"category": "Jet Airliner", "type": "B-747-400ERF"},
    {"category": "Jet Airliner", "type": "B-747-400F"},
    {"category": "Jet Airliner", "type": "B-747-8F"},
    {"category": "Jet Airliner", "type": "B-757"},
    {"category": "Jet Airliner", "type": "B-757-200"},
    {"category": "Jet Airliner", "type": "B-757-300"},
    {"category": "Jet Airliner", "type": "B-767"},
    {"category": "Jet Airliner", "type": "B-767-200"},
    {"category": "Jet Airliner", "type": "B-767-300"},
    {"category": "Jet Airliner", "type": "B-767-300ER"},
    {"category": "Jet Airliner", "type": "B-777"},
    {"category": "Jet Airliner", "type": "B-777-300ER"},
    {"category": "Jet Airliner", "type": "B-787-800"},
    {"category": "Jet Airliner", "type": "B-787-900"},
    {"category": "Jet Airliner", "type": "BAE-146"},
    {"category": "Jet Airliner", "type": "BAE-146-200"},
    {"category": "Jet Airliner", "type": "BAE-146-200QT"},
    {"category": "Jet Airliner", "type": "BAE-146-300"},
    {"category": "Jet Airliner", "type": "BAE-146-RJ70"},
    {"category": "Jet Airliner", "type": "BAE-146/RJ100"},
    {"category": "Jet Airliner", "type": "BAE-146/RJ85"},
    {"category": "Jet Airliner", "type": "CRJ-100"},
    {"category": "Jet Airliner", "type": "CRJ-1000"},
    {"category": "Jet Airliner", "type": "CRJ-200"},
    {"category": "Jet Airliner", "type": "CRJ-700"},
    {"category": "Jet Airliner", "type": "CRJ-900"},
    {"category": "Jet Airliner", "type": "DC-9"},
    {"category": "Jet Airliner", "type": "DC-9-15"},
    {"category": "Jet Airliner", "type": "DC-9-30"},
    {"category": "Jet Airliner", "type": "DO-328J"},
    {"category": "Jet Airliner", "type": "DO-ENVOY-3"},
    {"category": "Jet Airliner", "type": "E175"},
    {"category": "Jet Airliner", "type": "EMB-145"},
    {"category": "Jet Airliner", "type": "EMB-145LR"},
    {"category": "Jet Airliner", "type": "EMB-190"},
    {"category": "Jet Airliner", "type": "EMB-190 E2"},
    {"category": "Jet Airliner", "type": "EMB-190-100 ECJ"},
    {"category": "Jet Airliner", "type": "EMB-195"},
    {"category": "Jet Airliner", "type": "EMB-195 E2"},
    {"category": "Jet Airliner", "type": "ERJ-135"},
    {"category": "Jet Airliner", "type": "ERJ-135ER"},
    {"category": "Jet Airliner", "type": "ERJ-140LR"},
    {"category": "Jet Airliner", "type": "ERJ-145"},
    {"category": "Jet Airliner", "type": "ERJ-145LR"},
    {"category": "Jet Airliner", "type": "ERJ-170"},
    {"category": "Jet Airliner", "type": "ERJ-175"},
    {"category": "Jet Airliner", "type": "ERJ-190-100 IGW"},
    {"category": "Jet Airliner", "type": "ERJ-190-400"},
    {"category": "Jet Airliner", "type": "FK-100"},
    {"category": "Jet Airliner", "type": "FK-70"},
    {"category": "Jet Airliner", "type": "IL-76"},
    {"category": "Jet Airliner", "type": "IL-76TD"},
    {"category": "Jet Airliner", "type": "MD-80"},
    {"category": "Jet Airliner", "type": "MD-82"},
    {"category": "Jet Airliner", "type": "MD-83"},
    {"category": "Jet Airliner", "type": "MD-83SF"},
    {"category": "Jet Airliner", "type": "MD-88"},
    {"category": "Jet Airliner", "type": "RJ100"},
    {"category": "Jet Airliner", "type": "SSJ-100"},
    {"category": "Light Jet", "type": "BE-400"},
    {"category": "Light Jet", "type": "BE-400A"},
    {"category": "Light Jet", "type": "BE-400XP"},
    {"category": "Light Jet", "type": "CE-500"},
    {"category": "Light Jet", "type": "CE-500E"},
    {"category": "Light Jet", "type": "CE-501"},
    {"category": "Light Jet", "type": "CE-501SP"},
    {"category": "Light Jet", "type": "CE-525"},
    {"category": "Light Jet", "type": "CE-525-M2"},
    {"category": "Light Jet", "type": "CE-525A"},
    {"category": "Light Jet", "type": "CE-525A+"},
    {"category": "Light Jet", "type": "CE-525B"},
    {"category": "Light Jet", "type": "CE-525B+"},
    {"category": "Light Jet", "type": "CE-525C"},
    {"category": "Light Jet", "type": "CE-525C GEN 2"},
    {"category": "Light Jet", "type": "CE-550"},
    {"category": "Light Jet", "type": "CE-550-B"},
    {"category": "Light Jet", "type": "CE-551"},
    {"category": "Light Jet", "type": "CE-560"},
    {"category": "Light Jet", "type": "CE-560-E"},
    {"category": "Light Jet", "type": "CE-560-U"},
    {"category": "Light Jet", "type": "CE-S550"},
    {"category": "Light Jet", "type": "DA-10"},
    {"category": "Light Jet", "type": "DA-100"},
    {"category": "Light Jet", "type": "EMB-505"},
    {"category": "Light Jet", "type": "EMB-505 (PHENOM 300E)"},
    {"category": "Light Jet", "type": "HA-420"},
    {"category": "Light Jet", "type": "HA-420 ELITE"},
    {"category": "Light Jet", "type": "HS-125-400XP"},
    {"category": "Light Jet", "type": "IAI-1124"},
    {"category": "Light Jet", "type": "IAI-1124A"},
    {"category": "Light Jet", "type": "LR-25B"},
    {"category": "Light Jet", "type": "LR-25D"},
    {"category": "Light Jet", "type": "LR-31"},
    {"category": "Light Jet", "type": "LR-31A"},
    {"category": "Light Jet", "type": "LR-31ER"},
    {"category": "Light Jet", "type": "LR-35"},
    {"category": "Light Jet", "type": "LR-35A"},
    {"category": "Light Jet", "type": "LR-36"},
    {"category": "Light Jet", "type": "LR-36A"},
    {"category": "Light Jet", "type": "LR-40"},
    {"category": "Light Jet", "type": "LR-40XR"},
    {"category": "Light Jet", "type": "LR-70"},
    {"category": "Light Jet", "type": "LR-75"},
    {"category": "Light Jet", "type": "MU-300"},
    {"category": "Light Jet", "type": "NEXTANT 400XT"},
    {"category": "Light Jet", "type": "NEXTANT 400XTI"},
    {"category": "Light Jet", "type": "PC-24"},
    {"category": "Light Jet", "type": "RA-390"},
    {"category": "Mid Jet", "type": "BD-100-1A10 CHALLENGER 300 "},
    {"category": "Mid Jet", "type": "BD-100-1A10 CHALLENGER 350"},
    {"category": "Mid Jet", "type": "BD-100-1A10 CL-350"},
    {"category": "Mid Jet", "type": "BD-100-Q1A10 CHALLENGER 300 "},
    {"category": "Mid Jet", "type": "CE-560-XL"},
    {"category": "Mid Jet", "type": "CE-560-XLS"},
    {"category": "Mid Jet", "type": "CE-560-XLS+"},
    {"category": "Mid Jet", "type": "CE-560-XLS+ GEN 2"},
    {"category": "Mid Jet", "type": "CE-650"},
    {"category": "Mid Jet", "type": "CE-650 III"},
    {"category": "Mid Jet", "type": "CE-650 IV"},
    {"category": "Mid Jet", "type": "CE-650-VI"},
    {"category": "Mid Jet", "type": "CE-650-VII"},
    {"category": "Mid Jet", "type": "CE-680 + CITATION SOVEREIGN + "},
    {"category": "Mid Jet", "type": "CE-680 CITATION SOVEREIGN "},
    {"category": "Mid Jet", "type": "CE-680A"},
    {"category": "Mid Jet", "type": "CE-700"},
    {"category": "Mid Jet", "type": "CE-750 CITATION X "},
    {"category": "Mid Jet", "type": "CE-750+"},
    {"category": "Mid Jet", "type": "CL-850 CHALLENGER "},
    {"category": "Mid Jet", "type": "DA-20"},
    {"category": "Mid Jet", "type": "DA-20-C5"},
    {"category": "Mid Jet", "type": "DA-20-D"},
    {"category": "Mid Jet", "type": "DA-20-E"},
    {"category": "Mid Jet", "type": "DA-20-F-5"},
    {"category": "Mid Jet", "type": "DA-50 FALCON 50 "},
    {"category": "Mid Jet", "type": "DA-50EX FALCON 50 "},
    {"category": "Mid Jet", "type": "EMB-545"},
    {"category": "Mid Jet", "type": "EMB-545 (PRAETOR 500)"},
    {"category": "Mid Jet", "type": "EMB-550"},
    {"category": "Mid Jet", "type": "EMB-550 (PRAETOR 600)"},
    {"category": "Mid Jet", "type": "G-100"},
    {"category": "Mid Jet", "type": "G-150"},
    {"category": "Mid Jet", "type": "G-200 GULFSTREAM 200 "},
    {"category": "Mid Jet", "type": "G-280 "},
    {"category": "Mid Jet", "type": "HS-125-1000 "},
    {"category": "Mid Jet", "type": "HS-125-1000A"},
    {"category": "Mid Jet", "type": "HS-125-400"},
    {"category": "Mid Jet", "type": "HS-125-4000 HAWKER 4000 "},
    {"category": "Mid Jet", "type": "HS-125-700"},
    {"category": "Mid Jet", "type": "HS-125-700A"},
    {"category": "Mid Jet", "type": "HS-125-700B"},
    {"category": "Mid Jet", "type": "HS-125-750"},
    {"category": "Mid Jet", "type": "HS-125-800"},
    {"category": "Mid Jet", "type": "HS-125-800A"},
    {"category": "Mid Jet", "type": "HS-125-800B"},
    {"category": "Mid Jet", "type": "HS-125-800XP"},
    {"category": "Mid Jet", "type": "HS-125-800XPI"},
    {"category": "Mid Jet", "type": "HS-125-850XP"},
    {"category": "Mid Jet", "type": "HS-125-900XP"},
    {"category": "Mid Jet", "type": "IAI-1125"},
    {"category": "Mid Jet", "type": "IAI-1125 SPX"},
    {"category": "Mid Jet", "type": "IAI-1125-SP"},
    {"category": "Mid Jet", "type": "IAI-1126"},
    {"category": "Mid Jet", "type": "LR-45"},
    {"category": "Mid Jet", "type": "LR-45XR"},
    {"category": "Mid Jet", "type": "LR-55"},
    {"category": "Mid Jet", "type": "LR-60"},
    {"category": "Mid Jet", "type": "LR-60XR"},
    {"category": "Mid Jet", "type": "NA-265-65"},
    {"category": "Piston Multi", "type": "AC-500 AERO COMMANDER"},
    {"category": "Piston Multi", "type": "BE-18 BEECH 18"},
    {"category": "Piston Multi", "type": "BE-18S BEECH SUPER 18"},
    {"category": "Piston Multi", "type": "BE-55 BARON"},
    {"category": "Piston Multi", "type": "BE-58 BARON"},
    {"category": "Piston Multi", "type": "BE-58P BARON"},
    {"category": "Piston Multi", "type": "BE-65 QUEEN AIR"},
    {"category": "Piston Multi", "type": "BE-65-80 QUEEN AIR"},
    {"category": "Piston Multi", "type": "BE-65B-80 QUEEN AIR"},
    {"category": "Piston Multi", "type": "BE-76 DUCHESS"},
    {"category": "Piston Multi", "type": "BE-95-B55 BARON"},
    {"category": "Piston Multi", "type": "BE-B95 TRAVELAIRE"},
    {"category": "Piston Multi", "type": "BE-D55 BARON"},
    {"category": "Piston Multi", "type": "BE-G18S SUPER 18"},
    {"category": "Piston Multi", "type": "BE-G58 BARON"},
    {"category": "Piston Multi", "type": "BN-2 ISLANDER"},
    {"category": "Piston Multi", "type": "BN-2A ISLANDER"},
    {"category": "Piston Multi", "type": "BN-2A-20 ISLANDER"},
    {"category": "Piston Multi", "type": "BN-2A-26 ISLANDER"},
    {"category": "Piston Multi", "type": "BN-2A-27"},
    {"category": "Piston Multi", "type": "BN-2A-MKIII TRI-ISLANDER"},
    {"category": "Piston Multi", "type": "BN-2B ISLANDER"},
    {"category": "Piston Multi", "type": "BN-2B-20 ISLANDER"},
    {"category": "Piston Multi", "type": "BN-2B-26 ISLANDER"},
    {"category": "Piston Multi", "type": "CE-303 CRUSADER"},
    {"category": "Piston Multi", "type": "CE-310"},
    {"category": "Piston Multi", "type": "CE-310Q CESSNA 310"},
    {"category": "Piston Multi", "type": "CE-310R CESSNA 310"},
    {"category": "Piston Multi", "type": "CE-335"},
    {"category": "Piston Multi", "type": "CE-337 SUPER SKYMASTER"},
    {"category": "Piston Multi", "type": "CE-340"},
    {"category": "Piston Multi", "type": "CE-340A"},
    {"category": "Piston Multi", "type": "CE-401"},
    {"category": "Piston Multi", "type": "CE-401B"},
    {"category": "Piston Multi", "type": "CE-402 BUSINESS/UTILINER"},
    {"category": "Piston Multi", "type": "CE-402A BUSINESS/UTILINER"},
    {"category": "Piston Multi", "type": "CE-402B BUSINESS/UTILINER"},
    {"category": "Piston Multi", "type": "CE-402C BUSINESS/UTILINER"},
    {"category": "Piston Multi", "type": "CE-404 TITAN"},
    {"category": "Piston Multi", "type": "CE-414 CHANCELLOR"},
    {"category": "Piston Multi", "type": "CE-414A CHANCELLOR"},
    {"category": "Piston Multi", "type": "CE-421"},
    {"category": "Piston Multi", "type": "CE-421A GOLDEN EAGLE"},
    {"category": "Piston Multi", "type": "CE-421B GOLDEN EAGLE"},
    {"category": "Piston Multi", "type": "CE-421C GOLDEN EAGLE"},
    {"category": "Piston Multi", "type": "CE-T303 CRUSADER"},
    {"category": "Piston Multi", "type": "D-62"},
    {"category": "Piston Multi", "type": "DA42 TWIN STAR"},
    {"category": "Piston Multi", "type": "DA62"},
    {"category": "Piston Multi", "type": "DC-3"},
    {"category": "Piston Multi", "type": "DC-3C"},
    {"category": "Piston Multi", "type": "DC-3R4D SUPER DC-3"},
    {"category": "Piston Multi", "type": "GA-8 AIRVAN"},
    {"category": "Piston Multi", "type": "GAF-N22B NOMAD"},
    {"category": "Piston Multi", "type": "GC-500 AERO COMMANDER"},
    {"category": "Piston Multi", "type": "GC-500B AERO COMMANDER"},
    {"category": "Piston Multi", "type": "GC-500S AERO COMMANDER"},
    {"category": "Piston Multi", "type": "GC-500U AERO COMMANDER"},
    {"category": "Piston Multi", "type": "P2006T"},
    {"category": "Piston Multi", "type": "P2010 TECNAM P2010"},
    {"category": "Piston Multi", "type": "P2012 TECNAM P2012"},
    {"category": "Piston Multi", "type": "P68 OBSERVER"},
    {"category": "Piston Multi", "type": "P68B"},
    {"category": "Piston Multi", "type": "P68C"},
    {"category": "Piston Multi", "type": "P68C-TC VICTOR TC"},
    {"category": "Piston Multi", "type": "PA-23 AZTEC"},
    {"category": "Piston Multi", "type": "PA-23-160 APACHE 160"},
    {"category": "Piston Multi", "type": "PA-23-200 SENECA"},
    {"category": "Piston Multi", "type": "PA-23-250 AZTEC F"},
    {"category": "Piston Multi", "type": "PA-23-250E AZTEC E"},
    {"category": "Piston Multi", "type": "PA-23-350 CHIEFTAN"},
    {"category": "Piston Multi", "type": "PA-31 NAVAJO"},
    {"category": "Piston Multi", "type": "PA-31-300 NAVAJO"},
    {"category": "Piston Multi", "type": "PA-31-310 NAVAJO"},
    {"category": "Piston Multi", "type": "PA-31-325 NAVAJO C/R"},
    {"category": "Piston Multi", "type": "PA-31-350 CHIEFTAIN"},
    {"category": "Piston Multi", "type": "PA-34 SENECA"},
    {"category": "Piston Multi", "type": "PA-34-180 SENECA V"},
    {"category": "Piston Multi", "type": "PA-34-200T SENECA II"},
    {"category": "Piston Multi", "type": "PA-34-220 SENECA"},
    {"category": "Piston Multi", "type": "PA-34-220T SENECA III/IV"},
    {"category": "Piston Multi", "type": "PA-44 SEMINOLE"},
    {"category": "Piston Multi", "type": "PA-60-600 AEROSTAR"},
    {"category": "Piston Multi", "type": "PA-60-601 AEROSTAR"},
    {"category": "Piston Single", "type": "7GCBC CITABRIA EXPLORER "},
    {"category": "Piston Single", "type": "A-1 HUSKY "},
    {"category": "Piston Single", "type": "AA-5A CHEETAH "},
    {"category": "Piston Single", "type": "AR-15-AC 15AC SEDAN "},
    {"category": "Piston Single", "type": "B400 "},
    {"category": "Piston Single", "type": "BE-23 MUSKETEER "},
    {"category": "Piston Single", "type": "BE-36 BONANZA "},
    {"category": "Piston Single", "type": "BE-A36 BONANZA "},
    {"category": "Piston Single", "type": "BE-B36TC TURBO BONANZA "},
    {"category": "Piston Single", "type": "CE-150 "},
    {"category": "Piston Single", "type": "CE-152 "},
    {"category": "Piston Single", "type": "CE-170B "},
    {"category": "Piston Single", "type": "CE-172 SKYHAWK "},
    {"category": "Piston Single", "type": "CE-172E SKYHAWK "},
    {"category": "Piston Single", "type": "CE-172K SKYHAWK "},
    {"category": "Piston Single", "type": "CE-172L SKYHAWK "},
    {"category": "Piston Single", "type": "CE-172M SKYHAWK "},
    {"category": "Piston Single", "type": "CE-172N SKYHAWK 100 "},
    {"category": "Piston Single", "type": "CE-172P SKYHAWK "},
    {"category": "Piston Single", "type": "CE-172R SKYHAWK "},
    {"category": "Piston Single", "type": "CE-172RG CUTLASS RG "},
    {"category": "Piston Single", "type": "CE-172S SKYHAWK "},
    {"category": "Piston Single", "type": "CE-172SP SKYHAWK SP "},
    {"category": "Piston Single", "type": "CE-172XP "},
    {"category": "Piston Single", "type": "CE-177B CARDINAL "},
    {"category": "Piston Single", "type": "CE-180 SKYWAGON "},
    {"category": "Piston Single", "type": "CE-180H SKYWAGON "},
    {"category": "Piston Single", "type": "CE-180J SKYWAGON "},
    {"category": "Piston Single", "type": "CE-180K SKYWAGON "},
    {"category": "Piston Single", "type": "CE-182 SKYLANE "},
    {"category": "Piston Single", "type": "CE-182A SKYLANE "},
    {"category": "Piston Single", "type": "CE-182K SKYLANE "},
    {"category": "Piston Single", "type": "CE-182P SKYLANE "},
    {"category": "Piston Single", "type": "CE-182Q SKYLANE "},
    {"category": "Piston Single", "type": "CE-182R SKYLANE "},
    {"category": "Piston Single", "type": "CE-182RG SKYLANE RG "},
    {"category": "Piston Single", "type": "CE-185 SKYWAGON "},
    {"category": "Piston Single", "type": "CE-185A SKYWAGON "},
    {"category": "Piston Single", "type": "CE-185D SKYWAGON "},
    {"category": "Piston Single", "type": "CE-195 "},
    {"category": "Piston Single", "type": "CE-206 STATIONAIRE "},
    {"category": "Piston Single", "type": "CE-206T STATIONAIRE "},
    {"category": "Piston Single", "type": "CE-207 SKYWAGON "},
    {"category": "Piston Single", "type": "CE-207A STATIONAIRE 8 "},
    {"category": "Piston Single", "type": "CE-210 "},
    {"category": "Piston Single", "type": "CE-210L CENTURION "},
    {"category": "Piston Single", "type": "CE-210M CENTURION "},
    {"category": "Piston Single", "type": "CE-210N CENTURION "},
    {"category": "Piston Single", "type": "CE-P210N CENTURION "},
    {"category": "Piston Single", "type": "CE-T182T SKYLANE "},
    {"category": "Piston Single", "type": "CE-T206H TURBO SKYWAGON "},
    {"category": "Piston Single", "type": "CE-T207 TURBO SKYWAGON "},
    {"category": "Piston Single", "type": "CE-T207A TURBO STATIONAIRE 8 "},
    {"category": "Piston Single", "type": "CE-T210 TURBO CENTURION "},
    {"category": "Piston Single", "type": "CE-T210M TURBO CENTURION "},
    {"category": "Piston Single", "type": "CE-T210N TURBO CENTURION "},
    {"category": "Piston Single", "type": "CE-TU206A TURBO STATIONAIRE "},
    {"category": "Piston Single", "type": "CE-TU206F TURBO STATIONAIRE "},
    {"category": "Piston Single", "type": "CE-TU206G TURBO STATIONAIRE 6 "},
    {"category": "Piston Single", "type": "CE-U206 STATIONAIRE SIX "},
    {"category": "Piston Single", "type": "CE-U206A SKYWAGON 206 "},
    {"category": "Piston Single", "type": "CE-U206F STATIONAIRE 6 "},
    {"category": "Piston Single", "type": "CE-U206G STATIONAIRE 6 "},
    {"category": "Piston Single", "type": "D-40 DIAMOND 40 "},
    {"category": "Piston Single", "type": "D-42 "},
    {"category": "Piston Single", "type": "DA40 NG "},
    {"category": "Piston Single", "type": "DH-82A TIGER MOTH "},
    {"category": "Piston Single", "type": "DHC-2MKI BEAVER "},
    {"category": "Piston Single", "type": "DHC-3 OTTER "},
    {"category": "Piston Single", "type": "DHC-3T TURBO OTTER "},
    {"category": "Piston Single", "type": "EMB-820 SENECA "},
    {"category": "Piston Single", "type": "FBA-2C1 BUSH HAWK "},
    {"category": "Piston Single", "type": "H-295 COURIER "},
    {"category": "Piston Single", "type": "K-100 KODIAK-100 "},
    {"category": "Piston Single", "type": "PA-12 SUPER CRUISER "},
    {"category": "Piston Single", "type": "PA-18 SUPER CUB "},
    {"category": "Piston Single", "type": "PA-18A-150 SUPER CUB "},
    {"category": "Piston Single", "type": "PA-24 COMMANCHE "},
    {"category": "Piston Single", "type": "PA-24-200T SENECA "},
    {"category": "Piston Single", "type": "PA-28 CHEROKEE "},
    {"category": "Piston Single", "type": "PA-28-151 WARRIOR "},
    {"category": "Piston Single", "type": "PA-28-161 "},
    {"category": "Piston Single", "type": "PA-28-180 CHEROKEE 180 "},
    {"category": "Piston Single", "type": "PA-28-181 ARCHER II "},
    {"category": "Piston Single", "type": "PA-28-200 ARROW III "},
    {"category": "Piston Single", "type": "PA-28-200T TURBO ARROW "},
    {"category": "Piston Single", "type": "PA-28-201 ARROW "},
    {"category": "Piston Single", "type": "PA-28-220T SENECA III "},
    {"category": "Piston Single", "type": "PA-28-350 CHIEFTAN "},
    {"category": "Piston Single", "type": "PA-28R ARROW "},
    {"category": "Piston Single", "type": "PA-28R-200 ARROW II "},
    {"category": "Piston Single", "type": "PA-32 CHEROKEE SIX "},
    {"category": "Piston Single", "type": "PA-32-200T SENECA II "},
    {"category": "Piston Single", "type": "PA-32-260 CHEROKEE SIX "},
    {"category": "Piston Single", "type": "PA-32-300 CHEROKEE SIX "},
    {"category": "Piston Single", "type": "PA-32-301 SARATOGA "},
    {"category": "Piston Single", "type": "PA-32-350 CHEYENNE "},
    {"category": "Piston Single", "type": "PA-32R LANCE "},
    {"category": "Piston Single", "type": "PA-32R-300 LANCE "},
    {"category": "Piston Single", "type": "PA-32R-301 SARATOGA SP "},
    {"category": "Piston Single", "type": "PA-32RT-300 LANCE II "},
    {"category": "Piston Single", "type": "PA-38-112 TOMAHAWK II "},
    {"category": "Piston Single", "type": "PA-46 MALIBU "},
    {"category": "Piston Single", "type": "PA-46-350 MALIBU MIRAGE "},
    {"category": "Piston Single", "type": "S-108 "},
    {"category": "Piston Single", "type": "S-10A 10A Voyager"},
    {"category": "Piston Single", "type": "SR-20 SR-20 "},
    {"category": "Piston Single", "type": "SR-22 SR-22 "},
    {"category": "Piston Single", "type": "SR-22T "},
    {"category": "Piston Single", "type": "TB-10 TOBAGO "},
    {"category": "Piston Single", "type": "TB-20 TRINIDAD "},
    {"category": "Piston Single", "type": "TB-9 TAMPICO "},
    {"category": "Turboprop", "type": "AP68TP–600 AVIATOR "},
    {"category": "Turboprop", "type": "BE-100 KING AIR "},
    {"category": "Turboprop", "type": "BE-200 GT "},
    {"category": "Turboprop", "type": "BE-200 SUPER KING AIR "},
    {"category": "Turboprop", "type": "BE-200B KING AIR "},
    {"category": "Turboprop", "type": "BE-200C SUPER KING AIR "},
    {"category": "Turboprop", "type": "BE-250 KING AIR 250 "},
    {"category": "Turboprop", "type": "BE-260 KING AIR 260 "},
    {"category": "Turboprop", "type": "BE-300 SUPER KING AIR 300 "},
    {"category": "Turboprop", "type": "BE-300B SUPER KING AIR "},
    {"category": "Turboprop", "type": "BE-300LW SUPER KING AIR "},
    {"category": "Turboprop", "type": "BE-350 SUPER KING AIR "},
    {"category": "Turboprop", "type": "BE-350I KING AIR "},
    {"category": "Turboprop", "type": "BE-90 GT "},
    {"category": "Turboprop", "type": "BE-90 KING AIR "},
    {"category": "Turboprop", "type": "BE-90C KING AIR "},
    {"category": "Turboprop", "type": "BE-90F KING AIR "},
    {"category": "Turboprop", "type": "BE-A100 KING AIR "},
    {"category": "Turboprop", "type": "BE-A200 KING AIR "},
    {"category": "Turboprop", "type": "BE-A90 KING AIR "},
    {"category": "Turboprop", "type": "BE-B100 KING AIR "},
    {"category": "Turboprop", "type": "BE-B200 SUPER KING AIR "},
    {"category": "Turboprop", "type": "BE-C90 KING AIR "},
    {"category": "Turboprop", "type": "BE-C90A KING AIR C90 "},
    {"category": "Turboprop", "type": "BE-C90B KING AIR C90 "},
    {"category": "Turboprop", "type": "BE-C90GT KING AIR GT "},
    {"category": "Turboprop", "type": "BE-C90GTI KINGAIR C90GTI "},
    {"category": "Turboprop", "type": "BE-C90GTX KINGAIR C90GTX "},
    {"category": "Turboprop", "type": "BE-E90 KING AIR "},
    {"category": "Turboprop", "type": "BE-F90 SUPER KING AIR "},
    {"category": "Turboprop", "type": "BN-2A-6 ISLANDER "},
    {"category": "Turboprop", "type": "BN-2T TURBINE ISLANDER "},
    {"category": "Turboprop", "type": "C-212-CC AVIOCAR "},
    {"category": "Turboprop", "type": "CE-208 CARAVAN I "},
    {"category": "Turboprop", "type": "CE-208 EX GRAND CARAVAN "},
    {"category": "Turboprop", "type": "CE-208 SEAPLANE CARAVAN AMPHIBIOUS "},
    {"category": "Turboprop", "type": "CE-208A CARAVAN "},
    {"category": "Turboprop", "type": "CE-208B EX CARAVAN "},
    {"category": "Turboprop", "type": "CE-208B GRAND CARAVAN "},
    {"category": "Turboprop", "type": "CE-208C GRAND CARAVAN "},
    {"category": "Turboprop", "type": "CE-406 CARAVAN II "},
    {"category": "Turboprop", "type": "CE-408 GRAND CARAVAN "},
    {"category": "Turboprop", "type": "CE-425 CONQUEST I "},
    {"category": "Turboprop", "type": "CE-441 CONQUEST II "},
    {"category": "Turboprop", "type": "DHC-2MKIII TURBO BEAVER "},
    {"category": "Turboprop", "type": "EMB-121 XINGU "},
    {"category": "Turboprop", "type": "EMB-821 CARAJA "},
    {"category": "Turboprop", "type": "GA-681B TURBO COMMANDER "},
    {"category": "Turboprop", "type": "GC-690A TURBO COMMANDER "},
    {"category": "Turboprop", "type": "GC-690B TURBO COMMANDER "},
    {"category": "Turboprop", "type": "GC-690C TURBO COMMANDER "},
    {"category": "Turboprop", "type": "GC-690D TURBO COMMANDER "},
    {"category": "Turboprop", "type": "GC-980 COMMANDER JETPROP "},
    {"category": "Turboprop", "type": "LC41-550FG COLUMBIA 400 "},
    {"category": "Turboprop", "type": "LET-410 "},
    {"category": "Turboprop", "type": "LET-410 UVPE "},
    {"category": "Turboprop", "type": "MU-2 "},
    {"category": "Turboprop", "type": "MU-2B-36 "},
    {"category": "Turboprop", "type": "MU-2B-36A "},
    {"category": "Turboprop", "type": "MU-2B-40 Solitaire "},
    {"category": "Turboprop", "type": "MU-2B-60 MARQUIS "},
    {"category": "Turboprop", "type": "P-180 {EVO} "},
    {"category": "Turboprop", "type": "P-180 AVANTI "},
    {"category": "Turboprop", "type": "P-180A AVANTI II "},
    {"category": "Turboprop", "type": "PA-31-T "},
    {"category": "Turboprop", "type": "PA-31-T1 CHEYENNE "},
    {"category": "Turboprop", "type": "PA-31T CHEYENNE II "},
    {"category": "Turboprop", "type": "PA-31T-1 CHEYENNE I "},
    {"category": "Turboprop", "type": "PA-31T-2 CHEYENNE IIXL "},
    {"category": "Turboprop", "type": "PA-31T-620 CHEYENNE II "},
    {"category": "Turboprop", "type": "PA-31T2 CHEYENNE IIXL "},
    {"category": "Turboprop", "type": "PA-31T3-T1040 T-1040 "},
    {"category": "Turboprop", "type": "PA-42 CHEYENNE III "},
    {"category": "Turboprop", "type": "PA-42-1000 CHEYENNE 400LS "},
    {"category": "Turboprop", "type": "PA-42-720 CHEYENNE IIIA "},
    {"category": "Turboprop", "type": "PA-46-500TP MALIBU MERIDIAN "},
    {"category": "Turboprop", "type": "PAC 750 XL XSTOL "},
    {"category": "Turboprop", "type": "PC 12NGX PC 12NGX "},
    {"category": "Turboprop", "type": "PC 12NGX PC-12NGX "},
    {"category": "Turboprop", "type": "PC-12 PILATUS "},
    {"category": "Turboprop", "type": "PC-12-45 PILATUS "},
    {"category": "Turboprop", "type": "PC-12-47 PILATUS "},
    {"category": "Turboprop", "type": "PC-12-47E PILATUS "},
    {"category": "Turboprop", "type": "PC-12NG "},
    {"category": "Turboprop", "type": "PC-12NGX PC-12NGX "},
    {"category": "Turboprop", "type": "PC-6 PORTER "},
    {"category": "Turboprop", "type": "SA-226 "},
    {"category": "Turboprop", "type": "SA227-TT "},
    {"category": "Turboprop", "type": "SW-226 MERLIN III "},
    {"category": "Turboprop", "type": "SW-226AT "},
    {"category": "Turboprop", "type": "SW-226T "},
    {"category": "Turboprop", "type": "SW-226TB MERLIN IIIB "},
    {"category": "Turboprop", "type": "SW-227AT MERLIN IVC-41 "},
    {"category": "Turboprop", "type": "SW-26AT MERLIN IIB "},
    {"category": "Turboprop", "type": "TBM-700 "},
    {"category": "Turboprop", "type": "TBM-850 "},
    {"category": "Turboprop", "type": "TBM-900 TBM-900 "},
    {"category": "Turboprop Airliner", "type": "AN-12B "},
    {"category": "Turboprop Airliner", "type": "AN-12BP "},
    {"category": "Turboprop Airliner", "type": "AN-24 "},
    {"category": "Turboprop Airliner", "type": "AN-24B "},
    {"category": "Turboprop Airliner", "type": "AN-24RV "},
    {"category": "Turboprop Airliner", "type": "AN-26 "},
    {"category": "Turboprop Airliner", "type": "AN-26-100 "},
    {"category": "Turboprop Airliner", "type": "AN-26B "},
    {"category": "Turboprop Airliner", "type": "AS-ATR42-300 "},
    {"category": "Turboprop Airliner", "type": "AS-ATR42-320 "},
    {"category": "Turboprop Airliner", "type": "AS-ATR42-500 "},
    {"category": "Turboprop Airliner", "type": "AS-ATR42-600 "},
    {"category": "Turboprop Airliner", "type": "AS-ATR72 "},
    {"category": "Turboprop Airliner", "type": "AS-ATR72-200 "},
    {"category": "Turboprop Airliner", "type": "AS-ATR72-202 "},
    {"category": "Turboprop Airliner", "type": "AS-ATR72-212 "},
    {"category": "Turboprop Airliner", "type": "AS-ATR72-500 "},
    {"category": "Turboprop Airliner", "type": "AS-ATR72-600 "},
    {"category": "Turboprop Airliner", "type": "BAE-3100 JETSTREAM 31 "},
    {"category": "Turboprop Airliner", "type": "BAE-3101 JETSTREAM "},
    {"category": "Turboprop Airliner", "type": "BAE-3200 JETSTREAM SUPER "},
    {"category": "Turboprop Airliner", "type": "BAE-3212 JETSTREAM 32 "},
    {"category": "Turboprop Airliner", "type": "BAE-32EP JETSTREAM SUPER "},
    {"category": "Turboprop Airliner", "type": "BAE-4100 JETSTREAM 41 "},
    {"category": "Turboprop Airliner", "type": "BAE-748-2A "},
    {"category": "Turboprop Airliner", "type": "BAE-ATR-42 "},
    {"category": "Turboprop Airliner", "type": "BE-1900 COMMUTER "},
    {"category": "Turboprop Airliner", "type": "BE-1900C COMMUTER "},
    {"category": "Turboprop Airliner", "type": "BE-1900C. EXECULINER "},
    {"category": "Turboprop Airliner", "type": "BE-1900D COMMUTER "},
    {"category": "Turboprop Airliner", "type": "BE-99 COMMUTER "},
    {"category": "Turboprop Airliner", "type": "BE-C99 AIRLINER "},
    {"category": "Turboprop Airliner", "type": "CA-212-200 AVIOCAR "},
    {"category": "Turboprop Airliner", "type": "CV-340 CONVAIR LINER "},
    {"category": "Turboprop Airliner", "type": "CV-580 CONVAIR LINER "},
    {"category": "Turboprop Airliner", "type": "DC-6 "},
    {"category": "Turboprop Airliner", "type": "DHC-6 TWIN OTTER "},
    {"category": "Turboprop Airliner", "type": "DHC-6-100 TWIN OTTER "},
    {"category": "Turboprop Airliner", "type": "DHC-6-200 TWIN OTTER "},
    {"category": "Turboprop Airliner", "type": "DHC-6-300 TWIN OTTER "},
    {"category": "Turboprop Airliner", "type": "DHC-6-310 TWIN OTTER "},
    {"category": "Turboprop Airliner", "type": "DHC-6-400 TWIN OTTER "},
    {"category": "Turboprop Airliner", "type": "DHC-7 DASH 7 "},
    {"category": "Turboprop Airliner", "type": "DHC-8 DASH 8 "},
    {"category": "Turboprop Airliner", "type": "DHC-8-100 DASH 8 "},
    {"category": "Turboprop Airliner", "type": "DHC-8-200Q DASH 8 200Q "},
    {"category": "Turboprop Airliner", "type": "DHC-8-300 DASH 8-300 "},
    {"category": "Turboprop Airliner", "type": "DHC-8-300Q DASH 8 300Q "},
    {"category": "Turboprop Airliner", "type": "DHC-8-400 DASH 8-400 "},
    {"category": "Turboprop Airliner", "type": "DHC-8-400Q DASH 8-Q400 "},
    {"category": "Turboprop Airliner", "type": "DHC-8-Q402 DASH Q402 "},
    {"category": "Turboprop Airliner", "type": "DO-228 "},
    {"category": "Turboprop Airliner", "type": "DO-228-100 "},
    {"category": "Turboprop Airliner", "type": "DO-228-101 "},
    {"category": "Turboprop Airliner", "type": "DO-228-200 "},
    {"category": "Turboprop Airliner", "type": "DO-228-202 "},
    {"category": "Turboprop Airliner", "type": "DO-228-202K "},
    {"category": "Turboprop Airliner", "type": "DO-228-212 "},
    {"category": "Turboprop Airliner", "type": "DO-328 "},
    {"category": "Turboprop Airliner", "type": "DO-328-100 "},
    {"category": "Turboprop Airliner", "type": "DO-328-300 "},
    {"category": "Turboprop Airliner", "type": "EMB-110 BANDEIRANTE "},
    {"category": "Turboprop Airliner", "type": "EMB-110P2 BANDEIRANTE "},
    {"category": "Turboprop Airliner", "type": "EMB-110PI BANDEIRANTE "},
    {"category": "Turboprop Airliner", "type": "EMB-120 BRASILIA "},
    {"category": "Turboprop Airliner", "type": "EMB-120ER BRASILIA "},
    {"category": "Turboprop Airliner", "type": "FK-50 "},
    {"category": "Turboprop Airliner", "type": "FK-F27 FRIENDSHIP "},
    {"category": "Turboprop Airliner", "type": "L-100-30 HERCULES "},
    {"category": "Turboprop Airliner", "type": "M-7-420A "},
    {"category": "Turboprop Airliner", "type": "SC7 SKYVAN "},
    {"category": "Turboprop Airliner", "type": "SD3-30 SHERPA "},
    {"category": "Turboprop Airliner", "type": "SD3-60 "},
    {"category": "Turboprop Airliner", "type": "SF-2000 COMMUTER "},
    {"category": "Turboprop Airliner", "type": "SF-340 "},
    {"category": "Turboprop Airliner", "type": "SF-340A COMMUTER "},
    {"category": "Turboprop Airliner", "type": "SF-340B COMMUTER "},
    {"category": "Turboprop Airliner", "type": "SW-226AC "},
    {"category": "Turboprop Airliner", "type": "SW-226TC METRO IIA "},
    {"category": "Turboprop Airliner", "type": "SW-226TC. METRO II "},
    {"category": "Turboprop Airliner", "type": "SW-226TC.. METRO "},
    {"category": "Turboprop Airliner", "type": "SW-227 METRO "},
    {"category": "Turboprop Airliner", "type": "SW-227AC METRO III/EXPEDITOR "},
    {"category": "Turboprop Airliner", "type": "SW-227AC. METRO III "},
    {"category": "Turboprop Airliner", "type": "SW-227BC METRO "},
    {"category": "Turboprop Airliner", "type": "SW-227DC "},
    {"category": "Turboprop Airliner", "type": "SW-23 METRO 23 "},
    {"category": "Very LIght Jet", "type": "CE-510 MUSTANG "},
    {"category": "Very LIght Jet", "type": "EA-500 ECLIPSE "},
    {"category": "Very LIght Jet", "type": "EA-550 ECLIPSE 550 "},
    {"category": "Very LIght Jet", "type": "EMB-500 PHENOM 100 "},
    {"category": "Very LIght Jet", "type": "SR-SF50 VISION JET "}    
    // Add more pairs as needed
  ];

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    setType('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const aircraftData = {
      operator,
      category,
      type,
      passengers: parseInt(passengers, 10),
      year: parseInt(year, 10),
      homeBase,
    };
    //console.log('Aircraft Data:', aircraftData);

    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_URL}/api/addAircraft`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(aircraftData),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error('Failed to add aircraft: ' + error.message);
      }

      const responseData = await response.json();
      navigate('/my-fleet');
    } catch (error) {
      //console.error('Error adding aircraft:', error);
    }
  };

  return (
    <div className="dashboard-container">
      <DashboardSidebar />
      <section className="dashboard-content">
        <div className="listing-container">
          <h1>Add Aircraft</h1>
          <form onSubmit={handleSubmit} className="listing-form">
            <div className="form-group">
              <label htmlFor="operator">Operator Name</label>
              <input
                type="text"
                id="operator"
                value={operator}
                onChange={(e) => setOperator(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="category">Category</label>
              <select
                id="category"
                value={category}
                onChange={handleCategoryChange}
                required
              >
                <option value="">Select Category</option>
                {Array.from(new Set(aircraftData.map(item => item.category))).map((category, index) => (
                  <option key={index} value={category}>{category}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="type">Aircraft Type</label>
              <select
                id="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                required
              >
                <option value="">Select Type</option>
                {aircraftData.filter(item => item.category === category).map((item, index) => (
                  <option key={index} value={item.type}>{item.type}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="passengers">Passengers</label>
              <input
                type="number"
                id="passengers"
                value={passengers}
                onChange={(e) => setPassengers(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="year">Aircraft Year</label>
              <input
                type="number"
                id="year"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="homeBase">Home Base</label>
              <input
                type="text"
                id="homeBase"
                value={homeBase}
                onChange={(e) => setHomeBase(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="listing-submit">Add Aircraft</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AddAircraft;