import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './article.css';
import { API_URL } from '../../config';

const Article = () => {
  const { id } = useParams(); // Access route parameter id
  const [article, setArticle] = useState({});

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(`${API_URL}/api/articles/${id}`);

        if (!response.ok) {
          throw new Error('Failed to fetch article');
        }
        const data = await response.json();
        // Format date to MM/DD/YYYY
        data.date = new Date(data.date).toLocaleDateString();
        setArticle(data);
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };

    fetchArticle();
  }, [id]); // Fetch article whenever id changes

  return (
    <div className="article">
      <h1>{article.title}</h1>
      <p>{article.body}</p>
      <p>{article.date}</p>
      <p>Tags: {article.tags}</p>
    </div>
  );
};

export default Article;
