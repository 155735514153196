import React from 'react';
import { Navigate } from 'react-router-dom'; // Use Navigate for react-router-dom v6
import DashboardSidebar from './DashboardSidebar';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth from your AuthContext
import './myDashboard.css';

const MyDashboard = () => {
  const { isLoggedIn } = useAuth(); // Access the isLoggedIn state using useAuth

  // Redirect to the login page if the user is not logged in
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // Render the dashboard if the user is logged in
  return (
    <div className="dashboard-container">
      <DashboardSidebar />
      <section className="dashboard-content">
        <h1>Welcome to Your Dashboard</h1>
        <p>Select an option from the menu to get started.</p>
      </section>
    </div>
  );
};

export default MyDashboard;
