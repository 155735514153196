import React, { useState } from 'react';
import './faq.css'; // Ensure the presence of this CSS file in the same directory
import { Helmet } from 'react-helmet';


const FAQ = () => {
  const [expanded, setExpanded] = useState({});
  
  // Function to toggle answer visibility
  const toggleAnswer = (index) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="FAQ">
        <Helmet>
        <title>FAQs | Charter God - Your Guide to Air Charter Services</title>
        <meta name="description" content="Find answers to frequently asked questions about Charter God, the marketplace connecting brokers, operators, and clients for air charter services worldwide." />
        <meta name="keywords" content="Charter God FAQ, air charter services, private jet bookings, charter flight FAQs, charter service questions" />
        <link rel="canonical" href="https://www.chartergod.com/faq" />
      </Helmet>
      <h1>Frequently Asked Questions</h1>
      <div className="questions">
        {/* Mapping through FAQ data */}
        {faqData.map((item, index) => (
          <div key={index} className="question">
            {/* Question */}
            <h3 onClick={() => toggleAnswer(index)}>
              {item.question}{' '}
              {/* Arrow up/down icon based on expansion state */}
              {expanded[index] ? (
                <span>&#9650;</span>
              ) : (
                <span>&#9660;</span>
              )}
            </h3>
            {/* Answer displayed if expanded */}
            {expanded[index] && (
              <div
                className="answer"
                dangerouslySetInnerHTML={{ __html: item.answer.replace(/contact form/g, '<a href="/contact">contact form</a>') }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

// Data for FAQ items
const faqData = [
  {
    question: 'How Does Charter God Work?',
    answer: `Charter God facilitates the connection between aviation professionals and clients seeking charter services. Users can submit and interact with charter "Needs" and "Haves" tailored to their specific requirements and preferences.`,
  },
  {
    question: 'Is Charter God Free to Use?',
    answer: `Charter God provides complimentary access to browse and view both charter "Haves" and "Needs." Messaging regarding "Haves" is also provided at no cost. Posting charter "Needs" is free of charge. However, to engage in messaging regarding charter "Needs," users are required to upgrade to a membership tier. Similarly, to post charter "Haves," users must also upgrade to a membership tier.<br/><br/>For more details on membership tiers and associated benefits, please visit our <a href="/pricing">Pricing page</a>.`,
  },
  {
    question: 'What is a Need?',
    answer: `A "Need" refers to a specific request for air charter services, typically initiated by a broker or a direct client. These requests are tailored to individual travel requirements and preferences.`,
  },
  {
    question: 'What is a Have?',
    answer: `A "Have" typically refers to available charter flights, often termed as "Empty Legs" or transient aircraft. These flights offer flexibility in departure and arrival times and are often available at discounted rates.`,
  },
  {
    question: 'What is an Empty Leg?',
    answer: `An "Empty Leg" signifies a one-way private jet charter flight where the aircraft must return to its base or another location without passengers. These flights present an opportunity for travelers to access private jet travel at reduced rates due to the aircraft's positioning needs.`,
  },
  {
    question: 'How do I cancel my membership?',
    answer: `Users can cancel their membership by submitting a request via the <a href="/contact">contact form</a> provided on our website.`,
  },
  {
    question: 'What is the refund policy?',
    answer: `Users can request a refund within 24 hours of a payment or recurring billing charge by submitting a request via the <a href="/contact">contact form</a> on our website.`,
  }
  // Add more FAQ items as necessary
];

export default FAQ;
