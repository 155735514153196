import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import './NavBar.css';
import { useAuth } from '../contexts/AuthContext';
import jetIcon from './chartergodlogo.png';

const NavBar = () => {
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = () => {
    logout();
    setExpanded(false);
    navigate('/');
  };

  const handleNavLinkClick = () => setExpanded(false);

  // Determine if the current page is the landing page
  const isLandingPage = location.pathname === '/landing-page';
  const isPricingPage = location.pathname === '/pricing';

  return (
    <>
      <Navbar expand="lg" className="navbar" expanded={expanded}>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="mobile-menu-button"
          onClick={() => setExpanded(expanded ? false : 'expanded')}
        />
        <Navbar.Brand as={Link} to="/" className="center-logo" onClick={handleNavLinkClick}>
          <img src={jetIcon} alt="Charter God" className="nav-icon" />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          {!isLandingPage && !isPricingPage && (
            <>
              {isMobile && isLoggedIn && (
                <Nav className="mr-auto">
                  {/* Render your mobile-specific links here */}
                </Nav>
              )}
              <Nav className="mr-auto">
                <Nav.Link as={Link} to="/" className="nav-item" onClick={handleNavLinkClick}>Air Charter Listings</Nav.Link>  
                <Nav.Link as={Link} to="/pricing" className="nav-item" onClick={handleNavLinkClick}>Pricing</Nav.Link>
                
              </Nav>
              <Nav>
                {isLoggedIn ? (
                  <>
                    <Nav.Link as={Link} to="/dashboard" className="nav-item" onClick={handleNavLinkClick}>My Account</Nav.Link>
                    <Button onClick={handleLogout} className="button-logout">Logout</Button>
                  </>
                ) : (
                  <>
                    <Nav.Link as={Link} to="/register" className="nav-item" onClick={handleNavLinkClick}>Register</Nav.Link>
                    <Nav.Link as={Link} to="/login" className="nav-item" onClick={handleNavLinkClick}>Login</Nav.Link>
                  </>
                )}
              </Nav>
            </>
          )}
        </Navbar.Collapse>
      </Navbar>
      <div className="banner-container">
      </div>
    </>
  );
};

export default NavBar;
