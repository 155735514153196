import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LandingPage.css';
import goldCheck from './gold_check.png';
import { useAuth } from '../contexts/AuthContext';
import { API_URL } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlaneDeparture, faSortUp, faSortDown, faPlane, faArrowLeft, faArrowRight, faFilter, faCaretUp, faCaretDown, faBullhorn, faListAlt, faEye, faHandshake, faUsers, faBars, faGlobe, faShieldAlt, faChartLine, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import jetIcon from './chartergodlogo.png';
import bannerImage from './banner-image.webp';

const testimonials = [
  {
    quote: "At first I thought it was expensive, but I've already booked 4 charters in 1 week directly with the passengers. Well worth the small $500/month fee.",
    name: "Bill N, Director of Operations",
  },
  {
    quote: "Charter God allows me to post and go directly to the operator. No broker markup.",
    name: "Sarah W, Executive Personal Assistant",
  },
  {
    quote: "My principal is constantly making me go find cheaper charters. I am too busy. Post once on Charter God and I go direct to operators!",
    name: "Matt N, Executive Personal Assistant",
  },
  {
    quote: "Worked out a few kickbacks with the operator directly.",
    name: "Emily J, Director of Travel",
  },
];

const LandingPage = () => {
  const [userEmail, setUserEmail] = useState('');
  const { isLoggedIn } = useAuth();
  const [charters, setCharters] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterDate, setFilterDate] = useState(null);
  const [filterFrom, setFilterFrom] = useState('');
  const [filterTo, setFilterTo] = useState('');
  const [filterAircraftSize, setFilterAircraftSize] = useState('');
  const [filterPassengers, setFilterPassengers] = useState('');
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [chartersPerPage] = useState(50);
  const [loading, setLoading] = useState(true);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const userPlan = localStorage.getItem('plan');
  const [userId, setUserId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const counters = document.querySelectorAll('.counter');
    counters.forEach(counter => {
      const updateCount = () => {
        const target = +counter.getAttribute('data-target');
        const count = +counter.innerText;
        const increment = target / 200;

        if (count < target) {
          counter.innerText = Math.ceil(count + increment);
          setTimeout(updateCount, 1);
        } else {
          counter.innerText = target;
        }
      };

      updateCount();
    });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterTypeParam = params.get('filterType');
    const filterFromParam = params.get('from');
    const filterAircraftSizeParam = params.get('aircraft_size');

    if (filterTypeParam) setFilterType(filterTypeParam);
    if (filterFromParam) setFilterFrom(filterFromParam);
    if (filterAircraftSizeParam) setFilterAircraftSize(filterAircraftSizeParam);

    fetchCharters();
  }, [location.search]);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    setUserId(String(storedUserId));
  }, []);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const CountdownTimer = () => {
    const [timeLeft, setTimeLeft] = useState({
      hours: 3,
      minutes: 29,
      seconds: 15,
    });

    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          let { hours, minutes, seconds } = prevTime;

          if (seconds > 0) {
            seconds -= 1;
          } else if (minutes > 0) {
            minutes -= 1;
            seconds = 59;
          } else if (hours > 0) {
            hours -= 1;
            minutes = 59;
            seconds = 59;
          }

          return { hours, minutes, seconds };
        });
      }, 1000);

      return () => clearInterval(timer);
    }, []);

    return (
      <div className="countdown-timer">
        <span>{formatTime(timeLeft.hours)}:</span>
        <span>{formatTime(timeLeft.minutes)}:</span>
        <span>{formatTime(timeLeft.seconds)}</span>
      </div>
    );
  };

  useEffect(() => {
    fetchCharters();
  }, [userId]);

  const formatDate = (dateString) => {
    if (!dateString || dateString.split('-').length !== 3) {
      return 'Invalid date';
    }

    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const parts = dateString.split('-');
    const year = parts[0];
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);

    if (month < 1 || month > 12 || day < 1 || day > 31) {
      return 'Invalid date';
    }

    const formattedDate = `${months[month - 1]} ${day < 10 ? '0' + day : day}, ${year}`;
    return formattedDate;
  };

  const removeFilter = (filterName) => {
    switch (filterName) {
      case 'type':
        setFilterType('');
        break;
      case 'date':
        setFilterDate(null);
        break;
      case 'from':
        setFilterFrom('');
        break;
      case 'to':
        setFilterTo('');
        break;
      case 'aircraft_size':
        setFilterAircraftSize('');
        break;
      case 'passengers':
        setFilterPassengers('');
        break;
      default:
        break;
    }
  };

  const fetchCharters = () => {
    fetch(`${API_URL}/api/charters`)
      .then((response) => response.json())
      .then((data) => {
        setCharters(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const resetFilters = () => {
    setFilterType('');
    setFilterDate(null);
    setFilterFrom('');
    setFilterTo('');
    setFilterAircraftSize('');
    setFilterPassengers('');
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const sortedCharters = sortBy ? [...charters].sort((a, b) => {
    const aValue = a[sortBy];
    const bValue = b[sortBy];

    if (sortBy === 'charter_date_start') {
      return sortOrder === 'asc' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
    }

    if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;

    return 0;
  }) : charters;

  const filteredCharters = sortedCharters.filter((charter) => {
    if (filterType && charter.have_need && charter.have_need.toLowerCase() !== filterType.toLowerCase()) {
      return false;
    }

    if (filterDate && charter.charter_date_start && charter.charter_date_end) {
      const filterDateTime = new Date(filterDate).getTime();
      const startDate = new Date(charter.charter_date_start).getTime();
      const endDate = new Date(charter.charter_date_end).getTime();
      const startDateMinusOneDay = startDate - (24 * 60 * 60 * 1000);
      const endDatePlusOneDay = endDate + (24 * 60 * 60 * 1000);
      if (!(filterDateTime >= startDateMinusOneDay && filterDateTime <= endDatePlusOneDay)) {
        return false;
      }
    }

    if (filterFrom && charter.from && !charter.from.toLowerCase().includes(filterFrom.toLowerCase())) {
      return false;
    }

    if (filterTo && charter.to && !charter.to.toLowerCase().includes(filterTo.toLowerCase())) {
      return false;
    }

    if (filterAircraftSize && charter.aircraft_size && !charter.aircraft_size.toLowerCase().includes(filterAircraftSize.toLowerCase())) {
      return false;
    }

    if (filterPassengers && charter.passengers && parseInt(charter.passengers) > parseInt(filterPassengers)) {
      return false;
    }

    return true;
  });

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastCharter = currentPage * chartersPerPage;
  const indexOfFirstCharter = indexOfLastCharter - chartersPerPage;
  const currentCharters = filteredCharters.slice(indexOfFirstCharter, indexOfLastCharter);

  const totalPages = Math.ceil(filteredCharters.length / chartersPerPage);
  const visiblePages = 4;
  const maxPages = Math.min(visiblePages, totalPages);
  const maxVisiblePages = currentPage + maxPages - 1;

  const toggleRowExpansion = (charterId) => {
    setExpandedRows(prevState => {
      if (prevState.includes(charterId)) {
        return prevState.filter(id => id !== charterId);
      } else {
        return [...prevState, charterId];
      }
    });
  };

  const renderFilterTags = () => {
    const filterTags = [];

    if (filterType) {
      filterTags.push(<span className="filter-tag">{filterType} <button onClick={() => removeFilter('type')}>x</button></span>);
    }
    if (filterDate) {
      filterTags.push(<span className="filter-tag">{filterDate.toISOString().split('T')[0]} <button onClick={() => removeFilter('date')}>x</button></span>);
    }
    if (filterFrom) {
      filterTags.push(<span className="filter-tag">{filterFrom} <button onClick={() => removeFilter('from')}>x</button></span>);
    }
    if (filterTo) {
      filterTags.push(<span className="filter-tag">{filterTo} <button onClick={() => removeFilter('to')}>x</button></span>);
    }
    if (filterAircraftSize) {
      filterTags.push(<span className="filter-tag">{filterAircraftSize} <button onClick={() => removeFilter('aircraft_size')}>x</button></span>);
    }
    if (filterPassengers) {
      filterTags.push(<span className="filter-tag">{filterPassengers} <button onClick={() => removeFilter('passengers')}>x</button></span>);
    }

    return <div className="filter-tags">{filterTags}</div>;
  };

  return (
    <div className="home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Charter God - Air Charter Marketplace for Brokers and Operators</title>
        <meta name="description" content="Charter God is the premier air charter marketplace, connecting brokers and operators for seamless private jet charters. Find the perfect charter tailored to your needs, whether you're a broker or an operator." />
        <meta name="keywords" content="Charter God, air charter marketplace, private jet charters, brokers, operators, charter flights, charter planes, aircraft charter" />
        <link rel="canonical" href="https://www.chartergod.com/" />
      </Helmet>

      <header className="hero-section">
        <div className="hero-content text-center">
          <h1>Increase your Charter Sales with Charter God</h1>
          <div className="row hero-text-blocks">
            <div className="col-md-4 benefit-box">
              <div className="benefit-content">
                <FontAwesomeIcon icon={faBullhorn} size="2x" />
                <h3>Sell More Charters</h3>
                <p>Connect with Passengers, Small Brokers & Executive Assistants Directly</p>
              </div>
            </div>
            <div className="col-md-4 benefit-box">
              <div className="benefit-content">
                <FontAwesomeIcon icon={faListAlt} size="2x" />
                <h3>More Listings</h3>
                <p>We don't charge a fee to post listings, thereby getting more opportunities than other platforms</p>
              </div>
            </div>
            <div className="col-md-4 benefit-box">
              <div className="benefit-content">
                <FontAwesomeIcon icon={faEye} size="2x" />
                <h3>Visibility</h3>
                <p>Post Have's and increase your visibility</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row counter-container">
            <div className="col-md-4 counter-box">
              <FontAwesomeIcon icon={faPlane} size="2x" />
              <h2 className="counter" data-target="2011">0</h2>
              <p># of Operators</p>
            </div>
            <div className="col-md-4 counter-box">
              <FontAwesomeIcon icon={faHandshake} size="2x" />
              <h2 className="counter" data-target="159">0</h2>
              <p># of Brokers</p>
            </div>
            <div className="col-md-4 counter-box">
              <FontAwesomeIcon icon={faPlaneDeparture} size="2x" />
              <h2 className="counter" data-target="231">0</h2>
              <p># of Direct Passengers</p>
            </div>
          </div>
        </div>
      </header>

      <section className="featured-charters-section">
        <div className="container text-center">
          <h2>Featured Charters</h2>
          <div className="row">
            {currentCharters.slice(0, 10).map((listing) => (
              <div className="col-md-6 listing-box" key={listing.id}>
                <div className="listing-content">
                  <h3>{listing.from} to {listing.to}</h3>
                  <p>Date: {formatDate(listing.charter_date_start)} - {formatDate(listing.charter_date_end)}</p>
                  <p>Aircraft: {listing.aircraft_size}</p>
                  <p>Passengers: {listing.passengers}</p>
                  <button className="btn btn-primary" onClick={() => navigate(`/pricing`)}>Sell this Charter NOW</button>
                </div>
              </div>
            ))}
          </div>
          <a href="/" className="btn btn-primary btn-lg">Explore More Listings</a>
        </div>
      </section>

      <section className="features-section">
        <div className="container text-center">
          <h2>Benefits</h2>
          <div className="row justify-content-center">
            <div className="col-md-3 benefit-box">
              <div className="benefit-content">
                <FontAwesomeIcon icon={faUsers} size="2x" />
                <h3>Direct Access to Clients</h3>
                <p>Connect directly with Executive Assistants and passengers, cutting out the middleman and saving on fees.</p>
              </div>
            </div>
            <div className="col-md-3 benefit-box">
              <div className="benefit-content">
                <FontAwesomeIcon icon={faBars} size="2x" />
                <h3>Reach Smaller Brokers</h3>
                <p>Access smaller brokers who can't afford expensive memberships with other platforms like Charter Pad or Avinode.</p>
              </div>
            </div>
            <div className="w-100"></div> {/* This forces a new row */}
            <div className="col-md-3 benefit-box">
              <div className="benefit-content">
                <FontAwesomeIcon icon={faGlobe} size="2x" />
                <h3>Expand Internationally</h3>
                <p>Reach international brokers and operators who are outside the US and may not have NBAA memberships.</p>
              </div>
            </div>
            <div className="col-md-3 benefit-box">
              <div className="benefit-content">
                <FontAwesomeIcon icon={faShieldAlt} size="2x" />
                <h3>Lower Barrier to Entry</h3>
                <p>Our platform offers a lower barrier to entry for posting, leading to more listings and a wider client base.</p>
              </div>
            </div>
            <div className="col-md-3 benefit-box">
              <div className="benefit-content">
                <FontAwesomeIcon icon={faChartLine} size="2x" />
                <h3>Increase Visibility</h3>
                <p>Advertise your empty legs and maximize your revenue with greater visibility.</p>
              </div>
            </div>
            <div className="col-md-3 benefit-box">
              <div className="benefit-content">
                <FontAwesomeIcon icon={faDollarSign} size="2x" />
                <h3>Free Posting for Brokers</h3>
                <p>More listings lead to better choices for clients and operators alike.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonials-section">
        <div className="container text-center">
          <h2>Testimonials</h2>
          {testimonials.map((testimonial, index) => (
            <blockquote key={index}>
              <p>"{testimonial.quote}"</p>
              <footer>{testimonial.name}</footer>
            </blockquote>
          ))}
        </div>
      </section>

      <section className="pricing-section">
        <div className="container text-center">
          <h2>Pricing</h2>
          <p>Limited time offer. 70% off Yearly. 50% off Monthly Memberships.</p>
          <CountdownTimer />
          <div className="pricing-options row justify-content-center">
            <div className="pricing-card col-md-4">
              <h2>14 Day Free Trial</h2>
              <p className="price">
                <span className="original-price">$999.99 / month</span>
                FREE for 14 days, then $299.99 / month
              </p>
              <ul className="features">
              <li>✅ Post Charter Need Requests</li>
                <li>✅ Message to Brokers</li>
                <li>✅ Message to Clients</li>
                <li>✅ Message to Executive Assistants</li>
                <li>✅ View Charter Listings</li>
                <li>✅ Post Charter Have Requests</li>
              </ul>
              <a href={`https://buy.stripe.com/28o5kA0vHgrBbbWaEM?prefilled_email=${userEmail || ''}`}>
                <button className={`button upgrade ${userPlan === 'monthly' ? 'current' : ''}`}>{userPlan === 'monthly' ? 'Current Plan' : 'Upgrade'}</button>
              </a>
            </div>
            <div className="pricing-card col-md-4 featured">
              <h2>Yearly</h2>
              <p className="price">
                <span className="original-price">$9,999.99 / year</span>
                $1,799.99 / year
              </p>
              <ul className="features">
              <li>✅ Post Charter Need Requests</li>
                <li>✅ Message to Brokers</li>
                <li>✅ Message to Clients</li>
                <li>✅ Message to Executive Assistants</li>
                <li>✅ View Charter Listings</li>
                <li>✅ Post Charter Have Requests</li>
              </ul>
              <a href={`https://buy.stripe.com/00g7sI7Y94ITa7S9AJ?prefilled_email=${userEmail || ''}`}>
                <button className={`button upgrade ${userPlan === 'yearly' ? 'current' : ''}`}>{userPlan === 'yearly' ? 'Current Plan' : 'Upgrade'}</button>
              </a>
            </div>
            {userPlan !== 'yearly' && userPlan !== 'monthly' && (
              <div className="pricing-card col-md-4">
                <h2>FREE</h2>
                <ul className="features">
                  <li><br></br><br></br></li>
                  <li>✅ Post Charter Need Requests</li>
                    <li>❌ Message to Brokers</li>
                    <li>❌ Message to Clients</li>
                    <li>❌ Message to Executive Assistants</li>
                    <li>❌ View Charter Listings</li>
                      <li>❌ Post Charter Have Requests</li>
                </ul>
                {isLoggedIn ? (
                  <button className={`button ${userPlan === 'free' ? 'current' : ''}`}>{userPlan === 'free' ? 'Current Plan' : 'Register'}</button>
                ) : (
                  <a href="/register">
                    <button className="button register">Register</button>
                  </a>
                )}
              </div>
            )}
          </div>
          <div className="pricing-comparison-table plan-comparison-section">
            <h2>Plan Comparison</h2>
            <table>
              <thead>
                <tr>
                  <th>Feature</th>
                  <th>Operator Monthly</th>
                  <th>Operator Yearly</th>
                  <th>Free Account</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Post Charter Need Requests </td>
                  <td>✅</td>
                  <td>✅</td>
                  <td>✅</td>
                </tr>
                <tr>
                  <td>Message Brokers, Clients, & Executive Assistants</td>
                  <td>✅</td>
                  <td>✅</td>
                  <td>❌</td>
                </tr>
                <tr>
                  <td>View the details of Charter Request Listings</td>
                  <td>✅</td>
                  <td>✅</td>
                  <td>❌</td>
                </tr>
                <tr>
                  <td>Post Charter Have Requests</td>
                  <td>✅</td>
                  <td>✅</td>
                  <td>❌</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="pricing-comparison-to-competitors">
            <h2>Competitor Comparison</h2>
            <table>
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Charter God</th>
                  <th>Avinode</th>
                  <th>Charter Pad</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Monthly Fee</td>
                  <td>$299.99</td>
                  <td>Not Available</td>
                  <td>$700</td>
                </tr>
                <tr>
                  <td>Yearly Fee</td>
                  <td>$1,799.99</td>
                  <td>$23,988</td>
                  <td>$8,400</td>
                </tr>
                <tr>
                  <td>FREE Broker Access</td>
                  <td>✅</td>
                  <td>❌</td>
                  <td>❌</td>
                </tr>
                <tr>
                  <td>FREE Executive Assistant Access </td>
                  <td>✅</td>
                  <td>❌</td>
                  <td>❌</td>
                </tr>
                <tr>
                  <td>FREE Passenger Access </td>
                  <td>✅</td>
                  <td>❌</td>
                  <td>❌</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </section>
    </div>
  );
};

export default LandingPage;
