import React, { useState, useEffect } from 'react';
import './RegistrationForm.css'; // Import the CSS styles
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { API_URL } from '../../config';
import { Helmet } from 'react-helmet'; // Ensure react-helmet is imported


function generateRandomNumber() {
    return Math.floor(Math.random() * 10); // Generates a random number between 0 and 9
}

function generateRandomCode() {
    return Array.from({ length: 6 }, () => generateRandomNumber().toString()).join(''); // Generates a 6-digit numeric code
}

function Register() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
        acceptTerms: false,
        captchaAnswer: '',
        accountType: 'Operator', // Default account type
        error: null,
    });

    const [captchaNumbers, setCaptchaNumbers] = useState({
        num1: generateRandomNumber(), // No parameter needed, as the function generates a single-digit number
        num2: generateRandomNumber()
    });

    const [loading, setLoading] = useState(false); // Add loading state

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Client-side validation
        if (!formData.acceptTerms) {
            setFormData({ ...formData, error: 'Please accept the terms of service.' });
            return;
        }
    
        const captchaSum = captchaNumbers.num1 + captchaNumbers.num2;
        if (parseInt(formData.captchaAnswer) !== captchaSum) {
            setFormData({ ...formData, error: 'Incorrect CAPTCHA answer. Please try again.' });
            return;
        }

        setLoading(true); // Set loading to true during form submission
    
        try {
            const data = await fetch(`${API_URL}/api/auth/register`, {
                method: 'POST',
                body: JSON.stringify({
                    username: formData.username,
                    email: formData.email,
                    password: formData.password,
                    accountType: formData.accountType // Pass account type to the backend
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(response => response.json());
    
            if (data.success) {
                // Registration was successful
                // Send confirmation email and wait for the confirmation code
                const confirmationCode = await sendConfirmationEmail(formData.email);
                // Redirect to the email verification page with confirmation code
                navigate('/email-verification', { state: { confirmationCode, email: formData.email } });
            } else {
                // Registration failed, show error message
                setFormData({ ...formData, error: data.message });
            }
        } catch (error) {
            // Handle any network or server errors
            //console.error('Error registering user:', error);
        } finally {
            setLoading(false); // Set loading to false after form submission completes
        }
    };
    
    const sendConfirmationEmail = (email) => {
        const confirmationCode = generateRandomCode(); // Generate confirmation code

        return new Promise((resolve, reject) => {
            fetch(`${API_URL}/api/auth/send-code`, {
                method: 'POST',
                body: JSON.stringify({
                    email: email,
                    confirmationCode: confirmationCode,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    resolve({ confirmationCode, email }); // Resolve with confirmation code and email
                } else {
                    reject(data.message); // Reject with the error message
                }
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
        });
    };

    useEffect(() => {
        setCaptchaNumbers({
            num1: generateRandomNumber(),
            num2: generateRandomNumber()
        });
    }, []);

    return (
        <div className="registration-form">
            <Helmet>
                <title>Register - Charter God</title>
                <meta name="description" content="Join Charter God today and start exploring exclusive air charter opportunities. Register now to connect with aviation professionals and streamline your charter operations." />
                <meta name="keywords" content="charter god registration, join charter god, air charter membership, charter brokers, aviation professionals" />
                <link rel="canonical" href="https://www.chartergod.com/register" />
            </Helmet>
            <h2>Registration</h2>
            
            {formData.error && <p className="error">{formData.error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="email">E-mail:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="confirmEmail">Confirm E-mail:</label>
                    <input
                        type="email"
                        id="confirmEmail"
                        name="confirmEmail"
                        value={formData.confirmEmail}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password:</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="accountType">Account Type:</label>
                    <select
                        id="accountType"
                        name="accountType"
                        value={formData.accountType}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="Broker">Broker</option>
                        <option value="Operator">Operator</option>
                        <option value="Passenger / Client">Passenger / Client</option>
                    </select>
                </div>

                <div className="checkbox-label">
                    <input
                        type="checkbox"
                        id="acceptTerms"
                        name="acceptTerms"
                        checked={formData.acceptTerms}
                        onChange={() => setFormData({ ...formData, acceptTerms: !formData.acceptTerms })}
                    />
                    <label htmlFor="acceptTerms" className="accept-terms-label">
                         <a href="/termsofservice" target="_blank" rel="noopener noreferrer">Accept Terms of Service</a>
                    </label>
                </div>

                <div className="form-group">
                    <label htmlFor="captcha">Captcha:</label>
                    <div className="captcha">
                        <span className="captcha-question">
                            {captchaNumbers.num1} + {captchaNumbers.num2} =
                        </span>
                        <input
                            type="text"
                            id="captcha"
                            name="captchaAnswer"
                            value={formData.captchaAnswer}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="form-group">
                    <input type="submit" value={loading ? 'Submitting...' : 'Register'} className="submit-button" disabled={loading} />
                    {loading && <div className="spinner"></div>} {/* Show spinner when loading */}
                </div>
            </form>
            <div className="login-link">
                <p>Already registered? <a href="/login">Login Here</a></p>
            </div>
        </div>
    );
}

export default Register;
