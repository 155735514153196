import React from 'react';
import { Link } from 'react-router-dom';
import './myDashboard.css'; // Import CSS file

const DashboardNavbar = () => {
  return (
    <nav className="dashboard-navbar">
      <ul className="navbar-list">
      <li>
          <Link to="/">
            <span role="img" aria-label="Air Charter Listings"></span> Air Charter Listings
          </Link>
        </li>
        <li>
          <Link to="/messages">
            <span role="img" aria-label="Messages"></span> Messages
          </Link>
        </li>
        <li>
          <Link to="/change-password">
            <span role="img" aria-label="Change Password"></span> Change Password
          </Link>
        </li>
        <li>
          <Link to="/contact">
            <span role="img" aria-label="My Subscription"></span> My Subscription
          </Link>
        </li>
        <li>
          <Link to="/my-fleet">
            <span role="img" aria-label="My Fleet"></span> My Fleet
          </Link>
        </li>
        <li>
          <Link to="/email-notifications">
            <span role="img" aria-label="E-Mail Notifications"></span> E-Mail Notifications
          </Link>
        </li>
        <li>
          <Link to="/post-listing">
            <span role="img" aria-label="Post a Need Listing"></span> Post a Need Listing
          </Link>
        </li>
        <li>
          <Link to="/post-listing-have">
            <span role="img" aria-label="Post a Have Listing"></span> Post a Have Listing
          </Link>
        </li>
        <li>
          <Link to="/my-posts">
            <span role="img" aria-label="My Posts"></span> My Posts
          </Link>
        </li>
        {/* You can add more navbar links here */}
      </ul>
    </nav>
  );
};

export default DashboardNavbar;
