import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ForgotPassword.css';
import { API_URL } from '../../config';
import { Helmet } from 'react-helmet';


function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [captchaResult, setCaptchaResult] = useState('');
    const [captchaNumbers, setCaptchaNumbers] = useState({
        num1: Math.floor(Math.random() * 10),
        num2: Math.floor(Math.random() * 10),
    });
    const [submitted, setSubmitted] = useState(false); // New state variable

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate captcha
        if (parseInt(captchaResult) !== captchaNumbers.num1 + captchaNumbers.num2) {
            setError('Incorrect captcha. Please try again.');
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/auth/reset-password`, {
                method: 'POST',
                body: JSON.stringify({ email }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to reset password');
            }

            setSuccessMessage('If the email account exists in our database, you will receive a link to reset your password.');
            setSubmitted(true); // Set submitted to true after successful submission
        } catch (error) {
            setError('Failed to reset password. Please try again.');
            //console.error('Error resetting password:', error);
        }
    };

    const handleCaptchaInputChange = (event) => {
        setCaptchaResult(event.target.value);
    };

    return (
        <div className="forgot-password-form">
                <Helmet>
                <title>Forgot Password | Charter God</title>
                <meta name="description" content="Forgot your password? Enter your email to receive a reset password link and regain access to your account at Charter God." />
                <meta name="keywords" content="Charter God forgot password, reset Charter God password, Charter God account recovery" />
                <link rel="canonical" href="https://www.chartergod.com/forgot-password" />
            </Helmet>
            <h2>Forgot Password</h2>
            {error && <p className="error">{error}</p>}
            {successMessage && <p className="success">{successMessage}</p>}
            {!submitted && ( // Render the form only if not submitted
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group captcha">
                        <label htmlFor="captcha">Captcha: {captchaNumbers.num1} + {captchaNumbers.num2} = ?</label>
                        <input
                            type="text"
                            id="captcha"
                            name="captcha"
                            value={captchaResult}
                            onChange={handleCaptchaInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input type="submit" value="Reset Password" className="submit-button" />
                    </div>
                </form>
            )}
            {submitted && ( // Render success message if submitted
                <p>Form submitted successfully. Input fields and button removed.</p>
            )}
            <p>Remember your password? <Link to="/login">Login</Link></p>
        </div>
    );
}

export default ForgotPassword;
