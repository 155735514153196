import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import DashboardSidebar from './DashboardSidebar';
import { useAuth } from '../contexts/AuthContext';
import './EmailNotifications.css';
import { API_URL } from '../../config';

const EmailNotifications = () => {
  const { isLoggedIn, token } = useAuth();
  const navigate = useNavigate();
  const [marketingEmails, setMarketingEmails] = useState(false);
  const [messageNotifications, setMessageNotifications] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    const fetchNotificationSettings = async () => {
      try {
        const response = await fetch(`${API_URL}/api/notificationRoutes/email-settings`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setMarketingEmails(data.marketing_emails === 1);
          setMessageNotifications(data.message_notifications === 1);
        } else {
          throw new Error('Error fetching notification settings');
        }
      } catch (error) {
        //console.error('Error fetching notification settings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotificationSettings();
  }, [isLoggedIn, navigate, token]);

  const handleMarketingEmailsChange = (e) => {
    setMarketingEmails(e.target.checked);
  };

  const handleMessageNotificationsChange = (e) => {
    setMessageNotifications(e.target.checked);
  };

  const handleSaveSettings = async () => {
    try {
      const response = await fetch(`${API_URL}/api/notificationRoutes/email-settings`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          marketing_emails: marketingEmails ? 1 : 0,
          message_notifications: messageNotifications ? 1 : 0,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        //console.log('Settings updated successfully:', data);
        setConfirmationMessage('Settings updated successfully.'); // Set confirmation message
      } else {
        throw new Error(data.message || 'Error updating settings');
      }
    } catch (error) {
      //console.error('Error saving settings:', error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="dashboard-container">
      <DashboardSidebar />
      <section className="dashboard-content">
        <div className="email-notifications-container">
          <h1>E-Mail Notifications</h1>
          {confirmationMessage && <p className="notification-message">{confirmationMessage}</p>} {/* Display confirmation message */}
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="checkbox-group">
              <label>
                <input
                  type="checkbox"
                  checked={marketingEmails}
                  onChange={handleMarketingEmailsChange}
                />
                Receive Marketing Email Notifications?
              </label>
            </div>

            <div className="checkbox-group">
              <label>
                <input
                  type="checkbox"
                  checked={messageNotifications}
                  onChange={handleMessageNotificationsChange}
                />
                Receive Message Notifications?
              </label>
            </div>

            <button type="button" onClick={handleSaveSettings} className="save-settings-btn">
              Save Settings
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default EmailNotifications;
  